import React, { useContext } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const TaskCompleteButton = props => {
  const { task, onChange } = props;
  const { token } = useContext(AuthContext);
  const { sendRequest } = useHttpClient();

  let isCompleted = task.completeDate && task.completeDate > 0;

  const handleClick = async event => {
    event.preventDefault();
    let completeDate = new Date().getTime() / 1000;
    if (isCompleted) {
      completeDate = 0;
    }
    onChange(task, "completeDate", completeDate);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/tasks/complete`,
        "PUT",
        JSON.stringify({
          taskId: task.id,
          completeDate: completeDate
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      );
      console.log(response);
      if (response.status === 201) {
        onChange(task, "completeDate", completeDate);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Button
      variant={isCompleted ? "contained" : "outlined"}
      size="small"
      color="primary"
      style={{ marginLeft: "10px" }}
      onClick={handleClick}
      //   disabled={isLoading && true}
    >
      {!false ? (
        isCompleted ? (
          "Completed"
        ) : (
          "Mark Complete"
        )
      ) : (
        <CircularProgress size={18} />
      )}
    </Button>
  );
};

export default TaskCompleteButton;
