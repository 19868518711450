import { useState, useCallback, useRef, useEffect } from "react";

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activeHttpRequest = useRef([]);

  // useCallback to avoid infinite loops.
  const sendRequest = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      setIsLoading(true);
      const httpAbortCntrl = new AbortController();
      activeHttpRequest.current.push(httpAbortCntrl); // we use current because ref automatically creates current

      try {
        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCntrl.signal
        });

        const responseData = await response.json();

        activeHttpRequest.current = activeHttpRequest.current.filter(
          reqCtrl => reqCtrl !== httpAbortCntrl
        );

        if (!response.ok) {
          // only if response status is 200ish. (200, 201 etc)
          throw new Error(responseData.message);
        }

        setIsLoading(false);
        return responseData;
      } catch (err) {
        setError(err.message || "Something went wrong, unexpected error.");
        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  // stop all requests when this component unmounts
  useEffect(() => {
    // returning a function will act as cleanup.
    return () => {
      activeHttpRequest.current.forEach(abortCtrl => abortCtrl.abort());
    };
  }, []);

  return {
    isLoading,
    error,
    sendRequest,
    clearError
  };
};
