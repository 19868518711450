import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";

// MUI
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// Icons
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockIcon from "@material-ui/icons/Lock";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

// Components
import { AuthContext } from "../../context/auth-context";
import { UserProfileContext } from "../../context/userProfile-context";
import UserSettingsModal from "../Modals/UserSettingsModal";

const useStyles = makeStyles(theme => ({
  userMenu: {
    "& .MuiMenu-paper": {
      marginTop: "40px"
      //   marginLeft: "10px"
    },
    "& .MuiMenu-paper li": {
      //   paddingRight: "50px",
      //   paddingLeft: "25px",
      minWidth: "200px"
    },
    "& .MuiListItemIcon-root": {
      minWidth: "40px"
    }
  }
}));

const UserMenuOptions = props => {
  const { history, anchorEl, handleClose } = props;
  const classes = useStyles();
  const { logout } = useContext(AuthContext);
  const [userSettingsModal, setUserSettingsModal] = useState(null);

  const handleOpenUserSettingsModal = tabIndex => {
    handleClose();
    setUserSettingsModal(tabIndex);
  };

  return (
    <React.Fragment>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.userMenu}
      >
        <MenuItem onClick={() => handleOpenUserSettingsModal(0)}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Personal Details</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleOpenUserSettingsModal(1)}>
          <ListItemIcon>
            <LockIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Change Password</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleOpenUserSettingsModal(2)}>
          <ListItemIcon>
            <NotificationsActiveIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Notification Settings</Typography>
        </MenuItem>
        <Divider style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }} />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </Menu>
      <UserSettingsModal
        open={typeof userSettingsModal === "number" ? true : false}
        setOpen={setUserSettingsModal}
        openIndex={userSettingsModal}
      />
    </React.Fragment>
  );
};

export default withRouter(UserMenuOptions);
