import React from "react";
import { NavLink, withRouter } from "react-router-dom";

const TabLink = (props) => {
  if (props.isGateActive === false) {
    return (
      <li className="top-header__tab">
        <span className="Tab-Link Tab-selectableTab--isDisabled">
          <span className={`Tab-selectableTab1`}>{props.children}</span>
        </span>
      </li>
    );
  }

  return (
    <li className="top-header__tab">
      <NavLink
        className="Tab-Link"
        to={props.to ? props.to : "#"}
        exact={props.exact || false}
        activeClassName="Tab-selectableTab--isSelected"
      >
        <span
          className={`Tab-selectableTab ${
            props.active && "Tab-selectableTab--isSelected"
          }`}
        >
          {props.children}
        </span>
      </NavLink>
    </li>
  );
};

export default withRouter(TabLink);
