import React, { useState, useEffect, useContext, useCallback } from "react";
import { withRouter, useParams } from "react-router-dom";
import queryString from "query-string";
import clsx from "clsx";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import grey from "@material-ui/core/colors/grey";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";

// Components
import { TaskRow, EmptyRow } from "../components/TaskRow";
import TaskSection from "../components/TaskSection";
import TaskDetailsSlider from "../components/TaskDetailsSlider";
import NewTaskModal from "../../shared/components/Modals/NewTaskModal";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import NewSectionModal from "../../shared/components/Modals/NewSectionModal";
import { Divider } from "@material-ui/core";
import ButtonNavLink from "../../shared/components/Navigation/ButtonNavLink";
import ProjectActions from "../components/ProjectActions";
import FileHistoryModal from "../../shared/components/Modals/FileHistoryModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  fullFlex: {
    flex: "1 1 auto",
    display: "flex",
    minWidth: "1px",
  },
  dissmissTaskDetails: {
    minHeight: "1px",
    flex: 1,
    display: "flex",
    height: "100%",
  },
  toolbar: {
    height: "55px",
    zIndex: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(2),
    borderBottom: "1px solid " + grey[300],
  },
  tasksContainer: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    minHeight: 1,
    position: "relative",
    width: "100%",
    maxHeight: "77vh",
    // height: "100%" // tmp removed because when there are not that many tasks, the task details dismiss container is too small.
  },
  scrollableContainer: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

const ProjectTasksPage = (props) => {
  const classes = useStyles();
  const { pid, stage, taskId } = useParams();
  const { token } = useContext(AuthContext);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [openTask, setOpenTask] = useState(null);
  const [showCompletedTasks, setShowCompletedTasks] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [sections, setSections] = useState([]);
  const [newTaskModal, setNewTaskModal] = useState({
    open: false,
    section: {},
  });
  const [newSectionModal, setNewSectionModal] = useState(false);
  const [fileHistory, setFileHistory] = useState(null);

  const fetchTasks = useCallback(
    async (token, pid, stage) => {
      if (token) {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/tasks/${pid}/${stage || ""}`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );
          const tasks = response.data.tasks;
          const sections = response.data.sections;

          const uniqueSections = [];
          sections.forEach((section) => {
            if (section.id) {
              uniqueSections[section.id] = section;
            }
          });
          if (!uniqueSections[0]) {
            uniqueSections.splice(0, 1);
          }

          uniqueSections.sort((a, b) => a.sectionOrder - b.sectionOrder);

          setTasks(tasks);
          setSections(uniqueSections);

          const extraParams = queryString.parse(props.location.search);
          if (extraParams.taskId) {
            const openTask = tasks.find(
              (task) => task.id === parseInt(extraParams.taskId)
            );
            if (openTask && openTask.id) {
              setOpenTask(openTask);
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    [sendRequest, props.location.search]
  );

  const LogProjectView = useCallback(async (token, pid, stage) => {
    if (token) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/projects/${pid}/view`,
          {
            method: "PUT",
            body: JSON.stringify({
              stage: stage,
              timestamp: new Date().getTime() / 1000,
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        const responseData = await response.json();
        console.log(responseData);
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    if (token && pid) {
      console.log("ProjectTasksPage:loaded. ", pid, stage);
      fetchTasks(token, pid, stage);
      LogProjectView(token, pid, stage);
    }
    return () => {
      setTasks([]);
      setSections([]);
    };
  }, [pid, stage, token, fetchTasks, LogProjectView]);

  useEffect(() => {
    setOpenTask(null);
  }, [pid, stage]);

  const onNewTask = (newTask) => {
    newTask.section = {
      ...sections.find((section) => section.id === newTask.section),
    };
    setTasks([...tasks, newTask]);
    // Removed support for adding new sections through new task button.
    // if (newTask.section) {
    //   const sectionExists = sections.find(
    //     section => section.id === newTask.section.id
    //   );
    //   if (!sectionExists) {
    //     setSections([
    //       ...sections,
    //       {
    //         index: sections.length,
    //         title: newTask.section,
    //         expanded: true
    //       }
    //     ]);
    //   }
    // }
  };
  const onTaskDelete = (task) => {
    let newTasks = tasks.filter((t) => t.id !== task.id);
    setOpenTask(null);
    setTasks([...newTasks]);
  };

  const onNewAttachment = (attachmentName, taskId) => {
    fetchTasks(token, pid, stage);
    setOpenTask(null);
  };

  const onNewSection = (section) => {
    const updatedSections = [...sections, section];
    updatedSections.sort((a, b) => a.sectionOrder - b.sectionOrder);
    setSections(updatedSections);
  };

  const handleChange = (task, key, value) => {
    let index;
    if (typeof task === "object") {
      index = tasks.findIndex((t) => t.id === task.id);
    }
    if (typeof task === "number") {
      index = tasks[task];
    }

    const newTasks = JSON.parse(JSON.stringify(tasks));
    let newTask = { ...newTasks[index] };
    newTask[key] = value;
    newTasks[index][key] = value;

    setOpenTask(newTask);
    setTasks([...newTasks]);
  };

  const loadingSkeleton = (
    <Grid container className={classes.fullFlex} spacing={1} direction="column">
      {[0, 1, 2].map((si) => (
        <React.Fragment key={si}>
          <Skeleton
            variant="text"
            height={50}
            key={si}
            width={300}
            style={{ margin: "10px 0" }}
          />
          {[0, 1, 2, 3, 4].map((ti) => (
            <Skeleton
              variant="text"
              height={30}
              width="60%"
              key={ti}
              style={{ margin: "2px 0" }}
            />
          ))}
        </React.Fragment>
      ))}
    </Grid>
  );

  return (
    <React.Fragment>
      <Grid
        container
        className={clsx(classes.root, classes.fullFlex)}
        spacing={0}
        direction="row"
      >
        <Grid item xs>
          <ProjectActions
            openTask={openTask}
            onClick={() => {
              if (openTask) setOpenTask(null);
            }}
            leftActionsComponent={
              <Grid container alignItems="center" item xs={!openTask ? 6 : 12}>
                <ButtonGroup color="primary" size="small">
                  <Button
                    onClick={() => setNewTaskModal({ open: true, section: {} })}
                  >
                    New Task
                  </Button>
                  <Button onClick={() => setNewSectionModal(true)}>
                    New Section
                  </Button>
                </ButtonGroup>
                <Divider
                  orientation="vertical"
                  style={{ margin: "0 25px", height: 20 }}
                  // variant=""
                />
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowCompletedTasks(!showCompletedTasks);
                  }}
                  color="primary"
                  size="small"
                >
                  {!showCompletedTasks
                    ? "Show Completed Tasks"
                    : "Hide Completed Tasks"}
                </Button>
              </Grid>
            }
          />
          <EmptyRow open={Boolean(openTask)} />
          {!isLoading && tasks.length === 0 && (
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              style={{ marginTop: 25 }}
            >
              <Typography variant="h5">No tasks found, create one?</Typography>
            </Grid>
          )}
          <div className={classes.tasksContainer}>
            <div
              className={classes.scrollableContainer}
              id="taskScrollableContainer"
            >
              {isLoading && !error
                ? loadingSkeleton
                : sections.map((section) => {
                    if (!section) return null;
                    if (!section.id) return null;
                    return (
                      <Grid
                        container
                        className={classes.root}
                        spacing={0}
                        direction="column"
                        key={section.id}
                      >
                        <TaskSection
                          title={section.name}
                          onClick={() =>
                            setNewTaskModal({
                              open: true,
                              section: section,
                            })
                          }
                        />
                        {tasks.map((task) => {
                          if (task.section && task.section.id === section.id) {
                            if (
                              task.completeDate > 0 &&
                              showCompletedTasks !== true
                            )
                              return null;
                            return (
                              <TaskRow
                                task={task}
                                key={task.id}
                                onClick={() => {
                                  setOpenTask(task);
                                  // removed as it causes a re-load of page (needs more work)
                                  // props.history.push(
                                  //   `/projects/${pid}/${stage}`
                                  // );
                                }}
                                openTask={openTask}
                                active={
                                  openTask ? openTask.id === task.id : false
                                }
                              />
                            );
                          }
                          return null;
                        })}
                      </Grid>
                    );
                  })}
            </div>
          </div>

          {openTask && (
            <div
              className={classes.dissmissTaskDetails}
              onClick={() => setOpenTask(null)}
            />
          )}
        </Grid>
        {openTask && (
          <TaskDetailsSlider
            open={openTask && true}
            task={openTask}
            taskId={openTask ? openTask.id : 0}
            dismiss={setOpenTask}
            onChange={handleChange}
            onTaskDelete={onTaskDelete}
            onNewAttachment={onNewAttachment}
            pid={pid}
            stage={stage}
            fileHistory={fileHistory}
            setFileHistory={setFileHistory}
          />
        )}
      </Grid>
      <NewTaskModal
        newTaskModal={newTaskModal}
        setNewTaskModal={setNewTaskModal}
        sections={sections}
        pid={pid}
        stage={stage}
        onNewTask={onNewTask}
      />
      <NewSectionModal
        newSectionModal={newSectionModal}
        setNewSectionModal={setNewSectionModal}
        pid={pid}
        stage={stage}
        onNewSection={onNewSection}
      />
      <FileHistoryModal
        fileHistory={fileHistory}
        setFileHistory={(file) => {
          setFileHistory(file);
        }}
      />
    </React.Fragment>
  );
};

export default withRouter(ProjectTasksPage);
