import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

// Project Pages
import ProjectTasksOld from "./projects/pages/ProjectTasksOld";
import ProjectPageGates from "./projects/pages/ProjectPageGates";
import ProjectsArchivePage from "./projects/pages/ProjectsArchivePage";
import ProjectCalendarPage from "./projects/pages/ProjectCalendarPage";
import ProjectMembersPage from "./projects/pages/ProjectMembersPage";
import ProjectFilesPage from "./projects/pages/ProjectFilesPage";
import MyTasksPage from "./tasks/pages/MyTasksPage";
import OrganizationPage from "./organization/pages/OrganizationPage";
// User
import LoginPage from "./user/pages/LoginPage";
import SignupPage from "./user/pages/SignupPage";
import ForgotPasswordPage from "./user/pages/ForgotPasswordPage";
import PasswordResetPage from "./user/pages/PasswordResetPage";

import FullScreenLoader from "./shared/components/UIElements/Loaders/FullScreenLoader";
import SidebarNav from "./shared/components/Sidebar/SidebarNav";
import LeftDrawerNav2 from "./shared/components/Navigation/LeftDrawerNav2";
import TopHeader from "./shared/components/TopNavigation/TopHeader";
import TopHeaderBar from "./shared/components/Navigation/TopHeaderBar";
// Auth stuff
import { useAuth } from "./shared/hooks/auth-hook";
import { useProjects } from "./shared/hooks/projects-hook";
// Context
import { AuthContext } from "./shared/context/auth-context";
import { UserProfileContext } from "./shared/context/userProfile-context";
import { ProjectsContext } from "./shared/context/projects-context";

// Styles
import "typeface-roboto";
import theme from "./shared/themes/DefaultTheme";
import "./assets/css/bootstrap-grid.css";
import { ThemeProvider } from "@material-ui/core/styles";
import ProjectGanttPage from "./projects/pages/ProjectGanttPage";
import ProjectsPage from "./projects/pages/ProjectsPage";
import InboxPage from "./inbox/pages/InboxPage";
import ProjectComparisonsPage from "./projects/pages/ProjectComparisonsPage";

const App = () => {
  const { token, login, logout, userId, loadingAuth } = useAuth();
  const [userProfile, setUserProfile] = useState(null);
  const [projects, setProjects] = useState([]);
  const {
    loading: loadingProjets,
    addProject,
    removeProject,
    updateProject,
  } = useProjects(token, projects, setProjects);

  let routes;

  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user-profile`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        const responseData = await response.json();
        if (!response.ok) {
          throw new Error("Response is invalid. " + responseData.message);
        }
        console.log("load user profile, ", responseData);
        setUserProfile({
          profile: responseData.data.profile,
          favorites: responseData.data.favorites,
        });
        setProjects(responseData.data.projects);
      } catch (err) {
        console.log(err);
      }
    };

    if (userId && token && !userProfile) {
      loadUserProfile();
    }
    if (userProfile && userProfile.profile.id !== userId) {
      if (token) {
        loadUserProfile();
      }
    }
  }, [userId, token, userProfile]);

  if (loadingAuth || (!userProfile && userId)) {
    return <FullScreenLoader />;
  }

  if (userId) {
    // protected routes
    routes = (
      <Switch>
        <Route path="/" exact>
          <main>
            <LeftDrawerNav2 />
            <div className="view-page">
              <TopHeaderBar title="Home" />
              <ProjectsPage />
            </div>
          </main>
        </Route>
        <Route path="/tasks" exact>
          <main>
            <LeftDrawerNav2 />
            <div className="view-page">
              <TopHeaderBar title="Tasks" />
              <MyTasksPage />
            </div>
          </main>
        </Route>
        <Route path="/organization" exact>
          <main>
            <LeftDrawerNav2 />
            <div className="view-page">
              <TopHeaderBar title="Organization" />
              <OrganizationPage />
            </div>
          </main>
        </Route>
        <Route path="/inbox" exact>
          <main>
            <LeftDrawerNav2 />
            <div className="view-page">
              <TopHeaderBar title="Inbox" />
              <InboxPage />
            </div>
          </main>
        </Route>
        <Route path="/profile" exact>
          <main>
            <LeftDrawerNav2 />
            <div className="view-page">
              <p style={{ flex: 1, padding: "20px" }}>Profile</p>
            </div>
          </main>
        </Route>

        <Route path="/projects/:pid/:stage/calendar">
          <main>
            <LeftDrawerNav2 />
            <ProjectCalendarPage />
          </main>
        </Route>
        <Route path="/projects/:pid/:stage/members">
          <main>
            <LeftDrawerNav2 />
            <ProjectMembersPage />
          </main>
        </Route>
        <Route path="/projects/:pid/:stage/files">
          <main>
            <LeftDrawerNav2 />
            <ProjectFilesPage />
          </main>
        </Route>
        <Route path="/projects/:pid/:stage/gantt">
          <main>
            <LeftDrawerNav2 />
            <ProjectGanttPage />
          </main>
        </Route>
        <Route path="/projects/:pid/:stage/comparisons/:cid">
          <main>
            <LeftDrawerNav2 />
            <ProjectComparisonsPage />
          </main>
        </Route>
        <Route path="/projects/:pid/:stage?">
          <main>
            <LeftDrawerNav2 />
            <ProjectPageGates profile={userProfile.profile} />
          </main>
        </Route>
        <Route path="/project/:pid/:stage?">
          <main>
            <SidebarNav />
            <div className="view-page">
              <TopHeader />
              <ProjectTasksOld />
            </div>
          </main>
        </Route>
        <Route path="/forgot-password/:token/:email?">
          <ForgotPasswordPage />
        </Route>
        <Route path="/archive" exact>
          <main>
            <LeftDrawerNav2 />
            <div className="view-page">
              <TopHeaderBar title="Archive" showSmallToolbar={false} />
              <ProjectsArchivePage />
            </div>
          </main>
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/signup" exact>
          <SignupPage />
        </Route>
        <Route path="/forgot-password/:token/:email?">
          <ForgotPasswordPage />
        </Route>
        <Route path="/password-reset">
          <PasswordResetPage />
        </Route>
        <Route>
          <LoginPage />
        </Route>
      </Switch>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <AuthContext.Provider
          value={{
            isLoggedIn: !!token,
            token: token,
            login: login,
            logout: logout,
            userId: userId,
          }}
        >
          <UserProfileContext.Provider
            value={{
              profile: userProfile && (userProfile.profile || null),
              favorites: userProfile && (userProfile.favorites || null),
              setUserProfile: setUserProfile,
            }}
          >
            <ProjectsContext.Provider
              value={{
                projects: projects,
                setProjects: setProjects,
                loading: loadingProjets,
                addProject: addProject,
                removeProject: removeProject,
                updateProject: updateProject,
              }}
            >
              <Hidden smDown={userId ? true : false}>
                <Router>{routes}</Router>
              </Hidden>
              <Hidden mdUp xsUp={userId ? false : true}>
                <Typography align="center">
                  <ErrorOutlineIcon
                    color="primary"
                    style={{ fontSize: 60, marginTop: "40vh" }}
                  />
                </Typography>
                <Typography variant="h4" align="center" gutterBottom>
                  Your screen size is not supported.
                </Typography>
                <Typography variant="h6" align="center" color="textSecondary">
                  Please use a desktop and maximize the application.
                </Typography>
              </Hidden>
            </ProjectsContext.Provider>
          </UserProfileContext.Provider>
        </AuthContext.Provider>
      </CookiesProvider>
    </ThemeProvider>
  );
};

export default App;
