import React, { useState, useRef, useContext, useEffect } from "react";
import FileIcon, { defaultStyles } from "react-file-icon";
import clsx from "clsx";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";

import MoreVertIcon from "@material-ui/icons/MoreVert";

// Components
import { fileExtensions, rejectedExtensions } from "../../util/fileExtensions";
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";

const useStyles = makeStyles(theme => ({
  icon: {},
  avatar: {
    color: grey[400],
    border: "1px solid " + grey[400],
    backgroundColor: "transparent",
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: "12px"
  },
  inlineFlex: {
    display: "inline-flex",
    alignItems: "center"
  },
  transparentButton: {
    border: "1px solid " + grey[400]
  }
}));

const FileAttachmentLoading = () => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.inlineFlex, classes.transparentButton)}
      style={{ padding: 4, borderRadius: 4 }}
    >
      <Skeleton variant="rect" width={35} height={35} />
      <div style={{ marginLeft: 8, display: "flex", flexDirection: "column" }}>
        <Skeleton variant="text" width={280} height={16} />
        <Skeleton variant="text" width={150} height={14} />
      </div>
      <div style={{ marginLeft: 25, display: "flex" }}></div>
    </div>
  );
};

const FileAttachment = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { token } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const {
    id,
    attachment,
    file,
    onFileChange,
    type,
    pid,
    stage,
    setFileHistory
  } = props;
  const [fileError, setFileError] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);

  const filePickerRef = useRef();

  const fileExtension =
    fileExtensions.find(ext => ext.extension === file.extension) ||
    fileExtensions[0];

  const handleButtonClick = event => {
    event.preventDefault();
    filePickerRef.current.click();
  };

  const handleFileChange = event => {
    let pickedFile;
    clearError();
    handleClose();
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      const fileSize = (pickedFile.size / 1024 / 1024).toFixed(4); // MB
      if (
        rejectedExtensions.includes(pickedFile.name.split(".").pop()) === false
      ) {
        if (fileSize < 200) {
          pickedFile.extension =
            fileExtensions.find(ext => ext.mime === pickedFile.type) ||
            fileExtensions[0];
          uploadFile(pickedFile);
        } else {
          setFileError("File must be less than 200MB");
        }
      } else {
        setFileError("invalid file (check file type or size)");
      }
    } else {
      setFileError("no file selected");
    }
  };

  const uploadFile = async file => {
    try {
      console.log("uploadFile called with file: ", file);
      // return;
      const formData = new FormData();
      let response;
      formData.append("file", file);
      formData.append("action", type);
      formData.append("mime", file.type);
      if (type === "projectFile") {
        formData.append("projectId", pid);
        formData.append("gate", stage);
        formData.append("masterFile", attachment.masterFile);
        console.log(
          "uploadFile called, type=projectFile, masterfile: ",
          attachment.masterFile
        );
        response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/files/project`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + token
          }
        );
      } else {
        formData.append("taskId", attachment.taskId);
        formData.append("category", attachment.category);
        response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/files`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + token
          }
        );
      }
      console.log(response);
      if (response.data.file) {
        setFileError(null);
        clearError();
        onFileChange(response.data.file, type);
      }
    } catch (err) {
      console.log(err);
      setFileError(err.message);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div
        className={clsx(classes.inlineFlex, classes.transparentButton)}
        style={{ padding: 4, borderRadius: 4 }}
      >
        <FileIcon
          extension={fileExtension.extension}
          {...defaultStyles[fileExtension.extension]}
          size={30}
        />
        <div
          style={{ marginLeft: 8, display: "flex", flexDirection: "column" }}
        >
          <Link
            color="textPrimary"
            // target="_blank"
            href={`${process.env.REACT_APP_API_URL}/download/${file.id}`}
            download={file.name}
          >
            <Typography
              variant="subtitle2"
              color="primary"
              display="inline"
              style={{ marginRight: 4 }}
            >
              {attachment.category}
            </Typography>
            <Typography variant="body2" color="textPrimary" display="inline">
              {file.nameWithExtension}
            </Typography>
            <Typography
              variant="body2"
              color="textPrimary"
              display="inline"
              style={{ marginLeft: 4 }}
            >
              {attachment.revision && `(${attachment.revision})`}
            </Typography>
          </Link>
          <Typography variant="body2" color="textSecondary">
            {fileExtension ? fileExtension.name : ""}
          </Typography>
        </div>
        <div style={{ marginLeft: 25, display: "flex" }}>
          <IconButton
            color="default"
            size="small"
            onClick={event => setAnchorEl(event.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
          {isLoading && <CircularProgress color="primary" size={26} />}
        </div>
      </div>
      {fileError && (
        <Typography variant="body2" color="error">
          {fileError}
        </Typography>
      )}
      <Menu
        id="attachment-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleButtonClick}>Upload Revision</MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setFileHistory({ ...file, masterFile: attachment.masterFile });
          }}
        >
          View File History
        </MenuItem>
        {/* <MenuItem disabled={attachment.revision > 1 ? false : true}>
          Compare Revisions
        </MenuItem> */}
      </Menu>
      <input
        id="revision-file"
        ref={filePickerRef}
        type="file"
        style={{ display: "none" }}
        accept="*"
        onChange={handleFileChange}
        value={(fileToUpload && fileToUpload.name) || ""}
      />
    </React.Fragment>
  );
};

export { FileAttachment, FileAttachmentLoading };
