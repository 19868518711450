import React, { useState, useEffect, useContext } from "react";

// MUI Stuff
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import grey from "@material-ui/core/colors/grey";

// Components
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const TaskDescriptionInput = withStyles((theme) => ({
  root: {
    marginTop: 0,
    "& input": {
      fontSize: "20px",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: grey[400],
      },
      "&.Mui-focused fieldset": {
        borderColor: grey[600],
        borderWidth: "1px",
      },
    },
  },
}))(TextField);

const TaskDescriptionField = (props) => {
  const { task, id, placeholder, onChange } = props;
  const [value, setValue] = useState("");
  const [initialValue, setInitialValue] = useState("");

  const { token } = useContext(AuthContext);
  const { sendRequest, clearError } = useHttpClient();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleBlur = async (event) => {
    if (event.target.value === initialValue) return;
    if (!task) return;
    clearError();
    console.log("handle blur detected a change. name name: ", value);
    onChange(task, "description", value);
    setInitialValue(value);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/tasks/description`,
        "PUT",
        JSON.stringify({
          description: value,
          taskId: task.id,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      if (response.status === 201) {
        // onChange(task, "description", value);
        // setInitialValue(value);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (task) {
      setValue(task.description || "");
      setInitialValue(task.description || "");
    }
    return () => {
      setValue("");
      setInitialValue("");
    };
  }, [task]);

  return (
    <TaskDescriptionInput
      placeholder={placeholder}
      variant="outlined"
      size="small"
      fullWidth
      multiline
      rows={props.rows}
      rowsMax={8}
      id={id}
      value={value}
      onChange={handleChange}
      autoComplete="off"
      onBlur={handleBlur}
    />
  );
};

export default TaskDescriptionField;
