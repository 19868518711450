import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { format } from "date-fns";

// Mui Stuff
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { generateRepeatingArray } from "../../util/helpers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 50,
  },
  formControlWithOther: {
    width: "100%",
    flexDirection: "row",
  },
  selectWithOther: {
    width: 100,
    height: 37,
  },
  textField: {
    minWidth: 150,
  },
  smallField: {
    width: 75,
    maxWidth: 75,
  },
  denseTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 16px 6px 8px",
    },
  },
}));

const DynamicTableTaskElement = (props) => {
  const [tmpTextFieldValue, setTmpTextFieldValue] = useState("");
  const [fieldValues, setFieldValues] = useState([]);
  const {
    id,
    handleTaskElementChange,
    type,
    name,
    options,
    value,
    otherValue,
    placeholder,
  } = props;
  const classes = useStyles();

  let columnNames = [];
  if (placeholder) {
    columnNames = placeholder.split(",");
  }

  useEffect(() => {
    if (options) {
      setFieldValues(options);
    }
  }, [options]);

  const handleChange = (event, row, col) => {
    const prevValues = [...fieldValues];
    prevValues[row][col] = event.target.value;
    setFieldValues(prevValues);
  };

  const handleBlur = (event, rowIndex, colIndex) => {
    console.log("handle blur called. values: ", fieldValues);
    const newFieldValues = [...fieldValues];
    const shouldAddRow = newFieldValues[newFieldValues.length - 1].filter(
      (val) => val !== ""
    );
    if (shouldAddRow.length > 0) {
      const emptyArr = generateRepeatingArray(fieldValues[0].length, "");
      newFieldValues.push(emptyArr);
    }
    handleTaskElementChange(id, "formula-table", newFieldValues);
  };

  return (
    <TableContainer>
      <Table
        aria-label="formula table"
        size="small"
        className={classes.denseTable}
      >
        <TableHead>
          <TableRow>
            {columnNames.length > 0 ? (
              columnNames.map((col, index) => (
                <TableCell key={index}>{col}</TableCell>
              ))
            ) : (
              <React.Fragment>
                <TableCell>MI code/name description</TableCell>
                <TableCell align="left">Label amount/day</TableCell>
                <TableCell align="left">Potency & Raw material/day</TableCell>
              </React.Fragment>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {fieldValues.map((row, rowIndex) => {
            return (
              <TableRow key={rowIndex}>
                {row.map((col, colIndex) => {
                  return (
                    <TableCell
                      component="th"
                      scope="row"
                      key={`${rowIndex}-${colIndex}`}
                    >
                      <TextField
                        className={clsx(
                          classes.formControl,
                          colIndex === 0 || columnNames.length > 1
                            ? classes.textField
                            : classes.smallField
                        )}
                        id={`${rowIndex}-${colIndex}`}
                        placeholder={
                          colIndex === 0 || columnNames.length > 1
                            ? "Enter something"
                            : null
                        }
                        value={col}
                        onChange={(event) =>
                          handleChange(event, rowIndex, colIndex)
                        }
                        variant="outlined"
                        onBlur={(event) =>
                          handleBlur(event, rowIndex, colIndex)
                        }
                        size="small"
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DynamicTableTaskElement;
