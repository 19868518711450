import React from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  activeOptionText: {
    fontWeight: 700,
  },
}));

export default function DeleteProjectModal(props) {
  const { open, setOpen, project } = props;
  const { token } = React.useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const deleteProjectHandler = async (event) => {
    event.preventDefault();

    if (token && project) {
      try {
        let response;
        response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/projects/${project.id}`,
          "DELETE",
          JSON.stringify({}),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        if (response.data) {
          window.location.href = "/";
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth="sm"
      //   fullScreen={true}
      fullWidth={true}
      open={open}
    >
      <DialogTitle onClose={handleClose}>Delete {project.name}</DialogTitle>
      <DialogContent dividers style={{}}>
        {error && (
          <Collapse in={Boolean(error)}>
            <Alert
              style={{ marginBottom: 16 }}
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    clearError();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          </Collapse>
        )}
        <Container maxWidth="sm">
          {project.gates ? (
            <>
              <Typography variant="body1" gutterBottom>
                Are you sure you want to delete {project.name}? This action is
                irreversible.
              </Typography>
              <Typography variant="body2" gutterBottom>
                All tasks, comments and members will be permanently removed from
                the project.
              </Typography>
            </>
          ) : (
            "Loading"
          )}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.backButton}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={deleteProjectHandler}
        >
          {isLoading ? (
            <React.Fragment>
              Deleting
              <CircularProgress size={16} style={{ marginLeft: 8 }} />
            </React.Fragment>
          ) : (
            `DELETE ${project.name || ""}`
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
