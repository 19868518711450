import React from "react";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
  datePicker: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 10,
    "& label + .MuiOutlinedInput-root": {
      width: 160
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent"
    }
  },
  wide: {
    "& label + .MuiOutlinedInput-root": {
      width: 200
    }
  }
}));

const DatePicker = props => {
  const classes = useStyles();
  const { label, id, value, handleDateChange } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        minDateMessage={props.minDateMessage || "Must be after min date"}
        className={clsx(classes.datePicker, props.wide && classes.wide)}
        style={props.style || null}
        disableToolbar
        variant="inline"
        minDate={props.minDate || new Date("2020-01-01")}
        format={props.format || "MM/dd/yyyy"}
        margin="normal"
        id={id}
        label={label}
        inputVariant="outlined"
        value={value || null}
        onChange={date => {
          handleDateChange(date, id);
        }}
        KeyboardButtonProps={{
          "aria-label": `Change ${label}`,
          fontSize: "small",
          size: "small"
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
