import React, { useReducer, useEffect } from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import purple from "@material-ui/core/colors/purple";
import red from "@material-ui/core/colors/red";
import { makeStyles } from "@material-ui/core/styles";

/* useReducer
use reducer allows you to manage more complex states, or add function to check state instead of just setting it (like with useState)
a reducer receives an action and current state
returns a new state
*/

import { validate } from "../../util/validators";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators)
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true
      };
    default:
      return state;
  }
};

const useStyles = makeStyles(theme => ({
  borderedInput: {
    marginBottom: theme.spacing(3),
    "& label.Mui-focused": {
      color: purple[500]
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "rgb(92, 6, 140, 0.9)"
      }
    }
  },
  borderedInputError: {
    marginBottom: theme.spacing(3),
    "& label.Mui-focused": {
      color: red[500]
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: red[600]
      }
    }
  },
  noMargin: {
    marginBottom: "0px !important"
  }
}));

const Input = props => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || "",
    isValid: props.initialIsValid || false,
    isTouched: false
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;
  const classes = useStyles();

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = event => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators
    }); // this will update our state
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH"
    });
  };

  const hasErrors = () => {
    if (props.noError) return false;
    return !inputState.isValid && inputState.isTouched;
  };

  return (
    <TextField
      autoFocus={props.autoFocus || false}
      autoComplete={props.autoComplete || "off"}
      id={props.id}
      label={props.label}
      type={props.type || "text"}
      onChange={changeHandler}
      onBlur={touchHandler}
      value={inputState.value}
      variant="outlined"
      size={props.size || "small"}
      fullWidth={props.fullWidth || false}
      helperText={hasErrors() ? props.errorText : props.helperText || ""}
      error={hasErrors()}
      className={clsx(
        hasErrors() ? classes.borderedInputError : classes.borderedInput,
        props.noMargin && classes.noMargin
      )}
      style={props.style}
    />
  );
};

export default Input;
