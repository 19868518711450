import React, { useState, useEffect, useContext } from "react";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { formatDistanceToNow, subMinutes } from "date-fns";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import Badge from "@material-ui/core/Badge";

// Icons
import AssignmentIcon from "@material-ui/icons/Assignment";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";

// Components
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { UserProfileContext } from "../../shared/context/userProfile-context";
import taskActionDisplay from "../../shared/util/taskActionDisplay";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    borderRadius: 4,
    boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)",
    backgroundColor: "#FFFFFF",
    paddingLeft: 4,
  },
  body: {
    backgroundColor: grey[50],
  },
}));

const LoadingInboxItem = (props) => {
  return (
    <React.Fragment key={props.id}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: purple[300] }}> </Avatar>
        </ListItemAvatar>
        <div>
          <Skeleton variant="text" width={300} height={25} component="div" />
          <Skeleton variant="text" width={400} height={20} component="div" />
        </div>
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

const InboxPage = (props) => {
  const classes = useStyles();
  const { token } = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const { profile } = useContext(UserProfileContext);
  const [inboxNotifications, setInboxNotifications] = useState([]);

  const storageLastNotificationId = localStorage.getItem("lastNotificationId");

  useEffect(() => {
    let mounted = true;
    if (token) {
      const fetchAssignedTasks = async () => {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/users/inbox`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );
          const notifications = response.data.notifications;
          if (mounted && notifications) {
            setInboxNotifications(notifications);
            localStorage.setItem("lastNotificationId", notifications[0].id);
            // setTimeout(() => {
            //   setStorageLastNotificationId(notifications[0].id);
            // }, 5000);
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchAssignedTasks();
    }
    return () => {
      mounted = false;
    };
  }, [token, sendRequest, profile.id]);

  return (
    <Grid container direction="column" item xs={12} className={classes.body}>
      <Box display="inline" component="div" align="center" marginTop={2}>
        <Typography
          variant="h5"
          align="center"
          display="inline"
          style={{ marginRight: 8 }}
        >
          Notifications
        </Typography>
        <Typography variant="h6" display="inline" color="textSecondary">
          Last 15 days
        </Typography>
      </Box>
      <Container maxWidth="md" style={{ maxHeight: "85vh", overflowY: "auto" }}>
        <List className={classes.paper}>
          {inboxNotifications.length > 0 ? (
            inboxNotifications.map((notification) => {
              const { display, value } = taskActionDisplay(notification);
              return (
                <React.Fragment key={notification.id}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Badge
                        color="primary"
                        variant="standard"
                        badgeContent="new"
                        invisible={Boolean(
                          notification.id <= storageLastNotificationId
                        )}
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      >
                        <Avatar style={{ backgroundColor: purple[300] }}>
                          {notification.type === "commented" ? (
                            <ChatOutlinedIcon />
                          ) : (
                            <AssignmentIcon />
                          )}
                        </Avatar>
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                          }}
                        >
                          <Box component="div" display="inline">
                            <Link
                              component={RouterLink}
                              to={`/projects/${notification.projectId}/${notification.gate}`}
                              underline="none"
                            >
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                display="inline"
                                style={{ fontWeight: "bold" }}
                              >
                                {notification.projectName}
                              </Typography>
                            </Link>
                            {" - "}

                            <Link
                              component={RouterLink}
                              to={`/projects/${notification.projectId}/${notification.gate}?taskId=${notification.taskId}`}
                              color="textPrimary"
                              underline="none"
                            >
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                display="inline"
                              >
                                {notification.taskName}
                              </Typography>
                            </Link>
                          </Box>
                          <Typography variant="body2" color="textSecondary">
                            {notification.dateCreated &&
                              formatDistanceToNow(
                                subMinutes(
                                  new Date(notification.dateCreated),
                                  1
                                ),
                                {
                                  addSuffix: true,
                                }
                              )}
                          </Typography>
                        </div>
                      }
                      secondary={
                        <React.Fragment>
                          {`${notification.first_name} ${notification.last_name} ${display} `}
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {value}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              );
            })
          ) : isLoading ? (
            [0, 1, 2, 3, 4, 5, 6, 7, 8].map((id) => (
              <LoadingInboxItem key={id} id={id} />
            ))
          ) : (
            <Typography variant="body1" align="center">
              No notifications, check back later.
            </Typography>
          )}
        </List>
      </Container>
    </Grid>
  );
};

export default withRouter(InboxPage);
