import React, { useState, useEffect, useContext } from "react";
import { withRouter, useParams } from "react-router-dom";
import clsx from "clsx";
import { CopyToClipboard } from "react-copy-to-clipboard";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import grey from "@material-ui/core/colors/grey";

// Components
import TopHeaderBarProject from "../../shared/components/Navigation/TopHeaderBarProject";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { UserProfileContext } from "../../shared/context/userProfile-context";
import ProjectActions from "../components/ProjectActions";
import { ProjectsContext } from "../../shared/context/projects-context";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  fullFlex: {
    flex: "1 1 auto",
    display: "flex",
    minWidth: "1px",
    flexDirection: "column"
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)"
  },
  body: {
    backgroundColor: grey[50]
  }
}));

const ProjectComparisonsPage = props => {
  const classes = useStyles();
  const { pid, cid } = useParams();
  const { token } = useContext(AuthContext);
  const { profile } = useContext(UserProfileContext);
  const { projects } = useContext(ProjectsContext);
  const { isLoading, sendRequest, error, clearError } = useHttpClient();
  const [comparison, setComparison] = useState(null);

  const project = {
    ...projects.find(project => project.id === parseInt(pid))
  };

  useEffect(() => {
    (async () => {
      if (comparison) return;
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/comparisons/view/${cid}`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        );
        if (response.data) {
          setComparison(response.data.url);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [cid, token, sendRequest]);

  return (
    <div className="view-page">
      <TopHeaderBarProject project={project} user={profile} />
      <Grid
        container
        className={clsx(classes.root, classes.fullFlex)}
        spacing={0}
        direction="row"
      >
        <Grid item xs>
          <ProjectActions
            leftActionsComponent={
              <Grid container alignItems="center" item xs={6}>
                <ButtonGroup color="primary" variant="outlined" size="small">
                  <CopyToClipboard
                    onCopy={() =>
                      console.log("copied portal link", window.location.href)
                    }
                    text={window.location.href}
                  >
                    <Button>Copy Portal Link</Button>
                  </CopyToClipboard>
                  <CopyToClipboard
                    onCopy={() =>
                      console.log("copied draftable link", comparison)
                    }
                    text={comparison}
                  >
                    <Button color="primary" variant="outlined" size="small">
                      Copy Draftable Link
                    </Button>
                  </CopyToClipboard>
                </ButtonGroup>
              </Grid>
            }
          />
        </Grid>
      </Grid>
      <Grid container direction="column" item xs={12}>
        {isLoading || !comparison ? (
          "Loading..."
        ) : (
          <iframe
            src={comparison}
            title="comparison"
            allowFullScreen
            style={{ display: "flex", flex: 1, border: 0 }}
          ></iframe>
        )}
      </Grid>
    </div>
  );
};

export default withRouter(ProjectComparisonsPage);
