import React, { useContext } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Avatar from "@material-ui/core/Avatar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "@material-ui/lab/Skeleton";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";

import grey from "@material-ui/core/colors/grey";

// Components
import TransparentButton from "../../shared/components/FormElements/TransparentButton";
import UserWithAvatar from "../../shared/components/UIElements/UserWithAvatar";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const useStyles = makeStyles(theme => ({
  root: {
    width: 221,
    // width: 500,
    fontSize: 13
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: ".15em 4px",
    fontWeight: 600,
    lineHeight: "15px",
    borderRadius: 2
  },
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 400,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "#f6f8fa"
  },
  header: {
    borderBottom: "1px solid #e1e4e8",
    padding: "8px 10px",
    fontWeight: 600
  },
  inputBase: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent"
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover
    }
  },
  popperDisablePortal: {
    position: "relative"
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2
  },
  text: {
    flexGrow: 1
  },
  emailText: {
    color: grey[500]
  },
  nameText: {
    fontWeight: "bold"
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18
  },
  avatar: {
    color: "#FFF",
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: "12px"
  },
  selectListItem: {
    display: "flex",
    flex: 1,
    // justifyContent: "center"
    alignItems: "center"
  },
  inlineFlex: {
    display: "inline-flex",
    flex: 1,
    alignItems: "center"
  }
}));

export default function TaskAssignButton(props) {
  const classes = useStyles();
  const { pid, task, taskId, assignee, onChange: handleAssigneeChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState();
  const [options, setOptions] = React.useState([]);
  const { token } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleUserSelect = async (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    if (token) {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/tasks/assignee`,
          "PUT",
          JSON.stringify({
            userId: newValue ? newValue.id : null,
            pid: pid,
            taskId: taskId
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        );
        handleAssigneeChange(task, "assignee", newValue);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const open = Boolean(anchorEl);
  const shouldLoadUsers = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!shouldLoadUsers) {
      return undefined;
    }

    (async () => {
      if (token) {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/users/${pid}`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            }
          );
          if (active) {
            const users = response.data;
            setOptions(users);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [shouldLoadUsers, sendRequest, token, pid]);

  React.useEffect(() => {
    setOptions([]);
    setAnchorEl(null);
  }, [pid, token]);

  React.useEffect(() => {
    setValue(assignee);
  }, [assignee, taskId]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <TransparentButton
          aria-describedby="users-menu"
          onClick={handleClick}
          isEmpty={!Boolean(value) && true}
        >
          {value ? (
            <UserWithAvatar
              initials={value.initials}
              name={value.name}
              color={value.color}
            />
          ) : (
            <UserWithAvatar icon={<PersonAddIcon />} name="Unassigned" />
          )}
        </TransparentButton>
      </div>
      <Popper
        id="users-menu"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
      >
        <div className={classes.header}>
          Select a user to assign this task to
        </div>
        <Autocomplete
          open
          loading={isLoading}
          // disableCloseOnSelect //tmp
          // inputValue={(assignee && assignee.name) || ""}
          getOptionSelected={option => {
            if (value) {
              if (option.id === value.id) return true;
            }
            return false;
          }}
          onClose={handleClose}
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal
          }}
          value={value}
          onChange={handleUserSelect}
          disablePortal
          options={options}
          getOptionLabel={option => option.name}
          renderTags={() => null}
          noOptionsText="No users found."
          renderOption={(option, { selected }) =>
            !isLoading ? (
              <Grid container item xs={12} alignItems="center">
                <DoneIcon
                  className={classes.iconSelected}
                  style={{ visibility: selected ? "visible" : "hidden" }}
                />
                <Avatar
                  className={classes.avatar}
                  style={{ backgroundColor: option.color }}
                >
                  {option.initials || ""}
                </Avatar>
                <div className={classes.text}>
                  <span className={classes.nameText}>{option.name} </span>-
                  <span className={classes.emailText}> {option.email}</span>
                </div>
                <CloseIcon
                  className={classes.close}
                  style={{ visibility: selected ? "visible" : "hidden" }}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleUserSelect(event, null);
                    setAnchorEl(null);
                  }}
                  // onClick={() => handleUserSelect(null, null)}
                />
              </Grid>
            ) : (
              <React.Fragment>
                <Skeleton
                  variant="circle"
                  width={22}
                  height={22}
                  style={{ paddingLeft: "4px", margin: "0 10px" }}
                />
                <Skeleton height={22} width="80%" style={{ marginBottom: 5 }} />
              </React.Fragment>
            )
          }
          renderInput={params => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
              endAdornment={
                isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
            />
          )}
        />
      </Popper>
    </React.Fragment>
  );
}
