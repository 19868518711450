import { useState, useCallback, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";

let refreshTokenTimer;

export const useAuth = () => {
  const [token, setToken] = useState();
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [, setCookie, removeCookie] = useCookies(["aor-jwt"]);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setTokenExpirationDate(null);
    localStorage.removeItem("userData");
    setLoadingAuth(false);
  }, []);

  const login = useCallback(
    (token) => {
      let decodedToken;
      try {
        decodedToken = jwt_decode(token);
        if (!decodedToken) throw new Error("unable to decode token.");
      } catch (err) {
        console.log("login error: ", err);
        setLoadingAuth(false);
        logout();
        return false;
      }
      console.log("deocded JWT: ", decodedToken);
      setToken(token);
      setUserId(decodedToken.userId);
      const tokenExpirationDate = new Date(decodedToken.expiry * 1000);
      setTokenExpirationDate(tokenExpirationDate);

      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: decodedToken.userId,
          token: token,
          expiration: tokenExpirationDate.toISOString(),
        })
      );
      setLoadingAuth(false);
    },
    [logout]
  );

  const refreshToken = useCallback(
    async (expiredToken) => {
      setTokenExpirationDate(null);
      try {
        if (!expiredToken) {
          const userData = JSON.parse(localStorage.getItem("userData"));
          expiredToken = userData.token;
          if (!expiredToken)
            throw new Error("Refresh token failed, no token found.");

          if (new Date(userData.expiration) > new Date()) {
            login(expiredToken);
            return;
          }
        }
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/refresh-token`,
          {
            method: "POST",
            body: JSON.stringify({ token: expiredToken }),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + expiredToken,
            },
          }
        );
        const responseData = await response.json();

        if (!response.ok) {
          throw new Error("Response is invalid. " + responseData.message);
        }
        if (responseData.data.token) {
          login(responseData.data.token);
        } else {
          throw new Error("Token was not supplied in response body.");
        }
      } catch (err) {
        console.log(err);
        logout();
      }
    },
    [logout, login]
  );

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      console.log("expiration timer.. ", remainingTime);
      refreshTokenTimer = setTimeout(refreshToken, remainingTime);
    } else {
      clearTimeout(refreshTokenTimer);
    }
  }, [token, refreshToken, tokenExpirationDate]);

  // Gets called on load
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (storedData && storedData.token) {
      if (new Date(storedData.expiration) > new Date()) {
        login(storedData.token);
      } else {
        refreshToken(storedData.token);
      }
    } else {
      setLoadingAuth(false);
    }
  }, [login, refreshToken]); // only runs once. when component mounts (AFTER THE RENDER CYCLE)

  useEffect(() => {
    if (token) {
      setCookie("aor-jwt", token, { path: "/" });
    } else {
      removeCookie("aor-jwt");
    }
  }, [token, setCookie, removeCookie]);

  return {
    token,
    login,
    logout,
    userId,
    loadingAuth,
  };
};
