import React, { useContext } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const TaskJoinButton = props => {
  const { task, onChange } = props;
  const { token } = useContext(AuthContext);
  const { sendRequest } = useHttpClient();

  let isJoined = task.followDate && task.followDate > 0;

  const handleClick = async event => {
    event.preventDefault();
    let followDate = new Date().getTime() / 1000;
    if (isJoined) {
      followDate = 0;
    }
    onChange(task, "followDate", followDate);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/tasks/follow`,
        "PUT",
        JSON.stringify({
          taskId: task.id,
          followDate: followDate
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      );
      console.log(response);
      if (response.status === 201) {
        onChange(task, "followDate", followDate);
        // onChange(task, "followDate", followDate);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Button
      variant={isJoined ? "text" : "outlined"}
      size="small"
      color="primary"
      style={{ marginLeft: "10px" }}
      onClick={handleClick}
      //   disabled={isLoading && true}
    >
      {!false ? (
        isJoined ? (
          "Leave Task"
        ) : (
          "Join Task"
        )
      ) : (
        <CircularProgress size={18} />
      )}
    </Button>
  );
};

export default TaskJoinButton;
