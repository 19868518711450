import React from "react";
import clsx from "clsx";

// MUI Stuff
import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";

// Icons
import CloseIcon from "@material-ui/icons/Close";
// import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

import { capitalize, priorityOptions } from "../../util/helpers";

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: "13px",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontWeight: "bold"
  },
  noPriority: {
    backgroundColor: "transparent",
    color: grey[800],
    borderColor: grey[500],
    "&:hover,&:active,&:focus": {
      backgroundColor: grey[100] + "!important"
    }
  },
  low: {
    backgroundColor: grey[400],
    "&:hover,&:active,&:focus": {
      backgroundColor: grey[500]
    }
  },
  normal: {
    backgroundColor: grey[600],
    "&:hover,&:active,&:focus": {
      backgroundColor: grey[700]
    }
  },
  high: {
    backgroundColor: orange[400],
    "&:hover,&:active,&:focus": {
      backgroundColor: orange[600]
    }
  },
  urgent: {
    backgroundColor: red[400],
    "&:hover,&:active,&:focus": {
      backgroundColor: red[600]
    }
  },
  avatar: {
    color: "#FFF",
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: "12px"
  },
  small: {
    fontSize: "12px",
    paddingLeft: 0,
    paddingRight: 0,
    height: 25,
    fontWeight: 400,
    width: 65
  }
}));

const PriorityBadge = props => {
  const classes = useStyles();
  const { onDelete, deleteIcon, priorityIndex, priority, small } = props;
  let priorityOption;

  if (priorityIndex) {
    priorityOption = priorityOptions[priorityIndex] || null;
  } else {
    priorityOption = priorityOptions.find(val => val.name === priority) || null;
  }

  return (
    <Chip
      className={clsx(
        classes.chip,
        priorityOption ? classes[priorityOption.name] : classes.noPriority,
        small && classes.small
      )}
      color={priorityOption ? "primary" : "default"}
      deleteIcon={deleteIcon || <CloseIcon />}
      onDelete={onDelete ? onDelete : null}
      label={priorityOption ? capitalize(priorityOption.name) : priority || ""}
      onClick={props.onClick || null}
      variant={priorityOption ? "default" : "outlined"}
    />
  );
};

export default PriorityBadge;
