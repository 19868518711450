import React from "react";
import { formatDistanceToNow } from "date-fns";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import grey from "@material-ui/core/colors/grey";

// Components
import UserWithAvatar from "../../shared/components/UIElements/UserWithAvatar";
import Typography from "@material-ui/core/Typography";

const TaskComment = (props) => {
  const { comment } = props;

  return (
    <Grid container spacing={1} style={{ marginBottom: 15 }}>
      <Grid item container alignItems="center" xs={1}>
        <UserWithAvatar
          initials={comment.user.initials}
          color={comment.user.color}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid item container xs={12} spacing={0}>
          <Typography variant="subtitle2" style={{ marginRight: 16 }}>
            {comment.user.name}
          </Typography>
          <Typography variant="body2" style={{ color: grey[500] }}>
            {formatDistanceToNow(new Date(comment.timestamp * 1000), {
              addSuffix: true,
            })}
          </Typography>
        </Grid>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: comment.comment }}
        />
      </Grid>
    </Grid>
  );
};

export default TaskComment;
