import React, { useState, useEffect, useContext, useCallback } from "react";
import clsx from "clsx";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import grey from "@material-ui/core/colors/grey";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// Icons
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";

// Components
import TaskAssignButton from "../components/TaskAssignButton";
import TaskPriorityButton from "../components/TaskPriorityButton";
import TaskNameField from "../components/TaskNameField";
import DatePicker from "../../shared/components/FormElements/DatePicker";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import TaskDescriptionField from "./TaskDescriptionField";
import TaskComment from "./TaskComment";
import TaskJoinButton from "./TaskJoinButton";
import TaskCompleteButton from "./TaskCompleteButton";
import TaskDeleteButton from "./TaskDeleteButton";
import TaskMembersButton from "./TaskMembersButton";
import TaskAddAttachmentButton from "./TaskAddAttachmentButton";
import DynamicInput from "../../shared/components/FormElements/DynamicInput";
import AttachFileButton from "../../shared/components/FormElements/AttachFileButton";
import TaskAction from "./TaskAction";
import DynamicTableTaskElement from "../../shared/components/FormElements/DynamicTableTaskElement";

var sliderWidth = "400px";
var sliderMaxWidth = "400px";

if (window.innerWidth > 1200) {
  sliderWidth = sliderMaxWidth = "400px";
}

if (window.innerWidth > 1350) {
  sliderWidth = sliderMaxWidth = "480px";
}

if (window.innerWidth > 1550) {
  sliderWidth = "580px";
  sliderMaxWidth = "580px";
}

if (window.innerWidth > 1900) {
  sliderWidth = "900px";
  sliderMaxWidth = "920px";
}

const useStyles = makeStyles((theme) => ({
  taskDetailsOverlay: {
    zIndex: 10,
    width: sliderWidth,
    maxWidth: sliderMaxWidth,
    minWidth: "560px",
    height: "100%",
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderColor: grey[300],
    boxShadow: "0 5px 20px 0 rgba(21,27,38,.08)",
    overflow: "hidden",
  },
  fullFlex: {
    flex: "1 1 auto",
    display: "flex",
    minWidth: "1px",
    flexDirection: "column",
  },
  toolbar: {
    height: "55px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    borderBottom: "1px solid " + grey[300],
    padding: "0 20px",
  },
  taskNameInput: {
    marginTop: theme.spacing(2),
    color: grey[300],
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: grey[200],
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
    },
  },
  detailsContent: {
    flex: 1,
    overflowY: "hidden",
  },
  commentsAndLogs: {
    borderTop: "1px solid " + grey[300],
    backgroundColor: grey[100],
    // flex: 1,
    padding: "0 20px",
    flexDirection: "column",
    // maxHeight: "12vh",
    // Testing
    display: "block",
    flex: 0,
  },
  actionIconsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  taskField: {
    // height: "40px",
    marginBottom: theme.spacing(1),
  },
  feed: {
    backgroundColor: grey[100],
    padding: "5px 20px 0",
    // background: grey[100],
    flexShrink: 0,
    marginTop: "auto",
  },
  commentInput: {
    marginTop: "10px",
    backgroundColor: "#FFF",
  },
  taskFieldTitle: {
    color: grey[700],
  },
  scrollableContainer: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    minHeight: 1,
    "& .MuiGrid-container": {
      flexWrap: "nowrap",
    },
    // maxHeight: "94vh"
    height: 1,
  },
  scrollableDetailsBody: {
    minHeight: 1,
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    overflowY: "auto",
  },
}));

const TaskDetailsSlider = (props) => {
  const classes = useStyles(props);
  const {
    open,
    task,
    onChange,
    pid,
    gate,
    dismiss,
    taskId,
    onTaskDelete,
    onNewAttachment,
    fileHistory,
    setFileHistory,
  } = props;
  const { token } = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [selectedDueDate, setSelectedDueDate] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [commentValue, setCommentValue] = useState("");
  const [taskComments, setTaskComments] = useState([]);
  const [taskActions, setTaskActions] = useState([]);
  const [snackBarError, setSnackBarError] = useState(null);

  const handleTaskElementChange = async (elementId, type, value) => {
    console.log("task Element Changed.. ", elementId, type, value);
    const newTaskElements = JSON.parse(JSON.stringify(task.elements));
    const element = newTaskElements.find((element) => element.id === parseInt(elementId));

    if (!element) {
      console.error("element was not found..");
      return;
    }

    if (type === "other") {
      element.otherValue = value;
      element.value = "other";
    } else if (type === "attachment") {
      element.value = value.value;
      element.options = value.options;
    } else if (type === "formula-table") {
      element.value = "";
      element.options = value;
    } else if (type === "multi-select") {
      element.value = "";
      element.options = value;
    } else {
      element.value = value;
      element.otherValue = "";
    }

    if (type !== "attachment" && type !== "formula-table" && type !== "multi-select") {
      if (element.options) {
        const options = element.options.map((option) => {
          return {
            ...option,
            selected: option.value === element.value,
          };
        });
        element.options = options;
      }
    }

    const elementIndex = newTaskElements.findIndex((e) => e.id === element.id);
    newTaskElements[elementIndex] = element;
    onChange(task, "elements", newTaskElements);

    if (type === "attachment") {
      console.log("attachment detected..");
      console.log(element);
      return;
    }

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/tasks/element`,
        "PUT",
        JSON.stringify({
          taskId: task.id,
          elementId: elementId,
          value:
            type === "other"
              ? "other"
              : type === "formula-table"
              ? ""
              : type === "multi-select"
              ? ""
              : value,
          element: element,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      console.log(response);
      if (response.status === 201) {
        fetchActions();
        // onChange(task, "elements", newTaskElements);
      }
    } catch (err) {
      console.log(err);
      if (err.message) {
        setSnackBarError(err.message);
      }
    }
  };

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        dismiss(null);
      }
    },
    [dismiss]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleDateChange = async (date, id) => {
    let parsedDate;

    if (date) {
      parsedDate = new Date(date).getTime() / 1000;
    } else {
      parsedDate = null;
    }

    if (id === "startDate") {
      setSelectedStartDate(date);
    } else {
      setSelectedDueDate(date);
    }

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/tasks/${id}`,
        "PUT",
        JSON.stringify({
          taskId: task.id,
          date: parsedDate,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      console.log(response);
      if (response.status === 201) {
        onChange(task, id, parsedDate);
        fetchActions();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCommentSend = async () => {
    console.log("sending comment");
    if (commentValue === "") return;
    const comment = commentValue;
    setCommentValue("");
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/comments/${task.id}`,
        "POST",
        JSON.stringify({
          taskId: task.id,
          comment: comment,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      console.log(response);
      if (response.status === 201) {
        fetchComments();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (task && open) {
      setSelectedDueDate(task.dueDate * 1000);
      setSelectedStartDate(task.startDate * 1000);
    }
  }, [task, open]);

  const fetchComments = useCallback(async () => {
    if (taskId) {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/comments/${taskId}`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        if (response.data) {
          setTaskComments(response.data);
          // onChange(taskId, "comments", response.data.length); // removed because it would cause an infinite cycling between tasks.
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [token, taskId, sendRequest]);

  const fetchActions = useCallback(async () => {
    if (taskId) {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/actions/task/${taskId}`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        if (response.data && response.data.actions) {
          console.log("actions: ", response.data.actions);
          setTaskActions(response.data.actions);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [token, taskId, sendRequest]);

  useEffect(() => {
    let active = true;
    if (open && active) {
      setTaskComments([]);
      setTaskActions([]);
      fetchComments();
      fetchActions();
    }
    return () => {
      active = false;
    };
  }, [open, fetchActions, fetchComments]);

  return (
    <Slide
      direction="left"
      in={open}
      timeout={{ enter: 500, exit: 450 }}
      // mountOnEnter
      // unmountOnExit
      onExited={() => dismiss(null)}
    >
      <Grid item xs style={{ maxWidth: sliderWidth }}>
        <div className={clsx(classes.taskDetailsOverlay, classes.fullFlex)}>
          {open && (
            <div className={classes.scrollableContainer}>
              <Grid container className={classes.toolbar} direction="row">
                <Grid item xs={7}>
                  <TaskCompleteButton task={task} onChange={onChange} />
                  <TaskJoinButton task={task} onChange={onChange} />
                </Grid>
                <Grid item xs={5} className={classes.actionIconsContainer}>
                  <TaskDeleteButton onTaskDelete={onTaskDelete} task={task} />
                  <TaskMembersButton onTaskDelete={onTaskDelete} task={task} />
                  <TaskAddAttachmentButton onNewAttachment={onNewAttachment} task={task} />
                  <IconButton
                    color="default"
                    aria-label="close"
                    size="small"
                    onClick={() => dismiss(null)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                className={classes.detailsContent}
                spacing={0}
              >
                <div className={classes.scrollableDetailsBody}>
                  <Grid
                    container
                    style={{
                      marginLeft: "-8px",
                      marginBottom: "8px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <TaskNameField
                      id="task-name"
                      placeholder="Write a task name"
                      task={task}
                      onChange={onChange}
                    />
                  </Grid>

                  <Grid
                    container
                    className={classes.taskField}
                    alignItems="center"
                    style={{
                      marginTop: "8px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="body1" className={classes.taskFieldTitle}>
                        Responsible
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TaskAssignButton
                        pid={pid}
                        taskId={task.id}
                        assignee={task.assignee}
                        onChange={onChange}
                        task={task}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    className={classes.taskField}
                    alignItems="center"
                    style={{ marginTop: "8px", padding: "10px 20px" }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="body1" className={classes.taskFieldTitle}>
                        Priority
                      </Typography>
                    </Grid>
                    <Grid item xs={10} style={{}}>
                      <TaskPriorityButton
                        color="urgent"
                        priority={task.priority}
                        task={task}
                        onChange={onChange}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    className={classes.taskField}
                    alignItems="center"
                    style={{
                      marginTop: "12px",
                      padding: "4px 10px 4px 20px",
                      marginBottom: "9px",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="body1" className={classes.taskFieldTitle}>
                        Date
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <DatePicker
                        label="Due Date"
                        id="dueDate"
                        value={selectedDueDate}
                        handleDateChange={handleDateChange}
                        minDate={new Date()}
                        minDateMessage="Date must be after today"
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    className={classes.taskField}
                    alignItems="flex-start"
                    style={{
                      paddingTop: "8px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography
                        variant="body1"
                        style={{ paddingTop: "10.5px" }}
                        className={classes.taskFieldTitle}
                      >
                        Description
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <TaskDescriptionField
                        id="description"
                        task={task}
                        onChange={onChange}
                        placeholder="Add more details to this task"
                        rows="3"
                      />
                    </Grid>
                  </Grid>
                  {Array.isArray(task.elements) &&
                    task.elements.map((element) => {
                      if (element.taskId !== taskId) return null;
                      if (element.type === "formula-table") {
                        return (
                          <React.Fragment key={element.id}>
                            <Divider />
                            <Grid
                              container
                              className={classes.taskField}
                              alignItems="flex-start"
                              style={{
                                paddingTop: 8,
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                marginBottom: 8,
                                alignItems: "center",
                              }}
                            >
                              <DynamicTableTaskElement
                                handleTaskElementChange={handleTaskElementChange}
                                {...element}
                              />
                            </Grid>
                          </React.Fragment>
                        );
                      }
                      return (
                        <React.Fragment key={element.id}>
                          <Divider />
                          <Grid
                            container
                            className={classes.taskField}
                            alignItems="flex-start"
                            style={{
                              paddingTop: 8,
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              marginBottom: 8,
                              alignItems: "center",
                            }}
                          >
                            <Grid item xs={4}>
                              <Typography variant="body1" className={classes.taskFieldTitle}>
                                {element.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              {element.type === "attachment" ? (
                                <div
                                  style={{
                                    paddingLeft: 8,
                                    display: "flex",
                                  }}
                                >
                                  <AttachFileButton
                                    {...element}
                                    handleTaskElementChange={handleTaskElementChange}
                                    taskId={taskId}
                                    pid={pid}
                                    gate={gate}
                                    setFileHistory={setFileHistory}
                                    fileHistory={fileHistory}
                                  />
                                </div>
                              ) : (
                                <DynamicInput
                                  handleTaskElementChange={handleTaskElementChange}
                                  {...element}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    })}

                  {taskComments.length > 0 && (
                    <Grid
                      container
                      className={clsx(classes.taskField, classes.feed)}
                      alignItems="flex-start"
                      style={{
                        paddingTop: "8px",
                        paddingBottom: "0px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        marginBottom: 0,
                      }}
                    >
                      <Grid item xs={10}>
                        {taskComments.map((taskComment) => (
                          <TaskComment comment={taskComment} key={taskComment.id} />
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  {taskActions.length > 0 && (
                    <React.Fragment>
                      <Divider />
                      <Grid
                        container
                        className={clsx(classes.taskField, classes.feed)}
                        alignItems="flex-start"
                        style={{
                          paddingTop: "15px",
                          paddingBottom: "8px",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                      >
                        <Grid item xs={12}>
                          {taskActions.map((action) => (
                            <TaskAction action={action} key={action.id} />
                          ))}
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </div>
              </Grid>

              <Grid
                container
                direction="column"
                alignItems="stretch"
                className={classes.commentsAndLogs}
                spacing={0}
              >
                <div style={{ display: "block", paddingBottom: 12 }}>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      id="description"
                      value={commentValue}
                      placeholder="Ask a question or post an update"
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows="3"
                      onChange={(event) => setCommentValue(event.target.value)}
                      className={classes.commentInput}
                    />
                    <div
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{/* <Typography variant="body1">Collaborators</Typography> */}</div>
                      <div>
                        <Button
                          disabled={!Boolean(commentValue) && true}
                          variant="outlined"
                          color="primary"
                          size="small"
                          style={{ marginTop: "5px" }}
                          endIcon={<SendIcon />}
                          onClick={handleCommentSend}
                        >
                          Send Comment
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
          )}
          <Snackbar
            open={Boolean(snackBarError)}
            autoHideDuration={4000}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            onClose={(event, reason) => {
              if (reason !== "clickaway") {
                setSnackBarError(null);
              }
            }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={() => setSnackBarError(null)}
            >
              {snackBarError}
            </MuiAlert>
          </Snackbar>
        </div>
      </Grid>
    </Slide>
  );
};

export default TaskDetailsSlider;
