import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCloudUploadAlt,
  faShare,
  faColumns
} from "@fortawesome/free-solid-svg-icons";

import "./TopHeader.css";

const TabLink = props => {
  return (
    <li className="top-header__tab">
      <NavLink
        className="Tab-Link"
        to={props.to}
        exact={props.exact || false}
        activeClassName="Tab-selectableTab--isSelected"
      >
        <span
          className={`Tab-selectableTab ${props.active &&
            "Tab-selectableTab--isSelected"}`}
        >
          {props.children}
        </span>
      </NavLink>
    </li>
  );
};

const MenuAnchor = props => {
  return (
    <NavLink
      className={`MenuAnchor MenuAnchor--default ${props.active && "active"}`}
      to={props.to}
      exact={props.exact || false}
    >
      {props.children}
    </NavLink>
  );
};

const TopHeader = props => {
  let { pid, stage } = useParams();
  return (
    <React.Fragment>
      <div
        className="top-header__bar top-header__bar-without-shadow"
        style={{ minHeight: "2rem" }}
      >
        <div className={`top-header__left-container `}>
          <div className="top-header__left-project-title">
            Super Secret Project 1
          </div>
          <div className="top-header__left-actions">
            <ul className="top-header__list">
              <TabLink to={`/project/${pid}`} active={pid ? null : true} exact>
                Ideas
              </TabLink>
              <TabLink to={`/project/${pid}/Feasibility`}>Feasibility</TabLink>
              <TabLink to={`/project/${pid}/Capability`}>Capability</TabLink>
              <TabLink to={`/project/${pid}/Launch`}>Launch</TabLink>
              <TabLink to={`/project/${pid}/Rollout`}>Rollout</TabLink>
              <TabLink to={`/project/${pid}/Closure`}>Closure</TabLink>
            </ul>
          </div>
        </div>
        <div className="top-header__right-container ">
          <div className="top-header__members  d-none d-md-inline-flex">
            <div className="top-header__members-count">8 Members</div>
            <div className="Button Button--secondary">
              <FontAwesomeIcon icon={faShare} style={{ marginRight: "10px" }} />
              Invite
            </div>
          </div>
          <div className="top-header__user">Shendel Ruiz</div>
        </div>
      </div>

      <div
        className={`top-header__bar top-header__bar-border-top `}
        style={{ minHeight: "2rem" }}
      >
        <div className="top-header__left-container">
          <div className="Button Button--secondary">
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: "10px" }} />
            Create Task
          </div>
          <div
            className="Button Button--secondary"
            style={{ marginLeft: "1.5rem" }}
          >
            <FontAwesomeIcon icon={faColumns} style={{ marginRight: "10px" }} />
            Add Section
          </div>
          <div
            className="Button Button--secondary"
            style={{ marginLeft: "1.5rem" }}
          >
            <FontAwesomeIcon
              icon={faCloudUploadAlt}
              style={{ marginRight: "10px" }}
            />
            Upload Files
          </div>
        </div>
        <div className="top-header__right-container">
          <MenuAnchor
            to={`/project/${pid}/${stage}`}
            exact
            active={pid ? (stage ? null : true) : stage ? null : true}
          >
            Tasks
          </MenuAnchor>
          <MenuAnchor to={`/project/${pid}/${stage}/Chart`}>
            Gantt Chart
          </MenuAnchor>
          <MenuAnchor to={`/project/${pid}/${stage}/Timeline`} exact>
            Timeline
          </MenuAnchor>
          <MenuAnchor to={`/project/${pid}/${stage}/Calendar`} exact>
            Calendar View
          </MenuAnchor>
          <MenuAnchor to={`/project/${pid}/${stage}/Files`} exact>
            Files
          </MenuAnchor>
          <MenuAnchor to={`/project/${pid}/${stage}/Members`} exact>
            Members
          </MenuAnchor>
          <MenuAnchor to={`/project/${pid}/${stage}/Settings`} exact>
            Settings
          </MenuAnchor>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopHeader;
