import React from "react";

import "./Task.css";

const Task = props => {
  return (
    <div className="project-task__row" role="row">
      <div
        role="gridcell"
        aria-label="task name..."
        className="project-task__cell project-task__cell-taskname"
      >
        <div className="project-task__drag-handle">
          <svg className="project-task__handle-icon" viewBox="0 0 24 24">
            <path d="M10,4c0,1.1-0.9,2-2,2S6,5.1,6,4s0.9-2,2-2S10,2.9,10,4z M16,2c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S17.1,2,16,2z M8,10 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S9.1,10,8,10z M16,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S17.1,10,16,10z M8,18 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S9.1,18,8,18z M16,18c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S17.1,18,16,18z"></path>
          </svg>
        </div>
        <label>Task Name</label>
        <div className="project-task__details-area">
          <span className="project-task__details-btn">
            Details
            <svg
              className="project-task__handle-icon project-task__details-btn-icon"
              viewBox="0 0 24 24"
            >
              <path d="M8.9,20.4c-0.4,0-0.7-0.1-1-0.4c-0.6-0.6-0.7-1.5-0.1-2.1l5.2-5.8L7.8,6C7.3,5.4,7.3,4.4,8,3.9C8.6,3.3,9.5,3.4,10.1,4l6.1,7.1c0.5,0.6,0.5,1.4,0,2l-6.1,6.8C9.8,20.3,9.4,20.4,8.9,20.4z"></path>
            </svg>
          </span>
        </div>
      </div>

      <div
        role="gridcell"
        className="project-task__cell "
        style={{ width: "120px" }}
      >
        Shendel Ruiz
      </div>
      <div
        role="gridcell"
        className="project-task__cell"
        style={{ width: "120px" }}
      >
        April 15
      </div>
      <div
        role="gridcell"
        className="project-task__cell"
        style={{ width: "120px" }}
      >
        Ongoing
      </div>
      <div
        role="gridcell"
        className="project-task__cell"
        style={{ width: "120px" }}
      >
        Low
      </div>
    </div>
  );
};

export default Task;
