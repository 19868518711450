// Full list https://www.lifewire.com/file-extensions-and-mime-types-3469109
const fileExtensions = [
  {
    name: "",
    mime: "",
    extension: ""
  },
  {
    name: "text file",
    mime: "text/plain",
    extension: "txt"
  },
  {
    name: "Rich Text File",
    mime: "text/richtext",
    extension: "rtx"
  },
  {
    name: "Bitmap",
    mime: "image/bmp",
    extension: "bmp"
  },
  {
    name: "JPEG Image",
    mime: "image/jpeg",
    extension: "jpg"
  },
  {
    name: "PowerPoint presentation",
    mime: "application/vnd.ms-powerpoint",
    extension: "ppt"
  },
  {
    name: "PowerPoint presentation",
    mime: "application/vnd.ms-powerpoint",
    extension: "pptx"
  },
  {
    name: "Excel spreadsheet",
    mime: "application/vnd.ms-excel",
    extension: "xls"
  },
  {
    name: "Word document",
    mime: "application/msword",
    extension: "docx"
  },
  {
    name: "Word document",
    mime:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    extension: "docx"
  },
  {
    name: "Acrobat PDF File",
    mime: "application/pdf",
    extension: "pdf"
  },
  {
    name: "Excel Spreadsheet",
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    extension: "xlsx"
  },
  {
    name: "Excel Spreadsheet (CSV)",
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    extension: "csv"
  }
];

const rejectedExtensions = ["exe", "php"];

export { fileExtensions, rejectedExtensions };
