import React, { useRef, useState, useEffect, useContext } from "react";
import FileIcon, { defaultStyles } from "react-file-icon";
import clsx from "clsx";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import grey from "@material-ui/core/colors/grey";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";
// Components
import TransparentButton from "../../shared/components/FormElements/TransparentButton";
import {
  fileExtensions,
  rejectedExtensions
} from "../../shared/util/fileExtensions";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import projectResponsibilities from "../../shared/util/projectResponsibilities";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: "100%"
  },
  taskInput: {
    marginBottom: theme.spacing(0)
  },
  avatar: {
    color: grey[400],
    border: "1px solid " + grey[400],
    backgroundColor: "transparent",
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: "12px"
  },
  inlineFlex: {
    display: "inline-flex",
    alignItems: "center"
  },
  transparentButton: {
    border: "1px solid " + grey[400]
  }
}));

const ProjectInnovationFileUpload = props => {
  const {
    setFileTemplate,
    fileTemplate,
    setUploadingFile,
    setFileTemplateId
  } = props;
  const classes = useStyles();
  const [fileError, setFileError] = useState(null);
  const { isLoading, sendRequest, clearError } = useHttpClient();
  const { token } = useContext(AuthContext);

  const filePickerRef = useRef();

  useEffect(() => {
    setUploadingFile(isLoading);
  }, [setUploadingFile, isLoading]);

  const handleButtonClick = event => {
    event.preventDefault();
    filePickerRef.current.click();
  };

  const handleFileChange = event => {
    let pickedFile;
    setFileTemplate(null);
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      const fileSize = (pickedFile.size / 1024 / 1024).toFixed(4); // MB
      if (
        rejectedExtensions.includes(pickedFile.name.split(".").pop()) === false
      ) {
        if (fileSize < 200) {
          pickedFile.extension =
            fileExtensions.find(ext => ext.mime === pickedFile.type) ||
            fileExtensions[0];
          uploadFile(pickedFile);
        } else {
          setFileError("File must be less than 200MB");
        }
      } else {
        setFileError("invalid file (check file type or size)");
      }
    } else {
      setFileError("no file selected");
    }
  };

  const uploadFile = async file => {
    try {
      const formData = new FormData();
      let response;
      formData.append("file", file);
      formData.append("mime", file.type);

      response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/files/csv`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + token
        }
      );
      console.log(response);
      if (response.data.file) {
        setFileError(null);
        clearError();
        setFileTemplate(file);
        setFileTemplateId(response.data.file.id);
      }
    } catch (err) {
      console.log(err);
      setFileError(err.message);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            You may upload a responsibilities CSV template or skip this step and
            the previous template will be used.
          </Typography>
          <Typography variant="body1" paragraph>
            Alternatively,{" "}
            <Link
              color="primary"
              href={`${process.env.REACT_APP_API_URL}/download/csv/responsibilities`}
              target="_blank"
            >
              click here
            </Link>
            {` `}to download the last template
          </Typography>
        </Grid>
        <Grid container item xs={12} justify="center" alignItems="center">
          {!fileTemplate ? (
            <React.Fragment>
              <TransparentButton
                aria-describedby="users-menu"
                onClick={handleButtonClick}
                isEmpty={true}
                style={{ border: "1px solid " + grey[400] }}
              >
                <div className={classes.inlineFlex}>
                  <Avatar className={classes.avatar}>
                    <AttachFileIcon fontSize="small" className={classes.icon} />
                  </Avatar>
                  <Typography variant="body1" noWrap>
                    Upload Responsibilities Template
                  </Typography>
                </div>
              </TransparentButton>
              {isLoading && (
                <CircularProgress
                  color="primary"
                  size={26}
                  style={{ marginLeft: 12 }}
                />
              )}
            </React.Fragment>
          ) : (
            <div
              className={clsx(classes.inlineFlex, classes.transparentButton)}
              style={{ padding: 4, borderRadius: 4 }}
            >
              <FileIcon extension="csv" {...defaultStyles.xlsx} size={30} />
              <div
                style={{
                  marginLeft: 8,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Typography
                  variant="body2"
                  color="textPrimary"
                  display="inline"
                >
                  {fileTemplate.name}
                </Typography>
              </div>
              <div style={{ marginLeft: 25, display: "flex" }}>
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    setFileTemplate(null);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12} container justify="center">
          {fileError && (
            <Typography variant="body2" color="error">
              {fileError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} container>
          {projectResponsibilities.map(resp => {
            if (!resp.name) return null;
            return (
              <Typography variant="body2" paragraph key={resp.name}>
                {resp.name} - {resp.display} - {resp.description}
              </Typography>
            );
          })}
        </Grid>
      </Grid>

      <input
        id="file"
        ref={filePickerRef}
        type="file"
        style={{ display: "none" }}
        accept=".csv"
        onChange={handleFileChange}
        value=""
      />
    </div>
  );
};

export default ProjectInnovationFileUpload;
