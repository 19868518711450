import React from "react";
import { formatDistanceToNow, subMinutes } from "date-fns";

// MUI Stuff
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

// Components
import taskActionDisplay from "../../shared/util/taskActionDisplay";

const LargeTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);

const TaskAction = (props) => {
  const { action } = props;

  const { display, value } = taskActionDisplay(action);

  const shortText = (text) => {
    var shortText = text;
    if (text.length > 40) {
      shortText = text.substring(0, 40) + "...";
    }
    return shortText;
  };
  return (
    <Grid
      item
      container
      spacing={1}
      style={{ marginBottom: 15 }}
      alignItems="center"
    >
      <Typography
        variant="subtitle2"
        color="textSecondary"
        style={{ marginRight: 3 }}
      >
        {action.first_name} {action.last_name?.substring(0, 1)}
        {". "}
      </Typography>
      <LargeTooltip title={display} placement="bottom" arrow enterDelay={300}>
        <Typography variant="body2" color="textSecondary" noWrap>
          {display.length > 25 && value.length > 50
            ? display.substring(0, 25) + "..."
            : display}
        </Typography>
      </LargeTooltip>
      {value ? (
        <LargeTooltip title={value} placement="bottom" arrow enterDelay={300}>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{ marginLeft: 3 }}
          >
            {shortText(value)}.
          </Typography>
        </LargeTooltip>
      ) : (
        <Typography variant="body2" color="textSecondary">
          .
        </Typography>
      )}
      <Tooltip
        title={action.dateCreated.toString()}
        placement="bottom"
        arrow
        leaveDelay={150}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginLeft: 8, fontSize: "0.7rem" }}
        >
          {formatDistanceToNow(subMinutes(new Date(action.dateCreated), 1), {
            addSuffix: true,
          })}
        </Typography>
      </Tooltip>
    </Grid>
  );
};

export default TaskAction;
