import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

// MUI
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Icons
import AddIcon from "@material-ui/icons/Add";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GroupIcon from "@material-ui/icons/Group";
import ArchiveIcon from "@material-ui/icons/Archive";
import MenuIcon from "@material-ui/icons/Menu";
import HomeWork from "@material-ui/icons/HomeWork";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import NotificationsIcon from "@material-ui/icons/Notifications";

// Components
import ListItemLink from "./ListItemLink";
import NewProjectModal from "../Modals/NewProjectModal";
import { UserProfileContext } from "../../context/userProfile-context";
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import { ProjectsContext } from "../../context/projects-context";
import { getActiveGate } from "../../util/helpers";
import aorLogo from "../../../assets/images/AOR_Logo_RGB_150dpi.jpg";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(2),
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  navBold: {
    "& .MuiListItemText-primary": {
      fontWeight: "bold",
    },
  },
  outerScrollContainer: {
    width: "100%",
    overflow: "hidden",
    height: "15rem",
  },
  innerScrollContainer: {
    height: "99%",
    width: "100%",
    overflow: "auto",
    // paddingRight: "20px", // might need this.
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const ResponsiveDrawer = (props) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [openNewProjectModal, setOpenNewProjectModal] = React.useState(false);
  const { favorites, profile } = useContext(UserProfileContext);
  const { projects } = useContext(ProjectsContext);
  // const { token } = useContext(AuthContext);
  // const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const recentProjects = projects.filter((p) => p.archiveDate === null);
  recentProjects.sort((a, b) => b.lastView - a.lastView);

  const drawer = (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <div className={classes.toolbar}>
        <Link to="/">
          <img
            src={aorLogo}
            style={{ width: 130, marginTop: 4 }}
            alt="Innovation Portal"
          />
        </Link>
      </div>
      <Divider />
      <List dense>
        {[
          {
            id: 1,
            display: "Home",
            to: "/",
            icon: <HomeWork />,
            exact: true,
          },
          {
            id: 3,
            display: "Organization",
            to: "/organization",
            icon: <GroupIcon />,
            exact: true,
            protected: true,
          },
          {
            id: 4,
            display: "My Tasks",
            to: "/tasks",
            icon: <AssignmentIcon />,
            exact: true,
          },
          {
            id: 5,
            display: "Inbox",
            to: "/inbox",
            icon: <NotificationsIcon />,
            exact: true,
          },
          {
            id: 6,
            display: "Archive",
            to: "/archive",
            icon: <ArchiveIcon />,
            exact: true,
          },
        ].map((navItem) => {
          if (navItem.protected === true) {
            if (profile.accesslevel <= 100) return null;
          }
          return (
            <ListItemLink
              to={navItem.to}
              primary={navItem.display}
              icon={navItem.icon}
              key={navItem.id}
              exact={navItem.exact}
            />
          );
        })}
      </List>
      <Divider />
      <List dense>
        <ListItem button onClick={() => setOpen(!open)}>
          <ListItemText primary="Favorites" className={classes.navBold} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            {favorites.map((favProject) => {
              const favoriteProject = projects.find(
                (p) => p.id === favProject.id
              );
              if (!favoriteProject) return null;
              if (favoriteProject.archiveDate !== null) return null;
              return (
                <ListItemLink
                  to={`/projects/${favoriteProject.id}/${getActiveGate(
                    favoriteProject
                  )}`}
                  primary={favoriteProject.name}
                  key={favoriteProject.id}
                  smallFont={true}
                  className={classes.nested}
                />
              );
            })}
          </List>
        </Collapse>
      </List>
      <Divider />
      <List
        dense
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <ListItemLink
          to="/"
          primary="Projects"
          rightIcon={<AddIcon fontSize="inherit" />}
          rightIconActionClick={() => setOpenNewProjectModal(true)}
          className={classes.navBold}
          exact
        />
        {/* <div className={classes.outerScrollContainer}>
          <div className={classes.innerScrollContainer}> */}
        <Scrollbars
          // style={{ height: "45vh", width: "240px" }}
          style={{
            width: 240,
            overflow: "hidden",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 1,
            position: "relative",
            height: "100%",
          }}
          hideTracksWhenNotNeeded={true}
          autoHide={true}
          // autoHeight={true}
          // autoHeightMin={200}
          // autoHeightMax={410}
          className="drawer-scrollbar"
        >
          {recentProjects.map((project) => {
            return (
              <ListItemLink
                to={`/projects/${project.id}/${getActiveGate(project)}`}
                primary={project.name}
                key={project.id}
                pid={project.id}
                // rightIcon={<MoreHoriz fontSize="inherit" />}
                smallFont={true}
                className={classes.nested}
                exact={false}
              />
            );
          })}
        </Scrollbars>
        {/* </div>
        </div> */}
      </List>
      <div
        style={{
          // display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          flex: 1,
          padding: 15,
          marginBottom: 10,
          display: "none",
        }}
      >
        <Button variant="outlined" color="primary">
          Request a Feature
        </Button>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className={classes.root}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </div>
      <NewProjectModal
        open={openNewProjectModal}
        setOpen={setOpenNewProjectModal}
      />
    </React.Fragment>
  );
};

export default withRouter(ResponsiveDrawer);
