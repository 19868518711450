import React, { useContext } from "react";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

// Components
import { useForm } from "../../hooks/form-hook";
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import TextInput from "../FormElements/Input";
import { VALIDATOR_REQUIRE } from "../../util/validators";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiPaper-root": {
      minWidth: 360
    }
  },
  taskInput: {
    marginBottom: theme.spacing(0)
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const NewSectionModal = props => {
  const classes = useStyles();
  const { token } = useContext(AuthContext);
  const {
    newSectionModal,
    setNewSectionModal,
    pid,
    stage,
    onNewSection
  } = props;
  const { isLoading, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false
      }
    },
    false
  );

  const handleClose = () => {
    setNewSectionModal(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    clearError();
    if (formState.inputs.name.isValid) {
      let response;
      try {
        response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/sections/${pid}/${stage}`,
          "POST",
          JSON.stringify({
            name: formState.inputs.name.value
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        );
        if (response.data.section) {
          onNewSection({
            ...response.data.section,
            id: response.data.id
          });
          handleClose();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={newSectionModal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">Create a Section</DialogTitle>
        <Divider />
        <DialogContent>
          <TextInput
            autoComplete="off"
            autoFocus
            margin="normal"
            id="name"
            label="Section Name"
            type="text"
            fullWidth
            variant="outlined"
            className={classes.taskInput}
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={isLoading === true}
          >
            Create
            {isLoading === true && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewSectionModal;
