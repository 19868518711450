import React, { useContext, useEffect, useState } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import GroupIcon from "@material-ui/icons/Group";

import UserWithAvatar from "../../shared/components/UIElements/UserWithAvatar";
import projectResponsibilities from "../../shared/util/projectResponsibilities";

const TaskMembersButton = (props) => {
  const { task, onTaskDelete } = props;
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const { token } = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) return;
    setMembers([]);
    const getTaskMembers = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/tasks/${task.id}/members`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        console.log(response);
        if (response.data.members) {
          setMembers(response.data.members);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getTaskMembers();
  }, [open, token, sendRequest, task]);

  return (
    <React.Fragment>
      <IconButton
        color="default"
        aria-label="task members with responsibilities"
        size="small"
        onClick={handleClickOpen}
      >
        <GroupIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          {task.name} - Members & Responsibilities
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table aria-label="members">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Role</TableCell>
                  <TableCell align="left">Responsibility</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && members.length === 0 ? (
                  [0, 1, 2, 3, 4].map((val) => (
                    <TableRow key={val} hover={true}>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                  ))
                ) : members.length < 1 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <Typography variant="body1">
                        No members... Please invite a member to get started.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  members.map((member, index) => {
                    const projectResponsibility = projectResponsibilities.find(
                      (resp) => resp.name === member.responsibility
                    );
                    return (
                      <TableRow hover={true} key={index}>
                        <TableCell component="th" scope="row">
                          <UserWithAvatar
                            color={member.color}
                            initials={member.initials}
                            name={member.name}
                            variant="body2"
                          />
                        </TableCell>
                        <TableCell align="left">{member.role}</TableCell>
                        <TableCell align="left">
                          {projectResponsibility
                            ? projectResponsibility.display
                            : "n/a"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary" variant="text">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default TaskMembersButton;
