import React from "react";

import Task from "../components/Task-old";
import "./ProjectTasks.css";

const ProjectTasksOld = props => {
  return (
    <React.Fragment>
      <div className="d-flex" style={{ height: "100%", position: "relative" }}>
        <div className="project-tasks__container" role="grid">
          <div
            className="project-tasks__header project-tasks__header-shadow"
            role="row"
            style={{ paddingRight: "21px", display: "flex" }}
          >
            <div className="project-tasks__header-left" style={{ flex: 3 }}>
              <div className="project-tasks__header-cell ">
                <div
                  className="project-tasks__header-border"
                  role="columnheader"
                >
                  <div className="project-tasks__header-task-name">
                    Task Name
                  </div>
                </div>
              </div>
            </div>

            <div
              className="project-tasks__header-right"
              style={{ right: "17px" }}
            >
              <div className="project-tasks__header-right-container">
                <div className="project-tasks__header-right-wrapper">
                  <div
                    className="project-tasks__header-field-heading"
                    style={{ width: "120px" }}
                  >
                    <div className="project-tasks__header-task-name">
                      Assigned To
                    </div>
                  </div>

                  <div
                    className="project-tasks__header-field-heading d-none d-lg-inline-flex"
                    style={{ width: "120px" }}
                  >
                    <div className="project-tasks__header-task-name">
                      Due Date
                    </div>
                  </div>

                  <div
                    className="project-tasks__header-field-heading  d-none d-md-inline-flex"
                    style={{ width: "120px" }}
                  >
                    <div className="project-tasks__header-task-name">
                      Status
                    </div>
                  </div>

                  <div
                    className="project-tasks__header-field-heading d-none d-md-inline-flex"
                    style={{ width: "120px", borderRight: 0 }}
                  >
                    <div className="project-tasks__header-task-name">
                      Priority
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="project-tasks__container-vertical-scroll">
            <div className="project-tasks__section-header-container">
              <div className="project-tasks__section-header">
                <div
                  className="project-tasks__section-btn"
                  style={{ marginLeft: "35px" }}
                >
                  Untitled Section
                </div>
              </div>
            </div>
            <Task />
            <Task />
            <Task />
            <Task />
            <Task />
            <Task />
            <div className="project-tasks__section-header-container">
              <div className="project-tasks__section-header">
                <div
                  className="project-tasks__section-btn"
                  style={{ marginLeft: "35px" }}
                >
                  Section 2
                </div>
              </div>
            </div>
            <Task />
            <Task />
            <Task />
            <Task />
            <div className="project-tasks__section-header-container">
              <div className="project-tasks__section-header">
                <div
                  className="project-tasks__section-btn"
                  style={{ marginLeft: "35px" }}
                >
                  Section 2
                </div>
              </div>
            </div>
            <Task />
            <Task />
            <Task />
            <Task />
            <div className="project-tasks__section-header-container">
              <div className="project-tasks__section-header">
                <div
                  className="project-tasks__section-btn"
                  style={{ marginLeft: "35px" }}
                >
                  Section 2
                </div>
              </div>
            </div>
            <Task />
            <Task />
            <Task />
            <Task />
            <div className="project-tasks__section-header-container">
              <div className="project-tasks__section-header">
                <div
                  className="project-tasks__section-btn"
                  style={{ marginLeft: "35px" }}
                >
                  Section 2
                </div>
              </div>
            </div>
            <Task />
            <Task />
            <Task />
            <Task />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectTasksOld;
