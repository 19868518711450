import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";

const getActiveGate = (project) => {
  let activeGate = "list";
  if (project) {
    let gates = [];
    if (typeof project.gates === "string") {
      gates = JSON.parse(project.gates);
    } else if (typeof project.gates === "object") {
      gates = project.gates;
    }
    // activeGate = gates.find(g => g.active === true).name; // finds first entry. we need last
    gates.forEach((gate) => {
      if (gate.active === true) activeGate = gate.name;
    });
  }
  return activeGate;
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const generateRepeatingArray = (num, whatTo) => {
  const arr = [];
  for (var i = 0; i < num; i++) {
    arr.push(whatTo);
  }
  return arr;
};

const priorityOptions = [
  {
    id: 0,
    name: "Select a priority",
    color: grey[800],
  },
  {
    id: 1,
    name: "low",
    color: grey[400],
  },
  {
    id: 2,
    name: "normal",
    color: grey[600],
  },
  {
    id: 3,
    name: "high",
    color: orange[400],
  },
  {
    id: 4,
    name: "urgent",
    color: red[400],
  },
];

export { capitalize, priorityOptions, generateRepeatingArray, getActiveGate };
