import React from "react";
import clsx from "clsx";

// MUI stuff
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import grey from "@material-ui/core/colors/grey";
// Icons
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  headerContainer: {
    paddingLeft: theme.spacing(0),
    height: "55px",
    "&:hover": {
      "& button": {
        visibility: "visible"
      }
    }
  },
  icon: {
    padding: 0,
    marginLeft: "0.7rem",
    marginRight: "0.75rem",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "& svg": {
      height: "16px",
      width: "16px"
    }
  },
  hoverIcons: {
    visibility: "hidden"
  }
}));

const TaskSection = props => {
  const classes = useStyles();
  const { title, onClick } = props;
  return (
    <Grid item xs={12} md={6}>
      <Grid
        container
        className={classes.headerContainer}
        alignItems="center"
        justify="flex-start"
      >
        <IconButton
          aria-label="minimize"
          className={classes.icon}
          color="inherit"
        >
          <ArrowDownwardIcon fontSize="small" />
        </IconButton>
        <Typography variant="h6" style={{ fontSize: "17px" }}>
          {title}
        </Typography>
        <IconButton
          aria-label="create new project"
          className={clsx(classes.icon, classes.hoverIcons)}
          color="inherit"
          onClick={onClick}
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default TaskSection;
