import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

// Components
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { ProjectsContext } from "../../shared/context/projects-context";

import { grey } from "@material-ui/core/colors";
import { capitalize } from "../../shared/util/helpers";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  fullFlex: {
    flex: "1 1 auto",
    display: "flex",
    minWidth: "1px",
    flexDirection: "column",
  },
  table: {
    "& .MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#fafafa",
    },
  },
  paper: {
    borderRadius: 4,
    boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)",
    height: "100%",
    maxHeight: "calc(100% - 60px)",
  },
  body: {
    backgroundColor: grey[50],
    height: "100%",
  },
  hoverTable: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const ProjectsArchivePage = (props) => {
  const classes = useStyles();
  const { token } = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const { projects } = useContext(ProjectsContext);

  let archivedProjects = projects.filter((p) => p.archiveDate !== null);
  archivedProjects.sort((a, b) => b.id - a.id);

  return (
    <div className="view-page">
      <Grid container direction="column" item xs={12} className={classes.body}>
        <Container maxWidth="md" style={{ height: "100%" }}>
          <Typography variant="h4" align="center" style={{ padding: "12px 0" }}>
            Archived Projects
          </Typography>
          <TableContainer
            component={Paper}
            style={{ marginTop: 0 }}
            className={classes.paper}
          >
            <Table className={classes.table} aria-label="members">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Gate</TableCell>
                  <TableCell align="left" size="small">
                    Brand
                  </TableCell>
                  <TableCell align="left" size="small">
                    Category
                  </TableCell>
                  <TableCell align="left" size="small">
                    Start Date
                  </TableCell>
                  <TableCell align="left" size="small">
                    Launch Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {archivedProjects.length < 1 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      <Typography variant="body1">
                        There are no archived projects at this time.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  archivedProjects.map((project, index) => {
                    let activeGate;
                    project.gates.forEach((gate) => {
                      if (gate.active === true)
                        activeGate = capitalize(gate.name);
                    });
                    return (
                      <TableRow
                        key={project.id}
                        hover={true}
                        onClick={() => {
                          props.history.push(
                            `/projects/${project.id}/${activeGate}`
                          );
                        }}
                        className={classes.hoverTable}
                      >
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">
                            {project.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{activeGate}</TableCell>
                        <TableCell align="left">{project.brand}</TableCell>
                        <TableCell align="left">{project.category}</TableCell>
                        <TableCell align="left">
                          {project.startDate &&
                            format(
                              new Date(project.startDate * 1000),
                              "MMM do yyyy"
                            )}
                        </TableCell>
                        <TableCell align="left">
                          {project.launchDate &&
                            format(
                              new Date(project.launchDate * 1000),
                              "MMM do yyyy"
                            )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Grid>
    </div>
  );
};

export default withRouter(ProjectsArchivePage);
