import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { purple } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";

import aorLogo from "../../../../assets/images/AOR_Logo_RGB_150dpi.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  wrapper: {},
  progressBar: {
    color: purple[500],
  },
  loadingText: {
    marginTop: theme.spacing(2),
  },
  logo: {
    width: 300,
    marginBottom: 30,
  },
}));

const FullScreenLoader = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={aorLogo} alt="AOR Innovation Portal" className={classes.logo} />
      <CircularProgress className={classes.progressBar} />
      <Typography color="textSecondary" className={classes.loadingText}>
        {props.message || "Loading..."}
      </Typography>
    </div>
  );
};

export default FullScreenLoader;
