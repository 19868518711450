import React, { useState, useEffect, useContext } from "react";
import { withRouter, useParams } from "react-router-dom";
import clsx from "clsx";
import Chart from "react-google-charts";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";

// Components
import TopHeaderBarProject from "../../shared/components/Navigation/TopHeaderBarProject";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { UserProfileContext } from "../../shared/context/userProfile-context";
import ProjectActions from "../components/ProjectActions";
import { purple } from "@material-ui/core/colors";
import { CircularProgress } from "@material-ui/core";
import { ProjectsContext } from "../../shared/context/projects-context";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  fullFlex: {
    flex: "1 1 auto",
    display: "flex",
    minWidth: "1px",
    flexDirection: "column",
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)",
  },
  body: {
    backgroundColor: grey[50],
  },
}));

const initialChartData = [
  [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
  ],
];

const ProjectGanttPage = (props) => {
  const classes = useStyles();
  const { pid, stage } = useParams();
  const { token } = useContext(AuthContext);
  const { profile } = useContext(UserProfileContext);
  const { projects } = useContext(ProjectsContext);
  const [chartData, setChartData] = useState(initialChartData);
  const { isLoading, sendRequest, error, clearError } = useHttpClient();

  const project = {
    ...projects.find((project) => project.id === parseInt(pid)),
  };

  useEffect(() => {
    let mounted = true;
    if (token && pid) {
      const fetchTasks = async () => {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/tasks/${pid}/${stage}`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );

          const tasks = response.data.tasks;
          console.log(tasks);

          let data = [
            [
              { type: "string", label: "Task ID" },
              { type: "string", label: "Task Name" },
              { type: "string", label: "Resource" },
              { type: "date", label: "End Date" },
              { type: "number", label: "Duration" },
              { type: "number", label: "Percent Complete" },
              { type: "string", label: "Dependencies" },
            ],
          ];
          tasks.forEach((task, index) => {
            if (task.dueDate > 0 && task.startDate > 0) {
              const taskDependantOn =
                !!task.dependantOn &&
                tasks.find((t) => t.id == task.dependantOn && t.startDate && t.dueDate)
                  ? task.dependantOn.toString()
                  : null;
              data.push([
                task.id.toString(),
                task.name,
                null,
                new Date(task.startDate * 1000),
                new Date(task.dueDate * 1000),
                (task.dueDate - task.startDate) * 1000,
                task.completeDate > 0 ? 100 : 0,
                taskDependantOn,
                // null,
              ]);
            }
          });
          console.log(data);
          if (mounted) {
            clearError();
            setChartData(data);
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchTasks();
    }
    return () => {
      mounted = false;
    };
  }, [pid, token, sendRequest]);

  return (
    <div className="view-page">
      <TopHeaderBarProject project={project} user={profile} />
      <Grid container className={clsx(classes.root, classes.fullFlex)} spacing={0} direction="row">
        <Grid item xs>
          <ProjectActions />
        </Grid>
      </Grid>
      <Grid container direction="column" item xs={12} className={classes.body}>
        <Container maxWidth="lg" className={classes.paper}>
          <Grid container spacing={3} style={{ marginTop: 12 }} justify="center">
            <Grid container justify="center" item xs={12}>
              {isLoading ? (
                <CircularProgress size={100} color="primary" />
              ) : chartData.length > 1 ? (
                <Chart
                  width={"100%"}
                  // height={"800px"}
                  chartType="Gantt"
                  loader={<CircularProgress size={100} color="primary" />}
                  data={chartData}
                  options={{
                    height: 700,
                    gantt: {
                      palette: [
                        {
                          color: purple[300],
                          dark: purple[500],
                          light: purple[200],
                        },
                      ],
                      trackHeight: 40, // was 30
                      criticalPathEnabled: false,
                      //   percentEnabled: false
                    },
                  }}
                  rootProps={{ "data-gantt": "2" }}
                />
              ) : (
                <Typography variant="h6">
                  No data to display. Please make sure tasks have a start date and due date on
                  some/all tasks.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </div>
  );
};

export default withRouter(ProjectGanttPage);
