import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faPlus,
  faHome,
  faList,
  faUserFriends,
  faCheckCircle,
  faArchive
} from "@fortawesome/free-solid-svg-icons";

import NavLink from "./NavLink";

import "./SidebarNav.css";

const SidebarNav = props => {
  return (
    <React.Fragment>
      <div className="sidebar-root d-none d-md-flex">
        {props.children}
        <div className="sidebar-root__header">
          <Link to="/"> Portal</Link>
          <button
            className="SideBurgerIcon SidebarHeader-sideBurgerIcon"
            aria-label="Hide sidebar"
          >
            <svg className="SideBurgerIcon-image" viewBox="0 0 50 32">
              <path d="M49,4H19c-0.6,0-1-0.4-1-1s0.4-1,1-1h30c0.6,0,1,0.4,1,1S49.6,4,49,4z"></path>
              <path d="M49,16H19c-0.6,0-1-0.4-1-1s0.4-1,1-1h30c0.6,0,1,0.4,1,1S49.6,16,49,16z"></path>
              <path d="M49,28H19c-0.6,0-1-0.4-1-1s0.4-1,1-1h30c0.6,0,1,0.4,1,1S49.6,28,49,28z"></path>
              <path d="M8.1,22.8c-0.3,0-0.5-0.1-0.7-0.3L0.7,15l6.7-7.8c0.4-0.4,1-0.5,1.4-0.1c0.4,0.4,0.5,1,0.1,1.4L3.3,15l5.5,6.2   c0.4,0.4,0.3,1-0.1,1.4C8.6,22.7,8.4,22.8,8.1,22.8z"></path>
            </svg>
          </button>
        </div>
        <div className="sidebar-root__top-links sidebar-root__section">
          <NavLink
            to="/"
            name="Home"
            exact
            leftIcon={<FontAwesomeIcon icon={faHome} />}
          />
          <NavLink
            to="/overview"
            name="Overview"
            leftIcon={<FontAwesomeIcon icon={faList} />}
          />
          <NavLink
            to="/team"
            name="Organization"
            leftIcon={<FontAwesomeIcon icon={faUserFriends} />}
          />
          <NavLink
            to="/tasks"
            name="My Tasks"
            leftIcon={<FontAwesomeIcon icon={faCheckCircle} />}
          />
          <NavLink
            to="/archive"
            name="Archive"
            leftIcon={<FontAwesomeIcon icon={faArchive} />}
          />
        </div>

        <div className="sidebar-root__projects-container sidebar-root__section">
          <NavLink
            to="/project"
            name="Projects"
            exact
            className="sidebar-root__projects sidebar-root__link-lg"
            icon={<FontAwesomeIcon icon={faPlus} />}
            iconAlwaysVisible
          />
          <NavLink
            to="/project/1"
            name="Project 1"
            className="sidebar-root__navlink-offset"
            icon={<FontAwesomeIcon icon={faEllipsisH} />}
          />
          <NavLink
            to="/project/2"
            name="Project 2"
            className="sidebar-root__navlink-offset"
            icon={<FontAwesomeIcon icon={faEllipsisH} />}
          />
          <NavLink
            to="/project/3"
            name="Project 3"
            className="sidebar-root__navlink-offset"
            icon={<FontAwesomeIcon icon={faEllipsisH} />}
          />
          <NavLink
            to="/project/4"
            name="Project 4"
            className="sidebar-root__navlink-offset"
            icon={<FontAwesomeIcon icon={faEllipsisH} />}
          />
          <NavLink
            to="/project/5"
            name="Project 5"
            className="sidebar-root__navlink-offset"
            icon={<FontAwesomeIcon icon={faEllipsisH} />}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SidebarNav;
