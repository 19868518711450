import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// Components
import { useForm } from "../../shared/hooks/form-hook";
import TextInput from "../../shared/components/FormElements/Input";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: "100%"
  },
  taskInput: {
    marginBottom: theme.spacing(0)
  }
}));

const ProjectBlankEditForm = props => {
  const { handleFormChange } = props;
  const classes = useStyles();
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false
      }
    },
    false
  );

  React.useEffect(() => {
    handleFormChange(formState);
  }, [formState, handleFormChange]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            autoComplete="off"
            autoFocus
            margin="normal"
            id="name"
            label="Project Name"
            type="text"
            fullWidth
            variant="outlined"
            className={classes.taskInput}
            onInput={inputHandler}
            // errorText="is required"
            noMargin
            // helperText="Type in the project name (required)"
            size="medium"
            validators={[VALIDATOR_REQUIRE()]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectBlankEditForm;
