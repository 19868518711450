import React, { useEffect } from "react";
import clsx from "clsx";
import { format } from "date-fns";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import grey from "@material-ui/core/colors/grey";
import purple from "@material-ui/core/colors/purple";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CommentIcon from "@material-ui/icons/Comment";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

import UserWithAvatar from "../../shared/components/UIElements/UserWithAvatar";
import PriorityBadge from "../../shared/components/UIElements/PriorityBadge";
import projectResponsibilities from "../../shared/util/projectResponsibilities";

const useStyles = makeStyles((theme) => ({
  taskColumn: {
    borderColor: grey[200],
    border: "1px solid",
    overflow: "hidden",
    height: "36px",
    marginRight: "-1px",
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    WebkitAlignItems: "center",
    boxSizing: "border-box",
    zIndex: 0,
    "&:hover": {
      borderColor: grey[400],
      zIndex: 1,
    },
    "&:last-child": {
      borderRight: 0,
    },
  },
  emptyTaskColumn: {
    borderTop: 0,
    borderBottom: 0,
    color: grey[700],
    "&:hover": {
      borderColor: grey[200],
      zIndex: 1,
    },
  },
  taskName: {
    borderLeft: 0,
    padding: "0 5px 0 20px",
    minWidth: "1px",
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      cursor: "pointer",
      "& p": {
        visibility: "visible",
      },
    },
  },
  taskNameWithIcon: {
    padding: "0 5px 0 0px",
  },
  taskRow: {
    padding: "0 20px",
    height: "36px",
    display: "inline-flex",
    flexWrap: "nowrap",
    contain: "style",
    marginBottom: "-1px",
    "&:hover": {
      backgroundColor: grey[50],
    },
    "&:last-child": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  taskRowActive: {
    backgroundColor: purple[50],
    "&:hover": {
      backgroundColor: purple[50],
    },
  },
  taskRowCompleted: {
    color: grey[700],
  },
  emptyTaskName: {
    "&:hover": {
      cursor: "auto",
    },
  },
  emptyRow: {
    boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)",
    "&:hover": {
      backgroundColor: "#FFF",
    },
    zIndex: 400,
  },
  taskDetailsText: {
    color: grey[500],
    visibility: "hidden",
  },
  columnAssignee: {
    minWidth: 140, // was 150px
  },
  columnDueDate: {
    // maxWidth: "130px"
    minWidth: "100px",
  },
  columnPriority: {
    maxWidth: "130px",
    minWidth: "100px",
  },
  columnResponsibility: {
    width: 120,
    minWidth: 120,
  },
  withExtraPadding: {
    paddingRight: 37,
  },
}));

const TaskRow = (props) => {
  const classes = useStyles();
  const { onClick, openTask, task } = props;

  const responsibility = projectResponsibilities.find(
    (r) => r.name === task.responsibility
  );

  return (
    <Grid
      container
      className={clsx(
        classes.taskRow,
        props.active === true ? classes.taskRowActive : null,
        task.completeDate > 0 && classes.taskRowCompleted
      )}
      spacing={0}
      direction="row"
      alignItems="center"
      style={{ height: "36px" }}
    >
      <Grid
        item
        xs={openTask ? 8 : 8}
        className={clsx(
          classes.taskColumn,
          classes.taskName,
          task.completeDate > 0 && classes.taskNameWithIcon
        )}
        onClick={() => onClick(true)}
      >
        <div style={{ display: "inline-flex" }}>
          {task.completeDate > 0 && (
            <CheckCircleIcon
              style={{
                fontSize: 14,
                width: 20,
                color: purple[200],
                marginTop: 2,
              }}
            />
          )}
          <Typography variant="body1" noWrap style={{ maxWidth: "35vw" }}>
            {task.name}
          </Typography>
          {/* {responsibility && (
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: 11, marginTop: 3, marginLeft: 12 }}
            >
              {responsibility.display || ""}
            </Typography>
          )} */}
          {task.comments > 0 && (
            <React.Fragment>
              <CommentIcon
                style={{
                  fontSize: 11,
                  color: grey[500],
                  marginTop: 6,
                  marginLeft: 12,
                }}
              />
              <Typography
                variant="body1"
                style={{ fontSize: 11, color: grey[600], marginTop: 3 }}
              >
                {task.comments}
              </Typography>
            </React.Fragment>
          )}
          {task.followDate > 0 && (
            <React.Fragment>
              <NotificationsActiveIcon
                style={{
                  fontSize: 11,
                  color: grey[500],
                  marginTop: 5,
                  marginLeft: 4,
                }}
              />
            </React.Fragment>
          )}
        </div>
        <Typography variant="body2" className={classes.taskDetailsText}>
          Details >
        </Typography>
      </Grid>
      <Grid item xs className={clsx(classes.taskColumn, classes.columnDueDate)}>
        <Typography variant="body1">
          {task.dueDate && format(new Date(task.dueDate * 1000), "MMM do")}
        </Typography>
      </Grid>
      <Grid
        item
        xs
        className={clsx(classes.taskColumn, classes.columnResponsibility)}
      >
        {responsibility ? (
          <Typography variant="body1">{responsibility.display}</Typography>
        ) : (
          ""
        )}
      </Grid>
      {!openTask && (
        <Hidden smDown>
          <Grid
            item
            xs
            className={clsx(classes.taskColumn, classes.columnPriority)}
          >
            {task.priority ? (
              <PriorityBadge priorityIndex={task.priority} small />
            ) : (
              ""
            )}
          </Grid>
          <Grid
            item
            xs
            className={clsx(classes.taskColumn, classes.columnAssignee)}
          >
            {task.assignee && (
              <UserWithAvatar
                color={task.assignee.color}
                name={task.assignee.first_name || ""}
                initials={task.assignee.initials}
              />
            )}
          </Grid>
        </Hidden>
      )}
    </Grid>
  );
};

const EmptyRow = (props) => {
  const classes = useStyles();
  const { open } = props;

  let hasOverflow = false;

  const elem = document.getElementById("taskScrollableContainer");
  if (elem) {
    if (elem.scrollHeight > elem.clientHeight) {
      hasOverflow = true;
    }
  }

  return (
    <Grid
      container
      className={clsx(
        classes.taskRow,
        classes.emptyRow,
        hasOverflow && classes.withExtraPadding
      )}
      spacing={0}
      direction="row"
      alignItems="center"
      style={{ height: "36px" }}
    >
      <Grid
        item
        xs={open ? 8 : 8}
        className={clsx(
          classes.taskColumn,
          classes.taskName,
          classes.emptyTaskName,
          classes.emptyTaskColumn
        )}
      >
        <Typography variant="body1">Task Name</Typography>
      </Grid>
      <Grid
        item
        xs
        className={clsx(
          classes.taskColumn,
          classes.columnDueDate,
          classes.emptyTaskColumn
        )}
      >
        <Typography>Due Date</Typography>
      </Grid>
      <Grid
        item
        xs
        className={clsx(
          classes.taskColumn,
          classes.columnResponsibility,
          classes.emptyTaskColumn
        )}
      >
        <Typography>Responsibility</Typography>
      </Grid>
      {!open && (
        <Hidden smDown>
          <Grid
            item
            xs
            className={clsx(
              classes.taskColumn,
              classes.columnPriority,
              classes.emptyTaskColumn
            )}
          >
            <Typography>Priority</Typography>
          </Grid>
          <Grid
            item
            xs
            className={clsx(
              classes.taskColumn,
              classes.columnAssignee,
              classes.emptyTaskColumn
            )}
          >
            <Typography>User Responsible</Typography>
          </Grid>
        </Hidden>
      )}
    </Grid>
  );
};

export { TaskRow, EmptyRow };
