import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import { Grid, Divider } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
} from "../../shared/util/validators";
import TextInput from "../../shared/components/FormElements/Input";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useForm } from "../../shared/hooks/form-hook";
import { AuthContext } from "../../shared/context/auth-context";
import InnovationPortalLogo from "../../shared/components/UIElements/InnovationPortalLogo";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background:
      "linear-gradient(to top right, rgb(92, 6, 140, 0.9) 10%, rgba(123, 57, 187, 0.9) 65%, rgb(90, 178, 192, 0.9) 125%)",
  },
  logo: {
    color: "#FFF",
  },
  header: {
    marginTop: "-8rem",
    marginBottom: theme.spacing(5),
    textAlign: "center",
  },
  title: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    padding: `0 ${theme.spacing(5)}px`,
  },
  form: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  footerBtn: {
    color: "#FFF",
    "&:hover": {
      color: "#FAFAFA",
    },
    textTransform: "none",
    marginTop: theme.spacing(1),
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    margin: "auto",
    marginBottom: theme.spacing(4),
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor: purple[700],
    },
    width: "90px",
  },
}))(Button);

const LoginPage = (props) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    clearError();
    console.log("handleSubmit", formState);
    let response;
    try {
      response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/auth`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (response.data.token) {
        auth.login(response.data.token);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        {/* <div className={classes.header}>
          <Typography variant="h2" align="center" className={classes.logo}>
            Innovation Portal
          </Typography>
        </div> */}
        <div className={classes.container}>
          <Paper className={classes.paper}>
            <InnovationPortalLogo />
            <Divider />
            <Typography
              variant="h4"
              color="textPrimary"
              align="center"
              className={classes.title}
            >
              Log In
            </Typography>
            {error && (
              <Alert
                severity="error"
                onClose={clearError}
                className={classes.alert}
              >
                <AlertTitle>Oops!</AlertTitle>
                {error}
              </Alert>
            )}

            <form
              className={classes.form}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <TextInput
                id="email"
                type="text"
                label="Email"
                validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email."
                onInput={inputHandler}
              />
              <TextInput
                id="password"
                type="password"
                label="Password"
                validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(5)]}
                errorText="Please enter a valid password (minimum 5 characters)."
                onInput={inputHandler}
              />
              <ColorButton
                variant="contained"
                type="submit"
                disabled={isLoading || !formState.isValid}
              >
                Login
              </ColorButton>
            </form>
          </Paper>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Button
              component={RouterLink}
              to="/password-reset"
              className={classes.footerBtn}
            >
              Forgot your password?
            </Button>
            <Button
              component={RouterLink}
              to="/signup"
              className={classes.footerBtn}
            >
              Don't have an account? Sign up
            </Button>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default LoginPage;
