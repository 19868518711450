import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import ButtonBase from "@material-ui/core/ButtonBase";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles(theme => ({
  button: {
    fontWeight: 600,
    height: "40px",
    padding: "5px 15px 5px 10px",
    borderRadius: "4px",
    border: "1px solid transparent",
    "&:hover,&:focus": {
      outline: 0,
      cursor: "pointer",
      fill: grey[800],
      color: grey[800],
      borderStyle: "solid",
      borderColor: "rgba(0, 0, 0, 0.87) !important",
      backgroundColor: "#f6f8f9"
    },
    "& span": {
      width: "100%"
    },
    "& svg": {
      width: 16,
      height: 16
    }
  },
  textGrey: {
    color: grey[600]
  },
  inlineFlex: {
    display: "inline-flex",
    flex: 1,
    alignItems: "center"
  }
}));

const TransparentButton = props => {
  const classes = useStyles();
  const isEmpty = props.isEmpty;
  const newProps = { ...props };
  delete newProps.isEmpty;
  return (
    <ButtonBase
      disableRipple
      className={clsx(classes.button, isEmpty && classes.textGrey)}
      onClick={props.onClick || null}
      {...newProps}
    >
      <div className={classes.inlineFlex}>{props.children}</div>
    </ButtonBase>
  );
};

export default TransparentButton;
