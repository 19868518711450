import React, { useContext, useEffect } from "react";

// MUI
import { fade, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";

// Components
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      minWidth: 450,
      //   minHeight: 600
    },
  },
  taskInput: {
    marginBottom: theme.spacing(0),
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  paper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 400,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "#f6f8fa",
    margin: 0,
    "&.MuiPaper-root": {
      width: 400,
      minWidth: "300px !important",
      //   minHeight: "auto !important",
      //   height: "auto !important"
    },
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  avatar: {
    color: "#FFF",
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: "12px",
  },
  inputBase: {
    padding: 10,
    width: "100%",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  dropDownContainer: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 400,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "#f6f8fa",
  },
}));

const InviteMemberModal = (props) => {
  const classes = useStyles();
  const { token } = useContext(AuthContext);
  const {
    inviteMemberModalOpen,
    setInviteMemberModalOpen,
    pid,
    onMemberInvite,
    members,
  } = props;
  const { isLoading, sendRequest, clearError } = useHttpClient();
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [autocompleteOpen, setAutocompleteOpen] = React.useState();

  const handleClose = () => {
    setInviteMemberModalOpen(false);
  };

  useEffect(() => {
    let active = true;

    (async () => {
      if (token) {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/users/${pid}?show=all`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );
          if (active) {
            const users = response.data;
            setOptions(users);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [sendRequest, token, pid]);

  const handleUserSelect = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  React.useEffect(() => {
    if (value === null) setAutocompleteOpen(true);
  }, [value]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    clearError();
    if (value === null || !value.id) return;
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/projects/${pid}/members`,
        "POST",
        JSON.stringify({
          userId: value.id,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      if (response.data.id) {
        onMemberInvite({ ...value, role: response.data.role || "Member" });
        handleClose();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Dialog
        open={inviteMemberModalOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">Invite a Member</DialogTitle>
        <Divider />
        <DialogContent>
          <Autocomplete
            loading={isLoading}
            open={autocompleteOpen}
            onOpen={() => setAutocompleteOpen(true)}
            onClose={() => setAutocompleteOpen(false)}
            // disableCloseOnSelect //tmp
            // inputValue={value && value.name}
            getOptionSelected={(option) => {
              if (value) {
                if (option.id === value.id) return true;
              }
              return false;
            }}
            getOptionDisabled={(option) =>
              members.filter((member) => member.id === option.id).length > 0
            }
            classes={{
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            value={value}
            onChange={handleUserSelect}
            disablePortal
            options={options}
            getOptionLabel={(option) => option.name}
            renderTags={() => null}
            noOptionsText="No users found."
            renderOption={(option, { selected }) =>
              !isLoading ? (
                <Grid container item xs={12} alignItems="center">
                  <Avatar
                    className={classes.avatar}
                    style={{ backgroundColor: option.color }}
                  >
                    {option.initials || ""}
                  </Avatar>
                  <div className={classes.text}>
                    <span className={classes.nameText}>{option.name} </span>-
                    <span className={classes.emailText}> {option.email}</span>
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Skeleton
                    variant="circle"
                    width={22}
                    height={22}
                    style={{ paddingLeft: "4px", margin: "0 10px" }}
                  />
                  <Skeleton
                    height={22}
                    width="80%"
                    style={{ marginBottom: 5 }}
                  />
                </React.Fragment>
              )
            }
            renderInput={(params) => (
              <InputBase
                placeholder="Type a name or email"
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                className={classes.inputBase}
                value={value && value.name}
                endAdornment={
                  isLoading && options.length === 0 ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={isLoading === true || value === null}
          >
            Invite
            {isLoading === true && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InviteMemberModal;
