export default [
  {
    name: "",
    display: "",
    value: "Member",
    description: ""
  },
  {
    name: "R",
    display: "Responsible",
    value: "Responsibility",
    description: "who has to initiate a task (DOING)"
  },
  {
    name: "A",
    display: "Accountable",
    value: "Accountability",
    description:
      "who is accountable for the final decision / consequences (there can be one, and only one person accountable and may or may not delegate the Responsibility)"
  },
  {
    name: "C",
    display: "Consultant",
    value: "Consult",
    description:
      "who has to be consulted to make the right decision and, feedback has to be given"
  },
  {
    name: "I",
    display: "Inform",
    value: "Inform",
    description:
      "who should be informed so that other steps/tasks can be prepared/started in time , no feedback is expected"
  }
];
