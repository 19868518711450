import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";

// MUI
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import purple from "@material-ui/core/colors/purple";
import grey from "@material-ui/core/colors/grey";

// Icons
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// Components
import { AuthContext } from "../../context/auth-context";
import { UserProfileContext } from "../../context/userProfile-context";
import UserWithAvatar from "../UIElements/UserWithAvatar";
import UserMenuOptions from "../UIElements/UserMenuOptions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  withShadow: {
    borderBottom: 0,
    boxShadow: "0 1px 3px 0 rgba(21, 27, 38, 0.15)",
  },
  header: {
    alignItems: "center",
    minWidth: "1px",
    flex: "1 1 auto",
    display: "inline-flex",
    height: "100%",
  },
  actions: {
    alignItems: "center",
    display: "flex",
  },
  button: {
    textTransform: "none",
  },
  userMenu: {
    "& .MuiMenu-paper": {
      marginTop: "40px",
      //   marginLeft: "10px"
    },
    "& .MuiMenu-paper li": {
      //   paddingRight: "50px",
      //   paddingLeft: "25px",
      minWidth: "200px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "40px",
    },
  },
  avatar: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
    fontSize: "small",
  },
  smallToolbar: {
    height: "34px",
    zIndex: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(2),
    borderBottom: "1px solid " + grey[300],
  },
}));

const TopHeaderBar = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const auth = useContext(AuthContext);
  const { profile } = useContext(UserProfileContext);
  const { title, history, withShadow } = props;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.toolbar, withShadow && classes.withShadow)}>
        <div className={classes.header}>
          <Typography variant="h5" noWrap>
            {title || ""}
          </Typography>
        </div>
        <div className={classes.actions}>
          <Button
            aria-controls="user-menu"
            aria-haspopup="true"
            classes={{ root: classes.button }}
            disableRipple
            onClick={handleClick}
          >
            <UserWithAvatar
              color={profile.color}
              name={profile.name}
              initials={profile.initials}
              variant="h6"
            />
            {Boolean(anchorEl) ? <ExpandLess /> : <ExpandMore />}
          </Button>
          <UserMenuOptions anchorEl={anchorEl} handleClose={handleClose} />
        </div>
      </div>
      <Divider />
      {props.showSmallToolbar && (
        <Grid container className={classes.smallToolbar} direction="row"></Grid>
      )}
    </div>
  );
};

export default withRouter(TopHeaderBar);
