import React, { useState, useEffect, useContext } from "react";
import { withRouter, useParams, Link as RouterLink } from "react-router-dom";
import { formatDistanceToNow, isPast } from "date-fns";
import clsx from "clsx";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import grey from "@material-ui/core/colors/grey";

// Components
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { UserProfileContext } from "../../shared/context/userProfile-context";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  fullFlex: {
    flex: "1 1 auto",
    display: "flex",
    minWidth: "1px",
    flexDirection: "column"
  },
  table: {
    // minWidth: 650,
    "& .MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#fafafa"
    }
  },
  paper: {
    borderRadius: 4,
    boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)"
  },
  taskNameCol: {
    // minWidth: 400
    width: 500
    // minWidth: 280
  },
  body: {
    backgroundColor: grey[50]
  }
}));

const MyTasksPage = props => {
  const classes = useStyles();
  const { token } = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const { profile } = useContext(UserProfileContext);
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [followingTasks, setFollowingTasks] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (token) {
      const fetchAssignedTasks = async () => {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/users/tasks`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            }
          );
          const tasks = response.data.tasks;
          console.log(tasks);
          if (mounted && tasks) {
            setAssignedTasks(tasks);
          }
        } catch (err) {
          console.log(err);
        }
      };
      const fetchFollowingTasks = async () => {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/users/followingTasks`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            }
          );
          const tasks = response.data.tasks;
          tasks.filter(task => task.completeDate === 0);
          if (mounted && tasks) {
            setFollowingTasks(tasks);
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchAssignedTasks();
      fetchFollowingTasks();
    }
    return () => {
      mounted = false;
    };
  }, [token, sendRequest, profile.id]);

  return (
    <Grid container direction="column" item xs={12} className={classes.body}>
      <Container maxWidth="md">
        <Typography variant="h5" align="center" style={{ marginTop: 12 }}>
          Assigned Tasks
        </Typography>
        <TableContainer
          component={Paper}
          style={{ marginTop: 12, maxHeight: "37vh" }}
          className={classes.paper}
        >
          <Table className={classes.table} aria-label="Tasks">
            <TableHead>
              <TableRow>
                <TableCell className={classes.taskNameCol}>Task</TableCell>
                <TableCell align="left">Due Date</TableCell>
                <TableCell align="left">Project</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && assignedTasks.length === 0 ? (
                [0, 1, 2, 3, 4].map(val => (
                  <TableRow key={val} hover={true}>
                    <TableCell className={classes.taskNameCol}>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                ))
              ) : assignedTasks.length < 1 ? (
                <TableRow>
                  <TableCell align="center" colSpan={3} variant="footer">
                    <Typography variant="body1">
                      You don't have any assigned tasks.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                [...assignedTasks]
                  .sort((a, b) => a.dueDate - b.dueDate)
                  .map((task, index) => {
                    if (task.completeDate > 0) return null;
                    return (
                      <TableRow key={task.id} hover={true}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.taskNameCol}
                        >
                          <Link
                            component={RouterLink}
                            to={`/projects/${task.project_id}/${task.gate}?taskId=${task.id}`}
                            color="textPrimary"
                            underline="none"
                          >
                            <Typography variant="body1" noWrap>
                              {task.name}
                            </Typography>
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="body1"
                            color={
                              isPast(new Date(task.dueDate * 1000))
                                ? "error"
                                : "inherit"
                            }
                          >
                            {task.dueDate &&
                              formatDistanceToNow(
                                new Date(task.dueDate * 1000),
                                {
                                  addSuffix: true
                                }
                              )}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ width: 220 }}>
                          <Link
                            component={RouterLink}
                            to={`/projects/${task.project_id}/${task.gate}`}
                            color="textPrimary"
                            underline="none"
                          >
                            <Typography variant="body1">
                              {task.projectName}
                            </Typography>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h5" align="center" style={{ marginTop: 20 }}>
          Joined Tasks
        </Typography>
        <TableContainer
          component={Paper}
          style={{ marginTop: 12, maxHeight: "37vh" }}
          className={classes.paper}
        >
          <Table className={classes.table} aria-label="Tasks">
            <TableHead>
              <TableRow>
                <TableCell className={classes.taskNameCol}>Task</TableCell>
                <TableCell align="left">Due Date</TableCell>
                <TableCell align="left">Project</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && followingTasks.length === 0 ? (
                [0, 1, 2, 3, 4].map(val => (
                  <TableRow key={val} hover={true}>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                ))
              ) : followingTasks.length < 1 ? (
                <TableRow>
                  <TableCell align="center" colSpan={3} variant="footer">
                    <Typography variant="body1">
                      You don't have any assigned tasks.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                [...followingTasks]
                  .sort((a, b) => a.dueDate - b.dueDate)
                  .map((task, index) => {
                    if (task.completeDate > 0) return null;
                    return (
                      <TableRow key={task.id} hover={true}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.taskNameCol}
                        >
                          <Link
                            component={RouterLink}
                            to={`/projects/${task.project_id}/${task.gate}?taskId=${task.id}`}
                            color="textPrimary"
                            underline="none"
                          >
                            <Typography variant="body1">{task.name}</Typography>
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="body1"
                            color={
                              isPast(new Date(task.dueDate * 1000))
                                ? "error"
                                : "inherit"
                            }
                          >
                            {task.dueDate &&
                              formatDistanceToNow(
                                new Date(task.dueDate * 1000),
                                {
                                  addSuffix: true
                                }
                              )}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ width: 220 }}>
                          <Link
                            component={RouterLink}
                            to={`/projects/${task.project_id}/${task.gate}`}
                            color="textPrimary"
                            underline="none"
                          >
                            <Typography variant="body1">
                              {task.projectName}
                            </Typography>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Grid>
  );
};

export default withRouter(MyTasksPage);
