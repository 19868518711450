import React from "react";
import clsx from "clsx";
import { NavLink, withRouter } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

import purple from "@material-ui/core/colors/purple";
import grey from "@material-ui/core/colors/grey";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  navLink: {
    color: grey[500],
    padding: "4px 5px",
    margin: "0 2px",
    "&.active": {
      color: purple[500]
    },
    "&:hover": {
      color: purple[500]
    },
    "&.active p": {
      fontWeight: "bold"
    }
  },
  listItemSmall: {
    fontSize: "13px"
  },
  disabled: {
    "&:hover": {
      color: grey[500],
      cursor: "not-allowed"
    }
  }
}));

function ButtonNavLink(props) {
  const classes = useStyles();
  const { to, pid, disabled } = props;

  if (disabled) {
    return (
      <Typography
        variant="body1"
        className={clsx(classes.navLink, classes.disabled)}
      >
        {props.children}
      </Typography>
    );
  }

  return (
    <NavLink
      //   className={`MenuAnchor MenuAnchor--default ${props.active && "active"}`}
      className={clsx(classes.navLink, props.active && "active")}
      to={to}
      exact={props.exact || false}
      isActive={(match, location) => {
        if (match) return true;
        if (pid) {
          let parsedId = location.pathname.split("/");
          return parseInt(parsedId[2]) === pid;
        }
      }}
    >
      <Typography variant="body1">{props.children}</Typography>
    </NavLink>
  );
}

export default withRouter(ButtonNavLink);
