import { createContext } from "react";

export const ProjectsContext = createContext({
  projects: [],
  loading: true,
  setProjects: () => {}, // overwrites projects
  addProject: () => {},
  removeProject: () => {},
  updateProject: () => {}
});
