import React, { useCallback, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Radio from "@material-ui/core/Radio";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import ProjectInnovationEditForm from "../../../projects/components/ProjectInnovationEditForm";
import ProjectBlankEditForm from "../../../projects/components/ProjectBlankEditForm";
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import ProjectInnovationFileUpload from "../../../projects/components/ProjectInnovationFileUpload";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SelectTemplate = (props) => {
  const { selectedValue, setSelectedValue } = props;

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <Container maxWidth="sm">
      <Typography gutterBottom variant="subtitle2" align="center">
        Please select one of the templates below to get started.
      </Typography>
      <List dense>
        <ListItem dense button onClick={() => handleChange("blank")}>
          <ListItemIcon>
            <Radio
              color="primary"
              checked={selectedValue === "blank"}
              onChange={() => handleChange("blank")}
              value="b"
              name="select-template"
              inputProps={{ "aria-label": "blank" }}
            />
          </ListItemIcon>
          <ListItemText
            secondaryTypographyProps={{
              style: {
                fontSize: 10,
              },
            }}
            secondary="List"
          >
            <Typography variant="body1">Blank Template</Typography>
          </ListItemText>
        </ListItem>
        <ListItem dense button onClick={() => handleChange("innovation")}>
          <ListItemIcon>
            <Radio
              color="primary"
              checked={selectedValue === "innovation"}
              onChange={() => handleChange("innovation")}
              value="a"
              name="select-template"
              inputProps={{ "aria-label": "innovation" }}
            />
          </ListItemIcon>
          <ListItemText
            secondaryTypographyProps={{
              style: {
                fontSize: 10,
              },
            }}
            secondary="Exploration, Feasibility, Development, Produce, Launch"
          >
            <Typography variant="body1">Innovation Template</Typography>
          </ListItemText>
        </ListItem>
      </List>
    </Container>
  );
};

export default function NewProjectModal(props) {
  const { open, setOpen } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [templateValue, setTemplateValue] = useState("blank");
  const [formState, setFormState] = useState({});
  const [tmpProject, setTmpProject] = useState(null);
  const [fileTemplate, setFileTemplate] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileTemplateId, setFileTemplateId] = useState(null);
  const { token } = React.useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleClose = () => {
    setOpen(false);
    setFormState({});
    setActiveStep(0);
    setTmpProject(null);
  };
  const classes = useStyles();

  const steps = ["Select a Template", "Project Details"];
  const innovationSteps = ["Select a Template", "Project Details", "Responsibilities (optional)"];

  const handleNext = () => {
    if (activeStep === 1 && templateValue === "innovation") {
      setTmpProject({
        name: formState.inputs.name && formState.inputs.name.value,
        category: formState.inputs.category && formState.inputs.category.value,
        variant: formState.inputs.variant && formState.inputs.variant.value,
        startDate: formState.inputs.startDate.value
          ? new Date(formState.inputs.startDate.value).getTime() / 1000
          : null,
        launchDate: formState.inputs.launchDate.value && formState.inputs.launchDate.value,
        brand: formState.inputs.brand && formState.inputs.brand.value,
        geography: formState.inputs.geography && formState.inputs.geography.value,
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFormChange = useCallback((formState) => {
    setFormState(formState);
  }, []);

  const createProjectHandler = async () => {
    console.log("createProjectHandler formState: ", formState);
    if (token) {
      try {
        let response;
        if (templateValue === "innovation") {
          response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/projects/template`,
            "POST",
            JSON.stringify({
              template: templateValue,
              name: formState.inputs.name.value,
              category: formState.inputs.category.value,
              variant: formState.inputs.variant.value,
              startDate:
                formState.inputs.startDate.value &&
                new Date(formState.inputs.startDate.value).getTime() / 1000,
              launchDate:
                formState.inputs.launchDate.value &&
                new Date(formState.inputs.launchDate.value).getTime() / 1000,
              brand: formState.inputs.brand.value,
              geography: formState.inputs.geography.value,
              fileId: fileTemplateId,
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );
        } else {
          response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/projects/blank`,
            "POST",
            JSON.stringify({
              name: formState.inputs.name.value,
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );
        }
        handleClose();
        if (response.data) {
          window.location.href = `/projects/${response.data.pid}/${response.data.gate}`;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <SelectTemplate selectedValue={templateValue} setSelectedValue={setTemplateValue} />;
      case 1:
        if (templateValue === "innovation") {
          return (
            <Container maxWidth="md">
              <ProjectInnovationEditForm project={tmpProject} handleFormChange={handleFormChange} />
            </Container>
          );
        }
        return (
          <Container maxWidth="xs">
            <ProjectBlankEditForm handleFormChange={handleFormChange} />
          </Container>
        );
      default:
        if (templateValue === "innovation") {
          return (
            <Container maxWidth="md">
              <ProjectInnovationFileUpload
                setFileTemplate={setFileTemplate}
                fileTemplate={fileTemplate}
                setUploadingFile={setUploadingFile}
                setFileTemplateId={setFileTemplateId}
              />
            </Container>
          );
        }
        return <Typography className={classes.instructions}>Unknown step index</Typography>;
    }
  };

  return (
    <Dialog
      // onClose={handleClose}
      maxWidth="md"
      //   fullScreen={true}
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Create a Project
      </DialogTitle>
      <DialogContent dividers style={{ minHeight: 320 }}>
        <Stepper activeStep={activeStep} alternativeLabel style={{ paddingTop: 0 }}>
          {templateValue === "innovation"
            ? innovationSteps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))
            : steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
        </Stepper>
        {error && (
          <Collapse in={Boolean(error)}>
            <Alert
              style={{ marginBottom: 16 }}
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    clearError();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          </Collapse>
        )}

        <div>{getStepContent(activeStep)}</div>
      </DialogContent>
      <DialogActions>
        <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
          Back
        </Button>
        {activeStep === (templateValue === "innovation" ? steps.length : steps.length - 1) ? (
          <Button
            variant="contained"
            color="primary"
            disabled={!formState.isValid || isLoading || uploadingFile}
            onClick={createProjectHandler}
          >
            {isLoading ? (
              <React.Fragment>
                Creating
                <CircularProgress size={16} style={{ marginLeft: 8 }} />
              </React.Fragment>
            ) : (
              "Create Project"
            )}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={activeStep === steps.length || (activeStep === 1 && !formState.isValid)}
          >
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
