import React, { useContext, useState } from "react";
import { NavLink, withRouter, useParams } from "react-router-dom";
import clsx from "clsx";

// MUI
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import purple from "@material-ui/core/colors/purple";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EditIcon from "@material-ui/icons/Edit";
import StarIcon from "@material-ui/icons/Star";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

// Components
import { AuthContext } from "../../context/auth-context";
import { UserProfileContext } from "../../context/userProfile-context";
import { ProjectsContext } from "../../context/projects-context";
import { capitalize } from "../../util/helpers";
import UserWithAvatar from "../UIElements/UserWithAvatar";
import EditProjectModal from "../Modals/EditProjectModal";
import TabLink from "../UIElements/TabLink";
import { useHttpClient } from "../../hooks/http-hook";
import UserMenuOptions from "../UIElements/UserMenuOptions";
import EditProjectGateModal from "../Modals/EditProjectGateModal";
import DeleteProjectModal from "../Modals/DeleteProjectModal";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  withShadow: {
    borderBottom: 0,
    boxShadow: "0 1px 3px 0 rgba(21, 27, 38, 0.15)",
  },
  headerTitle: {
    alignItems: "center",
    minWidth: "1px",
    flex: "1 1 auto",
    display: "inline-flex",
    height: "100%",
  },
  actions: {
    alignItems: "center",
    display: "flex",
  },
  button: {
    textTransform: "none",
  },
  userMenu: {
    "& .MuiMenu-paper": {
      marginTop: "40px",
      //   marginLeft: "10px"
    },
    "& .MuiMenu-paper li": {
      //   paddingRight: "50px",
      //   paddingLeft: "25px",
      minWidth: "200px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "40px",
    },
  },
  avatar: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: "11px",
  },
  headerAndNav: {
    display: "flex",
    flex: 1,
  },
  headerGates: {
    marginTop: 5,
    marginBottom: -9,
  },
}));

const TopHeaderBarProject = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { pid } = useParams();
  const auth = useContext(AuthContext);
  const { profile, favorites, setUserProfile } = useContext(UserProfileContext);
  const { updateProject } = React.useContext(ProjectsContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [projectEditModal, setProjectEditModal] = useState(false);
  const [projectGateEditModal, setProjectGateEditModal] = useState(false);
  const [projectDeleteModal, setProjectDeleteModal] = useState(false);
  const { history, withShadow, project } = props;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const favoriteProjectToggle = async (project) => {
    const newFavorites = JSON.parse(JSON.stringify(favorites));
    const favoriteIndex = newFavorites.findIndex(
      (favProject) => favProject.id === project.id
    );
    let favoriteDate = 0;
    if (favoriteIndex >= 0) {
      // unfavorite
      const updatedFavorites = newFavorites.filter((f) => f.id !== project.id);
      setUserProfile({
        favorites: updatedFavorites,
        profile: profile,
      });
    } else {
      // favorite
      favoriteDate = new Date().getTime() / 1000;
      newFavorites.push(project);
      setUserProfile({
        favorites: newFavorites,
        profile: profile,
      });
    }
    const response = await sendRequest(
      `${process.env.REACT_APP_API_URL}/users/favorite`,
      "POST",
      JSON.stringify({
        projectId: project.id,
        favoriteDate: favoriteDate,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );
  };

  const archiveProjectToggle = async (project) => {
    const newProject = { ...project };
    let archiveDate = null;
    console.log(project);

    if (!project.archiveDate) {
      archiveDate = new Date().getTime() / 1000;
    }
    newProject.archiveDate = archiveDate;

    updateProject(project.id, newProject);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/projects/${project.id}/archive`,
        "PATCH",
        JSON.stringify({
          projectId: project.id,
          archiveDate: archiveDate,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div
          className={clsx(classes.toolbar, withShadow && classes.withShadow)}
        >
          <div className={classes.headerAndNav}>
            <Grid
              container
              spacing={0}
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid
                item
                container
                alignItems="center"
                className={classes.headerTitle2}
              >
                <Typography variant="h5" noWrap display="inline">
                  {project.name || ""}
                </Typography>
                {favorites.filter((favProject) => favProject.id === project.id)
                  .length > 0 ? (
                  <Tooltip title="Remove project from favorites">
                    <IconButton
                      aria-label="favorite"
                      onClick={() => favoriteProjectToggle(project)}
                      color="primary"
                      size="small"
                      style={{ marginLeft: 12 }}
                    >
                      <StarIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Favorite this project">
                    <IconButton
                      aria-label="favorite"
                      onClick={() => favoriteProjectToggle(project)}
                      color="primary"
                      size="small"
                      style={{ marginLeft: 12 }}
                    >
                      <StarBorderIcon />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title="Edit project">
                  <IconButton
                    aria-label="Edit project"
                    onClick={() => setProjectEditModal(project)}
                    color="primary"
                    size="small"
                    style={{ marginLeft: 8 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>

                {project && profile && profile.accesslevel >= 200 && (
                  <React.Fragment>
                    <Tooltip title="Edit Active Gate">
                      <span>
                        <IconButton
                          aria-label="Edit Active Gate"
                          onClick={() => setProjectGateEditModal(true)}
                          color="primary"
                          size="small"
                          style={{ marginLeft: 8 }}
                        >
                          <LockOpenIcon />
                        </IconButton>
                      </span>
                    </Tooltip>

                    {project.archiveDate ? (
                      <Tooltip title="Un-archive Project">
                        <span>
                          <IconButton
                            onClick={() => archiveProjectToggle(project)}
                            color="primary"
                            size="small"
                            style={{ marginLeft: 8 }}
                          >
                            <UnarchiveIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Archive Project">
                        <span>
                          <IconButton
                            onClick={() => archiveProjectToggle(project)}
                            color="primary"
                            size="small"
                            style={{ marginLeft: 8 }}
                          >
                            <ArchiveIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}

                    <Tooltip title="Delete Project">
                      <span>
                        <IconButton
                          onClick={() => setProjectDeleteModal(true)}
                          color="primary"
                          size="small"
                          style={{ marginLeft: 8 }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item container className={classes.headerGates}>
                {project.gates
                  ? project.gates.map((gate) => (
                      <TabLink
                        key={gate.name}
                        pid={project.id}
                        to={`/projects/${project.id}/${gate.name}`}
                        isGateActive={gate.active}
                      >
                        {capitalize(gate.name)}
                      </TabLink>
                    ))
                  : ""}
              </Grid>
            </Grid>
          </div>
          <div className={classes.actions}>
            <Button
              aria-controls="user-menu"
              aria-haspopup="true"
              classes={{ root: classes.button }}
              disableRipple
              onClick={handleClick}
            >
              <UserWithAvatar
                color={profile.color}
                name={profile.name}
                initials={profile.initials}
                variant="h6"
              />
              {Boolean(anchorEl) ? <ExpandLess /> : <ExpandMore />}
            </Button>
            <UserMenuOptions anchorEl={anchorEl} handleClose={handleClose} />
          </div>
        </div>
        <Divider />
      </div>

      <EditProjectModal
        open={Boolean(projectEditModal)}
        project={project}
        setOpen={setProjectEditModal}
      />
      <EditProjectGateModal
        open={Boolean(projectGateEditModal)}
        project={project}
        setOpen={setProjectGateEditModal}
      />
      <DeleteProjectModal
        open={Boolean(projectDeleteModal)}
        project={project}
        setOpen={setProjectDeleteModal}
      />
    </React.Fragment>
  );
};

export default withRouter(TopHeaderBarProject);
