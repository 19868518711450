import React from "react";
import { Typography } from "@material-ui/core";

import aorLogo from "../../../assets/images/AOR_Logo_RGB_150dpi.jpg";

const InnovationPortalLogo = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        paddingTop: 24,
        paddingBottom: 24,
      }}
    >
      <img
        src={aorLogo}
        alt="AOR Logo"
        style={{ width: 200, marginBottom: 10 }}
      />

      <Typography variant="h4" align="center" style={{ color: "#55565a" }}>
        Innovation Portal
      </Typography>
    </div>
  );
};

export default InnovationPortalLogo;
