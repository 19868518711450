import React, { useState, useEffect } from "react";
import clsx from "clsx";

// Mui Stuff
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import { Typography, TextField } from "@material-ui/core";
import DatePicker from "./DatePicker";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  formControlWithOther: {
    width: "100%",
    flexDirection: "row",
  },
  selectWithOther: {
    width: 100,
    height: 37,
  },
  textField: {
    minWidth: 300,
  },
}));

const DynamicInput = (props) => {
  const [tmpTextFieldValue, setTmpTextFieldValue] = useState("");

  const {
    id,
    handleTaskElementChange,
    type,
    name,
    options,
    value,
    otherValue,
    placeholder,
  } = props;
  const classes = useStyles();

  let element = null;

  const handleSelectChange = (event) => {
    handleTaskElementChange(id, "select", event.target.value);
  };

  const handleMultiSelectChange = (event) => {
    const { value: selectedValues } = event.target;
    console.log(selectedValues);

    const newOptions = JSON.parse(JSON.stringify(options));

    newOptions.forEach((option) => {
      option.selected = selectedValues.includes(option.value);
    });

    // console.log("newOptions: ", newOptions);

    handleTaskElementChange(id, "multi-select", newOptions);
  };

  const handleDateChange = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    handleTaskElementChange(id, "date", formattedDate);
  };

  const handleTextFieldBlur = (event) => {
    handleTaskElementChange(id, "textfield", event.target.value);
  };

  const handleOtherTextFieldBlur = (event) => {
    handleTaskElementChange(id, "other", tmpTextFieldValue);
  };

  useEffect(() => {
    setTmpTextFieldValue("");
    if (type === "textfield") {
      setTmpTextFieldValue(value);
    }
    if (type === "select" && value === "other") {
      setTmpTextFieldValue(otherValue || "");
    }
  }, [value, type, otherValue]);

  if (type === "select") {
    // const selectedValue = options.find(o => o.selected === true);
    element = (
      <FormControl
        className={clsx(
          classes.formControl,
          value === "other" && classes.formControlWithOther
        )}
        variant="outlined"
        margin="dense"
      >
        <Select
          id={name}
          value={value}
          onChange={(event) => handleSelectChange(event)}
          displayEmpty
          className={clsx(value === "other" && classes.selectWithOther)}
        >
          <MenuItem value="">
            <Typography variant="body1" display="inline">
              None
            </Typography>
          </MenuItem>
          {options.map(({ value: optionValue, caption, selected }, index) => {
            return (
              <MenuItem value={optionValue} key={index}>
                <Typography variant="body1" display="inline">
                  {optionValue}
                </Typography>
                {caption && (
                  <Typography
                    display="inline"
                    variant="caption"
                    color="textSecondary"
                    style={{ marginLeft: 4 }}
                  >
                    {caption}
                  </Typography>
                )}
              </MenuItem>
            );
          })}
        </Select>
        {value && value === "other" && (
          <TextField
            id={`${id}-other`}
            label="Please specify"
            variant="outlined"
            margin="dense"
            value={tmpTextFieldValue}
            onChange={(event) => setTmpTextFieldValue(event.target.value)}
            onBlur={handleOtherTextFieldBlur}
            style={{
              marginLeft: 8,
              width: "100%",
              marginTop: 0,
              marginBottom: 0,
            }}
          />
        )}
      </FormControl>
    );
  } else if (type === "textfield") {
    // let inputMultiline = false;
    // if (options && options.length > 0) {
    //   if (options[0].multiline) inputMultiline = true;
    // }
    element = (
      <TextField
        className={clsx(classes.formControl, classes.textField)}
        id={name}
        label={name}
        placeholder={placeholder}
        value={tmpTextFieldValue}
        onChange={(event) => setTmpTextFieldValue(event.target.value)}
        // helperText="test"
        variant="outlined"
        onBlur={handleTextFieldBlur}
        size="small"
        // multiline={inputMultiline}
        multiline
      />
    );
  } else if (type === "date") {
    element = (
      <DatePicker
        label={name}
        id={name}
        value={value}
        handleDateChange={handleDateChange}
        wide
      />
    );
  } else if (type === "multi-select") {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
      variant: "menu",
      getContentAnchorEl: null,
    };

    const optionValues = [];

    options.forEach((option) => {
      if (option.selected) optionValues.push(option.value);
    });

    element = (
      <FormControl
        className={clsx(classes.formControl)}
        variant="outlined"
        margin="dense"
      >
        <Select
          id={name}
          // value={options.map((option) =>
          //   option.selected ? option.value : null
          // )}
          value={optionValues}
          onChange={handleMultiSelectChange}
          displayEmpty
          multiple
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {options.map(({ value: optionValue, caption, selected }, index) => {
            return (
              <MenuItem value={optionValue} key={index}>
                <Checkbox checked={selected} color="default" />
                <Typography
                  variant="body1"
                  style={{ fontWeight: selected && 600 }}
                  display="inline"
                >
                  {optionValue}
                </Typography>
                {caption && (
                  <Typography
                    display="inline"
                    variant="caption"
                    color="textSecondary"
                    style={{ marginLeft: 4 }}
                  >
                    {caption}
                  </Typography>
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  return element;
};

export default DynamicInput;
