import React, { useState, useContext, useEffect } from "react";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

// Components
import { useForm } from "../../hooks/form-hook";
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import TextInput from "../FormElements/Input";
import { VALIDATOR_REQUIRE } from "../../util/validators";

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiPaper-root": {
      minWidth: 360
    }
  },
  taskInput: {
    marginBottom: theme.spacing(2)
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const NewTaskModal = props => {
  const classes = useStyles();
  const { token } = useContext(AuthContext);
  const { newTaskModal, setNewTaskModal, sections, pid, stage } = props;
  const [sectionValue, setSectionValue] = useState({ title: "" });
  const { isLoading, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false
      }
    },
    false
  );

  const isOpen = newTaskModal ? newTaskModal.open : false;

  useEffect(() => {
    if (Object.keys(newTaskModal.section).length !== 0) {
      setSectionValue(newTaskModal.section);
    } else {
      if (sections) {
        setSectionValue({ ...sections[0] });
      } else {
        setSectionValue({ id: 0, name: "Untitled", expanded: true });
      }
    }
  }, [newTaskModal, sections]);

  const handleClose = () => {
    setNewTaskModal({ open: false, section: {} });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    clearError();
    if (formState.inputs.name.isValid) {
      let response;
      try {
        response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/tasks/${pid}/${stage}`,
          "POST",
          JSON.stringify({
            name: formState.inputs.name.value,
            section: sectionValue.id
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        );
        if (response.data.id) {
          props.onNewTask({
            name: formState.inputs.name.value,
            section: sectionValue.id,
            id: response.data.id
          });
          handleClose();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">Create a Task</DialogTitle>
        <Divider />
        <DialogContent>
          <TextInput
            autoComplete="off"
            autoFocus
            margin="normal"
            id="name"
            label="Task Name"
            type="text"
            fullWidth
            variant="outlined"
            className={classes.taskInput}
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
          />
          <Autocomplete
            id="section"
            options={sections}
            getOptionLabel={option => option.name}
            // freeSolo={true} // testing purposes
            defaultValue={sectionValue}
            onChange={(event, val) => {
              val && setSectionValue(val);
            }}
            // onInput={(event, val) => setSectionValue(event.target.value)}
            renderInput={params => (
              <TextField
                {...params}
                id="sectionName"
                label="Section"
                variant="outlined"
                fullWidth
                onInput={inputHandler}
                size="small"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={isLoading === true}
          >
            Create
            {isLoading === true && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewTaskModal;
