import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";

import TransparentButton from "../FormElements/TransparentButton";

const useStyles = makeStyles(theme => ({
  avatar: {
    color: "#FFF",
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: "12px"
  },
  icon: {
    color: grey[400],
    border: "1px solid " + grey[400]
  },
  inlineFlex: {
    display: "inline-flex",
    flex: 1,
    alignItems: "center"
  }
}));
const UserWithAvatar = props => {
  const classes = useStyles();
  const { onClick, color, icon, initials, name } = props;

  const avatar = (
    <div className={classes.inlineFlex}>
      <Avatar
        className={clsx(classes.avatar, icon && classes.icon)}
        style={{ backgroundColor: color || "transparent" }}
      >
        {initials || icon || ""}
      </Avatar>
      <Typography variant={props.variant || "body1"} noWrap={true}>
        {name}
      </Typography>
    </div>
  );

  if (!onClick) {
    return avatar;
  }

  return (
    <TransparentButton
      aria-describedby={`${name} avatar`}
      onClick={props.handleClick}
    >
      {avatar}
    </TransparentButton>
  );
};

export default UserWithAvatar;
