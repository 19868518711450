import React, { useContext } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextInput from "../../shared/components/FormElements/Input";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";

import CloseIcon from "@material-ui/icons/Close";

import { VALIDATOR_REQUIRE } from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";

import AttachFileIcon from "@material-ui/icons/AttachFile";

const TaskAddAttachmentButton = (props) => {
  const [open, setOpen] = React.useState(false);
  const { task, onNewAttachment } = props;
  const { token } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    clearError();
    setOpen(false);
  };

  const handleCreateAttachment = async (event) => {
    event.preventDefault();
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/tasks/attachment`,
        "PUT",
        JSON.stringify({
          taskId: task.id,
          name: formState.inputs.name.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      console.log(response);
      if (response.status === 201) {
        onNewAttachment();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <IconButton
        color="default"
        aria-label="delete task"
        size="small"
        onClick={handleClickOpen}
      >
        <AttachFileIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add an Attachment to "{task.name}"?
        </DialogTitle>
        <DialogContent>
          {error && (
            <Collapse in={Boolean(error)}>
              <Alert
                style={{ marginBottom: 16 }}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={clearError}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {error}
              </Alert>
            </Collapse>
          )}
          <Typography variant="body2" paragraph>
            Specify the title/description for the new attachment field.
            <br />
            Once created you will be able to upload the file.
          </Typography>
          <TextInput
            autoComplete="off"
            margin="normal"
            id="name"
            label="Attachment Description or Title"
            type="text"
            fullWidth
            variant="outlined"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary" variant="text">
            Cancel
          </Button>
          <Button
            onClick={handleCreateAttachment}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={isLoading && true}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default TaskAddAttachmentButton;
