import React, { useEffect, useState, useContext } from "react";

// MUI Stuff
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import Alert from "@material-ui/lab/Alert";

// Icons \\
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CloseIcon from "@material-ui/icons/Close";

// Components \\
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import purple from "@material-ui/core/colors/purple";
import { useForm } from "../../hooks/form-hook";
import { UserProfileContext } from "../../context/userProfile-context";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_MINLENGTH
} from "../../util/validators";
import TextInput from "../../components/FormElements/Input";

const dialogTitleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const StyledTabs = withStyles({
  root: {
    minHeight: 15
  },
  indicator: {
    backgroundColor: purple[500]
  }
})(Tabs);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    minHeight: 15,
    fontSize: "0.8rem",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: purple[400],
      opacity: 1
    },
    "&$selected": {
      color: purple[500],
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: purple[400]
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

const DialogTitle = withStyles(dialogTitleStyles)(props => {
  const { children, classes, onClose, value, handleChange, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" gutterBottom>
        {children}
      </Typography>
      <div style={{ display: "flex", flex: 1 }}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label="Personal Details" />
          <StyledTab label="Password" />
          <StyledTab label="Notifications" />
        </StyledTabs>
      </div>
      <Divider />
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

// Components
const PersonalDetailsTab = props => {
  const { token } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { profile, favorites, setUserProfile } = useContext(UserProfileContext);
  const [formState, inputHandler, setFormData] = useForm(
    {
      first_name: {
        value: "",
        isValid: false
      },
      last_name: {
        value: "",
        isValid: false
      }
    },
    true
  );

  useEffect(() => {
    setFormData(
      {
        first_name: {
          value: profile.first_name,
          isValid: true
        },
        last_name: {
          value: profile.last_name,
          isValid: true
        }
      },
      true
    );
    return () => {
      setFormData({});
    };
  }, [profile, setFormData]);

  const handleSubmit = async event => {
    event.preventDefault();
    clearError();
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/details`,
        "PUT",
        JSON.stringify({
          first_name: formState.inputs.first_name.value,
          last_name: formState.inputs.last_name.value
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      );
      if (response.data.ok) {
        setUserProfile({
          favorites: favorites,
          profile: {
            ...profile,
            first_name: formState.inputs.first_name.value,
            last_name: formState.inputs.last_name.value,
            name:
              formState.inputs.first_name.value +
              " " +
              formState.inputs.last_name.value,
            initials:
              formState.inputs.first_name.value[0].toUpperCase() +
              formState.inputs.last_name.value[0].toUpperCase()
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      {error && (
        <Collapse in={Boolean(error)}>
          <Alert
            style={{ marginBottom: 16 }}
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  clearError();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {error}
          </Alert>
        </Collapse>
      )}
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ marginBottom: 28 }}
      >
        You're logged in as {profile.email}
      </Typography>

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        id="userPersonalSaveForm"
      >
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={12} sm={6}>
            <TextInput
              id="first_name"
              type="text"
              label="First Name"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(50)]}
              errorText="Please enter your first name."
              onInput={inputHandler}
              fullWidth
              initialValue={profile.first_name}
              initialIsValid={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="last_name"
              type="text"
              label="Last Name"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(75)]}
              errorText="Please enter your last name."
              onInput={inputHandler}
              fullWidth
              initialValue={profile.last_name}
              initialIsValid={true}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            id="saveProfile"
            disabled={isLoading}
          >
            {!isLoading ? "Save" : "Saving"}
          </Button>
        </Grid>
      </form>
    </React.Fragment>
  );
};

const PasswordChangeTab = props => {
  const { token } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { profile, favorites, setUserProfile } = useContext(UserProfileContext);
  const initialFormState = {
    existing_password: {
      value: "",
      isValid: false
    },
    new_password: {
      value: "",
      isValid: false
    },
    confirm_new_password: {
      value: "",
      isValid: false
    }
  };
  const [formState, inputHandler, setFormData] = useForm(
    initialFormState,
    false
  );

  const handleSubmit = async event => {
    event.preventDefault();
    clearError();
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/password`,
        "PUT",
        JSON.stringify({
          existing_password: formState.inputs.existing_password.value,
          new_password: formState.inputs.new_password.value,
          confirm_new_password: formState.inputs.confirm_new_password.value
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      );
      if (response.data.ok) {
        setFormData(initialFormState, false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      {error && (
        <Collapse in={Boolean(error)}>
          <Alert
            style={{ marginBottom: 16 }}
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  clearError();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {error}
          </Alert>
        </Collapse>
      )}

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        id="userPasswordUpdate"
      >
        <Grid container spacing={2} justify="center" style={{ marginTop: 12 }}>
          <Grid item xs={10}>
            <TextInput
              id="existing_password"
              type="password"
              label="Current Password"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(5)]}
              errorText="Please enter your existing password (minimum 5 characters)"
              onInput={inputHandler}
              value={formState.inputs.existing_password.value}
              fullWidth
            />
          </Grid>
          <Grid item xs={10}>
            <TextInput
              id="new_password"
              type="password"
              label="New Password"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(5)]}
              errorText="Please enter a new password (minimum 5 characters)"
              onInput={inputHandler}
              value={formState.inputs.new_password.value}
              fullWidth
            />
          </Grid>
          <Grid item xs={10}>
            <TextInput
              id="confirm_new_password"
              type="password"
              label="Confirm Password"
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(5)]}
              errorText="Please enter confirm your password (minimum 5 characters)"
              onInput={inputHandler}
              value={formState.inputs.confirm_new_password.value}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            id="updatePasswordButton"
            disabled={isLoading}
          >
            {!isLoading ? "Update Password" : "Updating..."}
          </Button>
        </Grid>
      </form>
    </React.Fragment>
  );
};

const NotificationsTab = () => {
  const [checked, setChecked] = React.useState([]);
  const { token } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { profile, favorites, setUserProfile } = useContext(UserProfileContext);

  const handleToggle = value => async () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    clearError();

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/notifications`,
        "PUT",
        JSON.stringify({
          notification: value,
          checked: currentIndex === -1 ? false : true
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      );
      if (response.data.ok) {
        setUserProfile({
          favorites: favorites,
          profile: {
            ...profile,
            [value]: currentIndex === -1 ? 1 : 0
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (profile) {
      const newChecked = [];
      if (profile.notifications_daily === 1)
        newChecked.push("notifications_daily");
      if (profile.notifications_weekly === 1)
        newChecked.push("notifications_weekly");
      setChecked(newChecked);
    }
  }, [profile]);

  return (
    <Grid container spacing={2} justify="center" item xs={12}>
      <List style={{ width: 300 }}>
        <ListItem>
          <ListItemIcon>
            <MailOutlineIcon />
          </ListItemIcon>
          <ListItemText id="daily-summary" primary="Daily Summaries" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={handleToggle("notifications_daily")}
              checked={checked.indexOf("notifications_daily") !== -1}
              color="primary"
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MailOutlineIcon />
          </ListItemIcon>
          <ListItemText id="weekly-summary" primary="Weekly Summaries" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={handleToggle("notifications_weekly")}
              checked={checked.indexOf("notifications_weekly") !== -1}
              color="primary"
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Grid>
  );
};

export default function UserSettingsModal(props) {
  const { open, openIndex, setOpen } = props;
  const classes = useStyles();

  const [tabIndex, setTabIndex] = React.useState(0);

  useEffect(() => {
    setTabIndex(openIndex || 0);
  }, [openIndex, open]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        handleChange={handleChange}
        value={tabIndex}
      >
        My Profile Settings
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        {tabIndex === 0 && <PersonalDetailsTab />}
        {tabIndex === 1 && <PasswordChangeTab />}
        {tabIndex === 2 && <NotificationsTab />}
      </DialogContent>
    </Dialog>
  );
}
