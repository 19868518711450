import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import ProjectInnovationEditForm from "../../../projects/components/ProjectInnovationEditForm";
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import { ProjectsContext } from "../../context/projects-context";
import { capitalize, getActiveGate } from "../../util/helpers";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  activeOptionText: {
    fontWeight: 700,
  },
}));

export default function EditProjectGateModal(props) {
  const { open, setOpen, project } = props;
  const { token } = React.useContext(AuthContext);
  const { updateProject } = React.useContext(ProjectsContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const [formState, setFormState] = React.useState({});
  const [activeGate, setActiveGate] = React.useState("");

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setFormState({});
  };

  useEffect(() => {
    if (project.gates) {
      setActiveGate(getActiveGate(project));
    }
  }, [project, open, token]);

  const handleGateChange = (event) => {
    setActiveGate(event.target.value);
  };

  const editProjectHandler = async () => {
    console.log("editProjectHandler formState: ", activeGate);
    if (token && project) {
      try {
        let response;
        response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/projects/${project.id}/gate`,
          "PUT",
          JSON.stringify({
            gate: activeGate,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        if (response.data) {
          const newProject = { ...project };
          newProject.gates = response.data.gates;
          updateProject(project.id, newProject);
          history.push(`/projects/${project.id}/${response.data.activeGate}`);
          handleClose();

          // window.location.href = `/projects/${project.id}/${response.data.activeGate}`;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth="sm"
      //   fullScreen={true}
      fullWidth={true}
      open={open}
    >
      <DialogTitle onClose={handleClose}>
        Editing Approved Gate for {project.name}
      </DialogTitle>
      <DialogContent dividers style={{}}>
        {error && (
          <Collapse in={Boolean(error)}>
            <Alert
              style={{ marginBottom: 16 }}
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    clearError();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          </Collapse>
        )}
        <Container maxWidth="sm">
          {project.gates ? (
            <>
              <Typography variant="body2" gutterBottom>
                Current unapproved active gate is{" "}
                <strong>{capitalize(getActiveGate(project))}</strong>. Select
                the gate from the list to approve. You may also jump to a
                previous gate.{" "}
              </Typography>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                margin="dense"
              >
                <Select
                  id="gate"
                  value={activeGate}
                  onChange={handleGateChange}
                >
                  <MenuItem value="none">
                    <Typography variant="body1" display="inline">
                      None
                    </Typography>
                  </MenuItem>
                  {project.gates.map(({ name, selected }, index) => {
                    return (
                      <MenuItem value={name} key={index}>
                        <Typography
                          variant="body1"
                          display="inline"
                          className={selected && classes.activeOptionText}
                        >
                          {capitalize(name)}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Typography variant="caption" display="block">
                Note: this will notify members and enable all gates up to
                approved gate.
              </Typography>
            </>
          ) : (
            "Loading"
          )}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.backButton}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          // disabled={isLoading || activeGate === getActiveGate(project)}
          onClick={editProjectHandler}
        >
          {isLoading ? (
            <React.Fragment>
              Saving
              <CircularProgress size={16} style={{ marginLeft: 8 }} />
            </React.Fragment>
          ) : (
            "Approve Gate"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
