import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";

import "./NavLink.css";

const NavLink = props => {
  let iconClass = "";
  let icon, leftIcon;

  if (props.icon) {
    iconClass = "sidebar-root__link-sm sidebar-root__project";
    icon = (
      <div
        className={`sidebar-root__menu-icon ${props.iconAlwaysVisible &&
          "sidebar-root_menu-icon-visible"}`}
      >
        {props.icon}
      </div>
    );
  }

  if (props.leftIcon) {
    leftIcon = <div style={{ marginRight: "10px" }}>{props.leftIcon}</div>;
  }

  return (
    <RouterNavLink
      to={props.to}
      exact={props.exact || false}
      className={`sidebar-root__link ${props.className || ""} ${iconClass}`}
    >
      {leftIcon}
      {props.name}
      {icon || null}
    </RouterNavLink>
  );
};

export default NavLink;
