import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

// MUI
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";

// Icons \\
import CloseIcon from "@material-ui/icons/Close";

// Components
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import UserWithAvatar from "../UIElements/UserWithAvatar";

const useStyles = makeStyles(theme => ({
  dialog: {
    // "& .MuiPaper-root": {
    //   minWidth: 660
    // }
  }
}));

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "space-between"
  }
}))(MuiDialogActions);

const FileHistoryModal = props => {
  const classes = useStyles();
  const history = useHistory();
  const { pid, stage } = useParams();
  const { token } = useContext(AuthContext);
  const { fileHistory, setFileHistory } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [files, setFiles] = useState([]);
  const [checked, setChecked] = React.useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = value => event => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (checked.length >= 2 && event.target.checked) return;

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    let active = true;
    if (active) {
      setFiles([]);
    }
    (async () => {
      if (fileHistory && token) {
        try {
          console.log(fileHistory);
          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/files/history/${fileHistory.masterFile}`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            }
          );
          if (active) {
            const files = response.data.files;
            setFiles(files);
            setChecked([files[0].fileId]);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [fileHistory, token, sendRequest]);

  const handleClose = () => {
    setFileHistory(null);
  };

  const beginComparison = async () => {
    if (checked.length < 2) return;
    console.log(checked);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/comparisons/${checked[0]}/${checked[1]}`,
        "POST",
        null,
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      );
      if (response.data) {
        console.log(response);
        history.push(
          `/projects/${pid}/${stage}/comparisons/${response.data.comparison.identifier}`
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog
      // open={true}
      open={Boolean(fileHistory)}
      onClose={handleClose}
      aria-labelledby="filehistory-dialog-title"
      //   className={classes.dialog}
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle id="filehistory-dialog-title">
        File History {files.length > 0 && files[0].category}
      </DialogTitle>
      <DialogContent dividers>
        {error && (
          <Collapse in={Boolean(error)}>
            <Alert
              style={{ marginBottom: 16 }}
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    clearError();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          </Collapse>
        )}
        <TableContainer>
          <Table className={classes.table} aria-label="files">
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell align="left">File Name</TableCell>
                <TableCell align="left">Revision</TableCell>
                <TableCell align="left" style={{ minWidth: 150 }}>
                  Upload Date
                </TableCell>
                <TableCell align="left" padding="checkbox">
                  Compare
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && files.length === 0 ? (
                [0, 1, 2, 3, 4].map(val => (
                  <TableRow key={val}>
                    <TableCell>
                      <Skeleton variant="text" width={100} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={120} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={60} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={100} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={20} />
                    </TableCell>
                  </TableRow>
                ))
              ) : files.length < 1 ? (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <Typography variant="body1">No files found.</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                files.map((file, index) => (
                  <TableRow key={file.id} hover={true} selected={index === 0}>
                    <TableCell component="th" scope="row">
                      <UserWithAvatar
                        color={file.color}
                        initials={file.initials}
                        name={file.first_name + " " + file.last_name}
                        variant="body2"
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Link
                        color="primary"
                        target="_blank"
                        href={`${process.env.REACT_APP_API_URL}/download/${file.fileId}`}
                        download={file.name}
                      >
                        {file.name}
                      </Link>
                    </TableCell>
                    <TableCell align="left">{file.revision}</TableCell>
                    <TableCell align="left">{file.lastUpdate}</TableCell>
                    <TableCell align="left" padding="checkbox">
                      <Checkbox
                        checked={checked.indexOf(file.fileId) !== -1}
                        onChange={handleChange(file.fileId)}
                        value={file.fileId}
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={checked.length < 2 || isLoading}
          onClick={beginComparison}
        >
          Compare
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileHistoryModal;
