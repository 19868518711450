import React, { useContext } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const TaskDeleteButton = props => {
  const [open, setOpen] = React.useState(false);
  const { task, onTaskDelete } = props;
  const { token } = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const handleDelete = async event => {
    event.preventDefault();
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/tasks/${task.id}`,
        "DELETE",
        null,
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      );
      console.log(response);
      if (response.status === 201) {
        onTaskDelete(task);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <IconButton
        color="default"
        aria-label="delete task"
        size="small"
        onClick={handleClickOpen}
      >
        <DeleteOutlineIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete "{task.name}"?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will permanently delete the task and associated comments. These
            items will no longer be accessible to you or anyone else. This
            action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary" variant="text">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={isLoading && true}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default TaskDeleteButton;
