import React, { useState, useEffect, useContext } from "react";

// MUI Stuff
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// Icons
// import CloseIcon from "@material-ui/icons/Close";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

// Components
import TransparentButton from "../../shared/components/FormElements/TransparentButton";
import PriorityBadge from "../../shared/components/UIElements/PriorityBadge";
import { capitalize, priorityOptions } from "../../shared/util/helpers";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: "13px",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontWeight: "bold"
  },
  menuContainer: {
    "& .MuiPaper-root": {
      width: "200px"
    }
  },
  noPriority: {
    backgroundColor: "transparent",
    color: grey[800],
    borderColor: grey[500],
    "&:hover,&:active,&:focus": {
      backgroundColor: grey[100] + "!important"
    }
  },
  low: {
    backgroundColor: grey[400],
    "&:hover,&:active,&:focus": {
      backgroundColor: grey[500]
    }
  },
  normal: {
    backgroundColor: grey[600],
    "&:hover,&:active,&:focus": {
      backgroundColor: grey[700]
    }
  },
  high: {
    backgroundColor: orange[400],
    "&:hover,&:active,&:focus": {
      backgroundColor: orange[600]
    }
  },
  urgent: {
    backgroundColor: red[400],
    "&:hover,&:active,&:focus": {
      backgroundColor: red[600]
    }
  },
  avatar: {
    color: "#FFF",
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: "12px"
  }
}));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      //   backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black
      }
    }
  }
}))(MenuItem);

const TaskPriorityButton = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { priority, onChange, task } = props;
  const { token } = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [selectedIndex, setSelectedIndex] = useState();
  const open = Boolean(anchorEl);

  useEffect(() => {
    setSelectedIndex(priority);
    setAnchorEl(null);
  }, [priority, task]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    updateServerPriority(index);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setSelectedIndex(0);
    updateServerPriority(0);
  };

  const updateServerPriority = async priorityIndex => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/tasks/priority`,
        "PUT",
        JSON.stringify({
          taskId: task.id,
          priorityIndex: priorityIndex
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      );
      console.log(response);
      if (response.status === 201) {
        onChange(task, "priority", priorityIndex);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      {selectedIndex ? (
        <PriorityBadge
          priorityIndex={selectedIndex}
          onDelete={selectedIndex ? handleDelete : null}
          onClick={handleClick}
        />
      ) : (
        <TransparentButton onClick={handleClick} isEmpty>
          <React.Fragment>
            <Avatar
              className={classes.avatar}
              style={{
                backgroundColor: "transparent",
                border: "1px solid " + grey[400],
                color: grey[400]
              }}
            >
              <PriorityHighIcon />
            </Avatar>
            <Typography variant="body1">Add Priority</Typography>
          </React.Fragment>
        </TransparentButton>
      )}
      <Menu
        className={classes.menuContainer}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {priorityOptions.map(priorityOption => (
          <StyledMenuItem
            key={priorityOption.id}
            disabled={priorityOption.id === 0}
            selected={priorityOption.id === selectedIndex}
            onClick={event => handleMenuItemClick(event, priorityOption.id)}
          >
            <ListItemText
              primary={capitalize(priorityOption.name)}
              style={{ color: priorityOption.color }}
            />
          </StyledMenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default TaskPriorityButton;
