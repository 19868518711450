import React, { useCallback } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import ProjectInnovationEditForm from "../../../projects/components/ProjectInnovationEditForm";
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import { UserProfileContext } from "../../context/userProfile-context";
import { ProjectsContext } from "../../context/projects-context";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function EditProjectModal(props) {
  const { open, setOpen, project } = props;
  const [formState, setFormState] = React.useState({});
  const { token } = React.useContext(AuthContext);
  const { updateProject } = React.useContext(ProjectsContext);
  const { profile } = React.useContext(UserProfileContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleClose = () => {
    setOpen(false);
    setFormState({});
  };

  const classes = useStyles();

  const handleFormChange = useCallback((formState) => {
    setFormState(formState);
  }, []);

  const editProjectHandler = async () => {
    console.log("editProjectHandler formState: ", formState.inputs);
    if (token) {
      try {
        let response;
        const updatedProject = {
          name: formState.inputs.name
            ? formState.inputs.name.value
            : project.name,
          category: formState.inputs.category
            ? formState.inputs.category.value
            : project.category,
          variant: formState.inputs.variant.value,
          startDate:
            formState.inputs.startDate.value &&
            new Date(formState.inputs.startDate.value).getTime() / 1000,
          launchDate:
            formState.inputs.launchDate.value &&
            new Date(formState.inputs.launchDate.value).getTime() / 1000,
          brand: formState.inputs.brand
            ? formState.inputs.brand.value
            : project.brand,
          geography: formState.inputs.geography
            ? formState.inputs.geography.value
            : project.geography,
        };
        response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/projects/${project.id}`,
          "PATCH",
          JSON.stringify(updatedProject),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        if (response.data) {
          handleClose();
          updateProject(project.id, updatedProject);
          //   window.location.href = `/projects/${response.data.pid}/${response.data.gate}`;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth="md"
      //   fullScreen={true}
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Editing {project.name}
      </DialogTitle>
      <DialogContent dividers style={{}}>
        {error && (
          <Collapse in={Boolean(error)}>
            <Alert
              style={{ marginBottom: 16 }}
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    clearError();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {error}
            </Alert>
          </Collapse>
        )}
        <Container maxWidth="lg">
          <ProjectInnovationEditForm
            handleFormChange={handleFormChange}
            project={project}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.backButton}>
          Close
        </Button>
        {profile && profile.accesslevel >= 200 && (
          <Button
            variant="contained"
            color="primary"
            disabled={!formState.isValid || isLoading}
            onClick={editProjectHandler}
          >
            {isLoading ? (
              <React.Fragment>
                Editing
                <CircularProgress size={16} style={{ marginLeft: 8 }} />
              </React.Fragment>
            ) : (
              "Edit Project"
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
