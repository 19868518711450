import { useState, useCallback, useEffect, useContext } from "react";
import { ProjectsContext } from "../context/projects-context";

export const useProjects = (token, projects, setProjects) => {
  const [loading, setLoading] = useState(true);

  const addProject = useCallback(
    project => {
      if (project) {
        setProjects(...projects, project);
      }
    },
    [projects, setProjects]
  );
  const removeProject = useCallback(() => {}, []);
  const updateProject = useCallback(
    (pid, data) => {
      if (projects && projects.length > 0) {
        const newProjects = JSON.parse(JSON.stringify(projects));
        const project = { ...newProjects.find(p => p.id === pid) };
        const projectIndex = newProjects.findIndex(p => p.id === pid);
        if (project && projectIndex >= 0) {
          const updatedProject = { ...project, ...data };

          newProjects[projectIndex] = updatedProject;
          setProjects(newProjects);
        } else {
          console.log("unable to find project to update.", pid);
        }
      } else {
        console.log("projects-hook: no projects?");
      }
    },
    [projects, setProjects]
  );

  useEffect(() => {}, []);

  return {
    loading,
    addProject,
    removeProject,
    updateProject
  };
};
