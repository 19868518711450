import React, { useState, useRef, useContext, useEffect } from "react";
import FileIcon, { defaultStyles } from "react-file-icon";
import clsx from "clsx";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// Components
import TransparentButton from "./TransparentButton";
import { Typography, Avatar, CircularProgress, Link } from "@material-ui/core";
import { fileExtensions, rejectedExtensions } from "../../util/fileExtensions";
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";

const useStyles = makeStyles(theme => ({
  avatar: {
    color: grey[400],
    border: "1px solid " + grey[400],
    backgroundColor: "transparent",
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: "12px"
  },
  inlineFlex: {
    display: "inline-flex",
    alignItems: "center"
  },
  transparentButton: {
    border: "1px solid " + grey[400]
  }
}));

const AttachFileButton = props => {
  const classes = useStyles();
  const [file, setFile] = useState();
  const [isValid, setIsValid] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const filePickerRef = useRef();
  const { token } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { id, options, value, handleTaskElementChange, setFileHistory } = props;

  useEffect(() => {
    if (value && options) {
      if (options.name) {
        const file = {
          name: options.nameWithExtension,
          extension:
            fileExtensions.find(ext => ext.extension === options.extension) ||
            fileExtensions[0],
          fileId: options.fileId,
          revision: options.revision || 1,
          masterFile: options.masterFile || ""
        };

        setFile(file);
      }
    }
  }, [value, options]);

  const handleButtonClick = event => {
    event.preventDefault();
    filePickerRef.current.click();
  };

  const handleFileChange = event => {
    let pickedFile;
    clearError();
    handleClose();
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      console.log(pickedFile);
      const fileSize = (pickedFile.size / 1024 / 1024).toFixed(4); // MB
      if (
        rejectedExtensions.includes(pickedFile.name.split(".").pop()) ===
          false &&
        fileSize < 200
      ) {
        pickedFile.extension =
          fileExtensions.find(ext => ext.mime === pickedFile.type) ||
          fileExtensions[0];
        setFile(pickedFile);
        setIsValid(true);
        uploadFile(pickedFile, event.target.id);
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  };

  const uploadFile = async (file, action) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("task", true);
      formData.append("action", action);
      formData.append("mime", file.type);
      formData.append("elementId", id);
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/files/${id}`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + token
        }
      );
      console.log(response);
      handleTaskElementChange(id, "attachment", response.data.file);
    } catch (err) {
      setIsValid(false);
      console.error(err);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (file && isValid) {
    return (
      <React.Fragment>
        <div
          className={clsx(classes.inlineFlex, classes.transparentButton)}
          style={{ padding: 4, borderRadius: 4 }}
        >
          <FileIcon
            extension={file.extension.extension}
            {...defaultStyles[file.extension.extension]}
            size={30}
          />
          <div
            style={{ marginLeft: 8, display: "flex", flexDirection: "column" }}
          >
            <Link
              color="textPrimary"
              // target="_blank"
              href={`${process.env.REACT_APP_API_URL}/download/${file.fileId}`}
              download={file.name}
            >
              <Typography
                variant="subtitle2"
                color="textPrimary"
                display="inline"
              >
                {file.name}
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                display="inline"
                style={{ marginLeft: 4 }}
              >
                {file.revision && `(${file.revision})`}
              </Typography>
            </Link>
            <Typography variant="body2" color="textSecondary">
              {file.extension ? file.extension.name : ""}
            </Typography>
          </div>
          <div style={{ marginLeft: 25, display: "flex" }}>
            <IconButton
              color="default"
              size="small"
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            {isLoading && <CircularProgress color="primary" size={26} />}
          </div>
        </div>
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        <Menu
          id="attachment-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleButtonClick}>Upload Revision</MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setFileHistory(file);
            }}
          >
            View File History
          </MenuItem>
          {/* <MenuItem disabled={file.revision > 1 ? false : true}>
            Compare Revisions
          </MenuItem> */}
        </Menu>
        <input
          id="revision-file"
          ref={filePickerRef}
          type="file"
          style={{ display: "none" }}
          accept="*"
          onChange={handleFileChange}
          value={(file && file.filename) || ""}
        />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <input
        id="new-file"
        ref={filePickerRef}
        type="file"
        style={{ display: "none" }}
        accept="*"
        // accept=".jpg,.png,.jpeg,.xls,.xlsx,.docx,.doc,.csv,.ppt"
        onChange={handleFileChange}
      />
      <TransparentButton
        aria-describedby="users-menu"
        onClick={handleButtonClick}
        isEmpty={true}
        style={{ border: "1px solid " + grey[400] }}
      >
        <div className={classes.inlineFlex}>
          <Avatar className={classes.avatar}>
            <AttachFileIcon fontSize="small" className={classes.icon} />
          </Avatar>
          <Typography variant="body1" noWrap>
            Attach a File
          </Typography>
        </div>
      </TransparentButton>
      {!isValid && (
        <Typography
          variant="body2"
          color="error"
          style={{ alignSelf: "center", marginLeft: 4 }}
        >
          {error || "Invalid file selected."}
        </Typography>
      )}
    </React.Fragment>
  );
};

export default AttachFileButton;
