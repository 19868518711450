import React from "react";
import { withRouter, useParams } from "react-router-dom";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import grey from "@material-ui/core/colors/grey";

import ButtonNavLink from "../../shared/components/Navigation/ButtonNavLink";

const useStyles = makeStyles(theme => ({
  toolbar: {
    height: "55px",
    zIndex: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(2),
    borderBottom: "1px solid " + grey[300]
  }
}));

const ProjectActions = props => {
  const classes = useStyles();
  const { pid, stage } = useParams();
  const { openTask, leftActionsComponent, onClick } = props;

  let rightSection = (
    <Grid container item xs={6} justify="flex-end" style={{ paddingRight: 20 }}>
      <ButtonNavLink to={`/projects/${pid}/${stage}`} exact>
        Tasks
      </ButtonNavLink>
      <ButtonNavLink to={`/projects/${pid}/${stage}/calendar`}>
        Calendar
      </ButtonNavLink>
      <ButtonNavLink to={`/projects/${pid}/${stage}/gantt`}>
        Gantt Chart
      </ButtonNavLink>
      <ButtonNavLink to={`/projects/${pid}/${stage}/files`}>
        Files
      </ButtonNavLink>
      {/* <ButtonNavLink to={`/projects/${pid}/${stage}/comparisons`}>
        Comparisons
      </ButtonNavLink> */}
      <ButtonNavLink to={`/projects/${pid}/${stage}/members`}>
        Members
      </ButtonNavLink>
    </Grid>
  );

  return (
    <Grid
      container
      className={classes.toolbar}
      direction="row"
      onClick={onClick ? onClick : () => {}}
    >
      {leftActionsComponent ? (
        leftActionsComponent
      ) : (
        <Grid container item xs={6}></Grid>
      )}

      {openTask ? (
        <React.Fragment />
      ) : (
        <Fade
          in={openTask ? !openTask : true}
          timeout={
            openTask
              ? {
                  enter: 700,
                  exit: 0
                }
              : { enter: 0, exit: 0 }
          }
        >
          {rightSection}
        </Fade>
      )}
    </Grid>
  );
};

export default withRouter(ProjectActions);
