import { createContext } from "react";

export const UserProfileContext = createContext({
  profile: {}, // user profile, email, name, accesslevel ?
  favorites: [], // array of projects, id and name
  setUserProfile: () => {}, // overwrites the user profile object
  //   permissions: [], // array of permissions, can edit, can invite, can create project etc?
  //   settings: {}, // user settings
  //   notifications: [], // notifications like task progress updates, users wanting to sign up or get approved, project progress changes
  //   recentTasks: [] // tasks that are due soon? maybe
});
