import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

// Components
import { useForm } from "../../shared/hooks/form-hook";
import TextInput from "../../shared/components/FormElements/Input";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";
import DatePicker from "../../shared/components/FormElements/DatePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    width: "100%",
  },
  taskInput: {
    marginBottom: theme.spacing(0),
  },
}));

const ProjectInnovationEditForm = (props) => {
  const { handleFormChange, project } = props;
  const classes = useStyles();
  const [formState, inputHandler, setFormData] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      variant: {
        value: "",
        isValid: false,
      },
      startDate: {
        value: null,
        isValid: true,
      },
      launchDate: {
        value: null,
        isValid: true,
      },
    },
    false
  );

  useEffect(() => {
    handleFormChange(formState);
  }, [formState, handleFormChange]);

  useEffect(() => {
    console.log("set form data to", project);
    if (project) {
      setFormData(
        {
          variant: {
            value: project.variant,
            isValid: true,
          },
          startDate: {
            value: project.startDate ? new Date(project.startDate * 1000) : null,
            isValid: true,
          },
          launchDate: {
            value: project.launchDate ? new Date(project.launchDate * 1000) : null,
            isValid: true,
          },
          name: {
            value: project.name || "",
            isValid: true,
          },
          category: {
            value: project.category || "",
            isValid: true,
          },
          brand: {
            value: project.brand || "",
            isValid: true,
          },
          geography: {
            value: project.geography || "",
            isValid: true,
          },
        },
        true
      );
    }
  }, [project, setFormData]);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            autoComplete="off"
            autoFocus
            margin="normal"
            id="name"
            label="Project Name"
            type="text"
            fullWidth
            variant="outlined"
            className={classes.taskInput}
            onInput={inputHandler}
            // errorText="is required"
            noMargin
            // helperText="Type in the project name (required)"
            size="medium"
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={project ? project.name : formState.inputs.name.value}
            initialIsValid={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            autoComplete="off"
            margin="normal"
            id="category"
            label="Category"
            type="text"
            fullWidth
            variant="outlined"
            className={classes.taskInput}
            onInput={inputHandler}
            noMargin
            size="medium"
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={project ? project.category : ""}
            initialIsValid={project && true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            autoComplete="off"
            margin="normal"
            id="brand"
            label="Brand"
            type="text"
            fullWidth
            variant="outlined"
            className={classes.taskInput}
            onInput={inputHandler}
            noMargin
            size="medium"
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={project ? project.brand : ""}
            initialIsValid={project && true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            autoComplete="off"
            margin="normal"
            id="geography"
            label="Geography"
            type="text"
            fullWidth
            variant="outlined"
            className={classes.taskInput}
            onInput={inputHandler}
            noMargin
            size="medium"
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={project ? project.geography : ""}
            initialIsValid={project && true}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} id="variant-label">
              Variant Name
            </InputLabel>
            <Select
              labelId="variant-label"
              id="variant"
              value={formState.inputs.variant.value}
              onChange={({ target }) => {
                inputHandler("variant", target.value, true);
              }}
              labelWidth={labelWidth}
            >
              <MenuItem value="New Variant">New Variant</MenuItem>
              <MenuItem value="Line Extension">Line Extension</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} container justify="space-between">
          <DatePicker
            label="Start Date"
            id="startDate"
            value={formState.inputs.startDate.value}
            handleDateChange={(date, id) => {
              inputHandler(id, date, true);
            }}
            wide
          />
          <DatePicker
            label="Launch Date"
            id="launchDate"
            value={formState.inputs.launchDate.value}
            handleDateChange={(date, id) => {
              inputHandler(id, date, true);
            }}
            minDate={formState.inputs.startDate.value || new Date("2020-01-01")}
            minDateMessage="Date must be after start date"
            style={{ marginRight: 0 }}
            wide
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectInnovationEditForm;
