import React, { useState, useEffect, useContext } from "react";

// MUI Stuff
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import grey from "@material-ui/core/colors/grey";

// Components
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const TaskNameInput = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    "& input": {
      fontSize: "20px",
      fontWeight: "bold",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent"
      },
      "&:hover fieldset": {
        borderColor: grey[400]
      },
      "&.Mui-focused fieldset": {
        borderColor: grey[600],
        borderWidth: "1px"
      }
    }
  }
}))(TextField);

const TaskNameField = props => {
  const { task, pid, id, placeholder, onChange } = props;
  const [value, setValue] = useState("");
  const [initialValue, setInitialValue] = useState("");

  const { token } = useContext(AuthContext);
  const { sendRequest, clearError } = useHttpClient();

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleBlur = async event => {
    if (event.target.value === initialValue) return;
    if (value === "") return;
    if (!task) return;
    clearError();
    console.log("handle blur detected a change. name name: ", value);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/tasks/name`,
        "PUT",
        JSON.stringify({
          name: value,
          taskId: task.id
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      );
      if (response.status === 201) {
        onChange(task, "name", value);
        setInitialValue(value);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (task) {
      setValue(task.name);
      setInitialValue(task.name);
    }
    return () => {
      setValue("");
      setInitialValue("");
    };
  }, [task]);

  return (
    <TaskNameInput
      placeholder={placeholder}
      variant="outlined"
      size="small"
      fullWidth
      id={id}
      value={value}
      onChange={handleChange}
      autoComplete="off"
      autoFocus={false}
      onBlur={handleBlur}
    />
  );
};

export default TaskNameField;
