import React, { useState, useEffect, useContext } from "react";
import { withRouter, useParams } from "react-router-dom";
import clsx from "clsx";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// Components
import TopHeaderBarProject from "../../shared/components/Navigation/TopHeaderBarProject";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { UserProfileContext } from "../../shared/context/userProfile-context";
import { grey } from "@material-ui/core/colors";
import UserWithAvatar from "../../shared/components/UIElements/UserWithAvatar";
import { Skeleton } from "@material-ui/lab";
import InviteMemberModal from "../../shared/components/Modals/InviteMemberModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  fullFlex: {
    flex: "1 1 auto",
    display: "flex",
    minWidth: "1px",
    flexDirection: "column",
  },
  table: {
    "& .MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "#fafafa",
    },
  },
  paper: {
    borderRadius: 4,
    boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  body: {
    backgroundColor: grey[50],
  },
}));

const OrganizationPage = (props) => {
  const classes = useStyles();
  const { pid } = useParams();
  const { token } = useContext(AuthContext);
  const { profile } = useContext(UserProfileContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [members, setMembers] = useState([]);
  const [inviteMemberModalOpen, setInviteMemberModalOpen] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (token) {
      const fetchMembers = async () => {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/users/stats`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );
          const members = response.data;
          if (mounted && members) {
            setMembers(members);
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchMembers();
    }
    return () => {
      mounted = false;
    };
  }, [token, sendRequest]);

  const onMemberInvite = (member) => {
    const newMembers = [...members];
    newMembers.push(member);
    setMembers(newMembers);
  };

  const handleMemberDelete = async (event, index) => {
    event.preventDefault();
    const userId = members[index].id;
    let newMembers = [...members];
    newMembers.splice(index, 1);
    setMembers(newMembers);

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_API_URL}/users/${userId}`,
        "DELETE",
        JSON.stringify({
          userId: userId,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      if (response) {
        console.log(response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRoleChange = async (event, index) => {
    if (members && token) {
      const updatedMembers = [...members];
      updatedMembers[index].accesslevel = event.target.value;
      setMembers(updatedMembers);
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/users/accesslevel`,
          "PUT",
          JSON.stringify({
            userId: updatedMembers[index].id,
            accesslevel: updatedMembers[index].accesslevel,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className="view-page">
      <Grid container direction="column" item xs={12} className={classes.body}>
        <Container maxWidth="md">
          <TableContainer
            component={Paper}
            style={{ marginTop: 30, maxHeight: "85vh", overflowY: "auto" }}
            className={classes.paper}
          >
            <Table className={classes.table} aria-label="members">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Permissions</TableCell>
                  <TableCell align="left" size="small">
                    Assigned Tasks
                  </TableCell>
                  <TableCell align="left" size="small">
                    Projects
                  </TableCell>
                  <TableCell align="right" size="small" padding="checkbox" />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && members.length === 0 ? (
                  [0, 1, 2, 3, 4].map((val) => (
                    <TableRow key={val} hover={true}>
                      <TableCell colSpan={5}>
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                  ))
                ) : members.length < 1 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <Typography variant="body1">
                        No members... Please invite a member to get started.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  members.map((member, index) => {
                    if (member.id === profile.id) return null;
                    return (
                      <TableRow key={member.id} hover={true}>
                        <TableCell component="th" scope="row">
                          <UserWithAvatar
                            color={member.color}
                            initials={member.initials}
                            name={member.name}
                            variant="body2"
                          />
                        </TableCell>
                        <TableCell align="left">{member.email}</TableCell>
                        <TableCell align="left">
                          <FormControl className={classes.formControl}>
                            <Select
                              value={member.accesslevel}
                              onChange={(event) =>
                                handleRoleChange(event, index)
                              }
                              displayEmpty
                              className={classes.selectEmpty}
                            >
                              <MenuItem value={0}>Guest</MenuItem>
                              <MenuItem value={100}>User</MenuItem>
                              <MenuItem value={200}>Administrator</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell align="left">{member.totalTasks}</TableCell>
                        <TableCell align="left">
                          {member.totalProjects}
                        </TableCell>
                        <TableCell
                          align="right"
                          size="small"
                          padding="checkbox"
                        >
                          <IconButton
                            // disabled
                            size="small"
                            onClick={(event) => {
                              handleMemberDelete(event, index);
                            }}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Grid>
    </div>
  );
};

export default withRouter(OrganizationPage);
