import React, { useState, useEffect, useContext, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";
import clsx from "clsx";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import grey from "@material-ui/core/colors/grey";

// Components
import TopHeaderBarProject from "../../shared/components/Navigation/TopHeaderBarProject";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { UserProfileContext } from "../../shared/context/userProfile-context";
import ProjectActions from "../components/ProjectActions";
import {
  FileAttachment,
  FileAttachmentLoading
} from "../../shared/components/FormElements/FileAttachment";
import {
  fileExtensions,
  rejectedExtensions
} from "../../shared/util/fileExtensions";
import FileHistoryModal from "../../shared/components/Modals/FileHistoryModal";
import { ProjectsContext } from "../../shared/context/projects-context";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  fullFlex: {
    flex: "1 1 auto",
    display: "flex",
    minWidth: "1px",
    flexDirection: "column"
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)"
  },
  body: {
    backgroundColor: grey[50]
  }
}));

const ProjectFilesPage = props => {
  const classes = useStyles();
  const { pid, stage } = useParams();
  const { token } = useContext(AuthContext);
  const { profile } = useContext(UserProfileContext);
  const { projects } = useContext(ProjectsContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [attachments, setAttachments] = useState([]);
  const [projectFiles, setProjectFiles] = useState([]);
  const [fileError, setFileError] = useState();
  const [uploadingNewProjectFile, setUploadingNewProjectFile] = useState(false);
  const [fileHistory, setFileHistory] = useState(null);

  const filePickerRef = useRef();

  const project = {
    ...projects.find(project => project.id === parseInt(pid))
  };

  useEffect(() => {
    let mounted = true;
    if (token && pid) {
      const fetchFiles = async () => {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/files/project/${pid}/${stage}`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            }
          );
          if (mounted && response.data.attachments) {
            setAttachments(response.data.attachments);
          }
          if (mounted && response.data.projectFiles) {
            setProjectFiles(response.data.projectFiles);
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchFiles();
    }
    return () => {
      mounted = false;
    };
  }, [pid, stage, token, sendRequest]);

  const handleFileChange = (file, type) => {
    console.log("handleFileChange called with: ", file, type);
    if (type === "attachment") {
      console.log(attachments);
      const newAttachments = JSON.parse(JSON.stringify(attachments));
      const updatedAttachment = newAttachments.find(
        a => a.masterFile === file.options.masterFile
      );
      const updatedAttachmentIndex = newAttachments.findIndex(
        a => a.masterFile === file.options.masterFile
      );
      if (!updatedAttachment || updatedAttachmentIndex < 0) {
        console.error("attachment was not found..");
        return;
      }
      updatedAttachment.name = file.options.nameWithExtension;
      updatedAttachment.fileId = file.options.fileId;
      updatedAttachment.id = file.options.attachmentId;
      updatedAttachment.revision = file.options.revision;

      updatedAttachment.file = { ...file.options };
      updatedAttachment.file.id = file.options.fileId;
      newAttachments[updatedAttachmentIndex] = updatedAttachment;

      setAttachments(newAttachments);
    }
    if (type === "projectFile") {
      console.log(projectFiles);
      const newProjectFiles = JSON.parse(JSON.stringify(projectFiles));
      const updatedProjectFile = newProjectFiles.find(
        a => a.masterFile === file.options.masterFile
      );
      const updatedProjectFileIndex = newProjectFiles.findIndex(
        a => a.masterFile === file.options.masterFile
      );
      if (!updatedProjectFile || updatedProjectFileIndex < 0) {
        setFileError("Internal error occured, please refresh your page");
        return;
      }
      updatedProjectFile.name = file.options.nameWithExtension;
      updatedProjectFile.fileId = file.options.fileId;
      updatedProjectFile.id = file.options.attachmentId;
      updatedProjectFile.revision = file.options.revision;

      updatedProjectFile.file = { ...file.options };
      updatedProjectFile.file.id = file.options.fileId;
      newProjectFiles[updatedProjectFileIndex] = updatedProjectFile;

      setProjectFiles(newProjectFiles);
    }
    if (type === "newProjectFile") {
      console.log(projectFiles);
      const newProjectFile = {};
      newProjectFile.name = file.options.nameWithExtension;
      newProjectFile.masterFile = file.options.masterFile;
      newProjectFile.fileId = file.options.fileId;
      newProjectFile.id = file.options.attachmentId;
      newProjectFile.revision = file.options.revision;

      newProjectFile.file = { ...file.options };
      newProjectFile.file.id = file.options.fileId;

      setProjectFiles([...projectFiles, newProjectFile]);
    }
  };

  const handleNewProjectFile = async event => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      setUploadingNewProjectFile(true);
      pickedFile = event.target.files[0];
      const fileSize = (pickedFile.size / 1024 / 1024).toFixed(4); // MB
      if (
        rejectedExtensions.includes(pickedFile.name.split(".").pop()) === false
      ) {
        if (fileSize < 200) {
          pickedFile.extension =
            fileExtensions.find(ext => ext.mime === pickedFile.type) ||
            fileExtensions[0];
          try {
            console.log("uploadFile called with file: ", pickedFile);
            const formData = new FormData();
            formData.append("file", pickedFile);
            formData.append("projectId", pid);
            formData.append("gate", stage);
            formData.append("mime", pickedFile.type);
            const response = await sendRequest(
              `${process.env.REACT_APP_API_URL}/files/project`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + token
              }
            );
            console.log(response);
            if (response.data.file) {
              setFileError(null);
              handleFileChange(response.data.file, "newProjectFile");
            }
          } catch (err) {
            setFileError(err.message);
          }
        } else {
          setFileError("File must be less than 200MB");
        }
      } else {
        setFileError("invalid file (check file type or size)");
      }
    } else {
      setFileError("no file selected");
    }

    setUploadingNewProjectFile(false);
  };

  return (
    <div className="view-page">
      <TopHeaderBarProject project={project} user={profile} />
      <Grid
        container
        className={clsx(classes.root, classes.fullFlex)}
        spacing={0}
        direction="row"
      >
        <Grid item xs>
          <ProjectActions
            leftActionsComponent={
              <Grid container alignItems="center" item xs={6}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={event => {
                    event.preventDefault();
                    filePickerRef.current.click();
                  }}
                  disabled={uploadingNewProjectFile}
                >
                  Upload Project File
                  {uploadingNewProjectFile && (
                    <CircularProgress
                      color="primary"
                      size={18}
                      style={{ marginLeft: 8 }}
                    />
                  )}
                </Button>
              </Grid>
            }
          />
          <input
            id="projectFile-new"
            ref={filePickerRef}
            type="file"
            style={{ display: "none" }}
            accept="*"
            onChange={handleNewProjectFile}
            value=""
          />
        </Grid>
      </Grid>
      <Grid container direction="column" item xs={12} className={classes.body}>
        <Container maxWidth="lg" className={classes.paper}>
          <Typography variant="h4" align="center" gutterBottom>
            Project Files
          </Typography>
          <Grid
            container
            spacing={3}
            style={{ marginTop: 12 }}
            justify="center"
          >
            {projectFiles.length > 0 ? (
              projectFiles.map(projectFile => (
                <Grid item key={projectFile.id}>
                  <FileAttachment
                    type="projectFile"
                    attachment={projectFile}
                    file={projectFile.file}
                    onFileChange={handleFileChange}
                    pid={pid}
                    stage={stage}
                    setFileHistory={setFileHistory}
                  />
                </Grid>
              ))
            ) : isLoading ? (
              [0, 1, 2].map(key => (
                <Grid item key={key}>
                  <FileAttachmentLoading />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  No files found.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Container>
        <Container maxWidth="lg" className={classes.paper}>
          <Typography variant="h4" align="center" gutterBottom>
            Task Files
          </Typography>
          <Grid
            container
            spacing={3}
            style={{ marginTop: 12 }}
            justify="center"
          >
            {attachments.length > 0 ? (
              attachments.map(attachment => (
                <Grid item key={attachment.id}>
                  <FileAttachment
                    type="attachment"
                    attachment={attachment}
                    file={attachment.file}
                    onFileChange={handleFileChange}
                    pid={pid}
                    setFileHistory={setFileHistory}
                  />
                </Grid>
              ))
            ) : isLoading ? (
              [0, 1, 2, 3, 4, 5].map(key => (
                <Grid item key={key}>
                  <FileAttachmentLoading />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  No files found.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Container>
      </Grid>
      <Snackbar
        open={Boolean(fileError)}
        autoHideDuration={6000}
        onClose={() => setFileError(null)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => setFileError(null)}
        >
          {fileError}
        </MuiAlert>
      </Snackbar>
      <FileHistoryModal
        fileHistory={fileHistory}
        setFileHistory={setFileHistory}
      />
    </div>
  );
};

export default withRouter(ProjectFilesPage);
