import React, { useState } from "react";
import clsx from "clsx";
import { NavLink as RouterLink, withRouter } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";

const useStyles = makeStyles(theme => ({
  navLink: {
    "&.active": {
      color: purple[500]
    },
    "&.active span": {
      fontWeight: "bold"
    }
  },
  listItemSmall: {
    fontSize: "13px"
  },
  activeIcon: {
    color: purple[500]
  }
}));

function ListItemLink(props) {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);

  const {
    icon,
    primary,
    to,
    exact,
    rightIcon,
    smallFont,
    pid,
    rightIconActionClick
  } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink
          to={to}
          ref={ref}
          exact={exact || false}
          {...itemProps}
          isActive={(match, location) => {
            if (match) {
              setIsActive(true);
              return true;
            }
            if (pid) {
              let parsedId = location.pathname.split("/");
              setIsActive(parseInt(parsedId[2]) === pid);
              return parseInt(parsedId[2]) === pid;
            }
            setIsActive(false);
          }}
        />
      )),
    [to, exact, pid]
  );

  const renderAction = (
    <ListItemSecondaryAction>
      <IconButton
        aria-label="create a new project"
        size="small"
        onClick={rightIconActionClick}
      >
        {rightIcon}
      </IconButton>
    </ListItemSecondaryAction>
  );

  return (
    <ListItem
      button
      component={renderLink}
      className={`${classes.navLink} ${props.className || null}`}
    >
      {icon ? (
        <ListItemIcon className={isActive ? classes.activeIcon : null}>
          {icon}
        </ListItemIcon>
      ) : null}
      <ListItemText
        primary={primary}
        // primaryTypographyProps={{ noWrap: true }}
        classes={smallFont && { primary: classes.listItemSmall }}
      />
      {rightIcon ? renderAction : null}
    </ListItem>
  );
}

export default withRouter(ListItemLink);
