import React, { useState, useEffect, useContext } from "react";
import { withRouter, useParams } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";

// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import grey from "@material-ui/core/colors/grey";

// Components
import TopHeaderBarProject from "../../shared/components/Navigation/TopHeaderBarProject";
import ProjectActions from "../components/ProjectActions";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { ProjectsContext } from "../../shared/context/projects-context";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  fullFlex: {
    flex: "1 1 auto",
    display: "flex",
    minWidth: "1px",
    flexDirection: "column"
  },
  dissmissTaskDetails: {
    minHeight: "1px",
    flex: 1,
    display: "flex",
    height: "100%"
  },
  tasksContainer: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    minHeight: 1,
    position: "relative",
    width: "100%",
    maxHeight: "77vh"
  },
  scrollableContainer: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  }
}));
const localizer = momentLocalizer(moment);

const ProjectCalendarPage = props => {
  const classes = useStyles();
  const { pid, stage } = useParams();
  const { token } = useContext(AuthContext);
  const { projects } = useContext(ProjectsContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [events, setEvents] = useState([]);
  const [project, setProject] = useState({});

  useEffect(() => {
    if (projects.length > 0) {
      const project = {
        ...projects.find(project => project.id === parseInt(pid))
      };
      setProject(project);
    }
  }, [projects, pid]);

  useEffect(() => {
    let mounted = true;
    if (token && pid) {
      const fetchTasks = async () => {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/tasks/${pid}/${stage}`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            }
          );
          const tasks = response.data.tasks;
          let events = [];
          tasks.forEach(task => {
            if (task.completeDate > 0) return;
            if (task.dueDate > 0 && task.startDate < 1) {
              events.push({
                start: new Date(task.dueDate * 1000),
                end: new Date(task.dueDate * 1000),
                allDay: true,
                title: task.name
              });
            } else if (task.dueDate > 0 && task.startDate > 1) {
              events.push({
                start: new Date(task.startDate * 1000),
                end: new Date(task.dueDate * 1000),
                title: task.name
              });
            }
          });
          if (mounted) {
            clearError();
            setEvents(events);
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchTasks();
    }
    return () => {
      mounted = false;
    };
  }, [pid, token, sendRequest]);

  return (
    <div className="view-page">
      <TopHeaderBarProject project={project} />
      <Grid
        container
        className={clsx(classes.root, classes.fullFlex)}
        spacing={0}
        direction="row"
      >
        <Grid item xs>
          <ProjectActions />
        </Grid>
      </Grid>
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        views={{ month: true, agenda: true }}
        events={events}
        style={{ margin: 20, overflowY: "auto" }}
      />
    </div>
  );
};

export default withRouter(ProjectCalendarPage);
