import { format } from "date-fns";
import { capitalize, priorityOptions } from "./helpers";

const taskActionDisplay = ({ field, type, newValue }) => {
  if (type === "created") {
    return {
      display: "created",
      value: field,
    };
  }
  switch (field) {
    case "assignee":
      return {
        display: "re-assigned to ",
        value: newValue,
      };
    case "priority":
      if (0 === parseInt(newValue))
        return {
          display: "removed the priority",
        };
      return {
        display: "updated the priority to ",
        value: capitalize(priorityOptions[newValue].name),
      };
    case "startDate":
      return {
        display: "changed the start date to ",
        value: format(new Date(newValue * 1000), "MMM do yyyy"),
      };
    case "dueDate":
      return {
        display: "changed the due date to ",
        value: format(new Date(newValue * 1000), "MMM do yyyy"),
      };
    case "created":
      return {
        display: "created",
        value: "",
      };
    case "comment":
      return {
        display: "commented",
        value: newValue,
      };
    case "completeDate":
      return {
        display: "updated complete status to",
        value: newValue,
      };
    default:
      return {
        display: `updated ${field} to `,
        value: `${newValue || "none"}`,
      };
  }
};

export default taskActionDisplay;
