export default [
  {
    value: "Member",
  },
  {
    value: "CSO",
  },
  {
    value: "General Manager",
  },
  {
    value: "Director of US",
  },
  {
    value: "Marketing Project Manager",
  },
  {
    value: "Content Manager",
  },
  {
    value: "Director of Canada",
  },
  {
    value: "Production Manager",
  },
  {
    value: "Label Master",
  },
  {
    value: "Medical Director",
  },
  {
    value: "RRA",
  },
  {
    value: "Purchaser",
  },
  {
    value: "QC",
  },
  {
    value: "QA",
  },
  {
    value: "Product Development",
  },
  {
    value: "Sales Manager",
  },
  {
    value: "Financial Analyst",
  },
  {
    value: "Controller",
  },
];

// Member, CSO, General Manager, Director of US, Marketing Project Manager, Content Manager, Director of Canada, Production Manager, Label Master, Medical Director, RRA, Purchaser, QC, QA, Product Development, Sales Manager, Financial Analyst
