import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import ProjectTasksPage from "../../projects/pages/ProjectTasksPage";
import TopHeaderBarProject from "../../shared/components/Navigation/TopHeaderBarProject";

import { UserProfileContext } from "../../shared/context/userProfile-context";
import { ProjectsContext } from "../../shared/context/projects-context";

const ProjectPageGates = props => {
  const { pid, stage: gate } = useParams();
  const { profile } = useContext(UserProfileContext);
  const { projects } = useContext(ProjectsContext);
  const project = {
    ...projects.find(project => project.id === parseInt(pid))
  };

  return (
    <React.Fragment>
      <div className="view-page">
        <TopHeaderBarProject user={profile} loading={false} project={project} />
        <ProjectTasksPage loading={false} />
      </div>
    </React.Fragment>
  );
};

export default ProjectPageGates;
